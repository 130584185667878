import React from 'react';
import 'rc-slider/assets/index.css';
import { ChartOptionSelector, OptionSelector } from 'constants/chartOptionsSelector';

export interface OptionCheckboxProps {
  option: any;
  onChangeHandler: any;
  ChildComponentName: ChartOptionSelector;
  showChildComponent: any;
  onSetGoalValuesChange: any;
  customValues: any;
}

const customOptionCheckbox: React.FC<OptionCheckboxProps> = ({
  option,
  onChangeHandler,
  ChildComponentName,
  showChildComponent,
  onSetGoalValuesChange,
  customValues,
}) => {
  const ChildComponent = OptionSelector.option[ChildComponentName];

  return (
    <>
      <div className="option-item">
        <span className="toggler-label">{option.displayName}</span>
        <label className="toggler-wrapper">
          <input
            type="checkbox"
            name={option.actionName}
            defaultChecked={option.isChecked}
            onChange={onChangeHandler}
          />
          <div className="toggler-slider">
            <div className="toggler-knob"></div>
          </div>
        </label>
      </div>
      {showChildComponent ? (
        <ChildComponent
          {...{ onSetGoalValuesChange: onSetGoalValuesChange, customValues: customValues }}
        />
      ) : null}
    </>
  );
};

export default customOptionCheckbox;
