import React, { useMemo } from 'react';
import { styled } from '@cx/ui/theme';
import Text from '@cx/ui/components/Text';
import Tags, { TagsProps, TagType } from '@cx/ui/components/Tags';
import { FilterBarValue, TagTypes } from 'types';
import { TagVariant } from '@cx/ui/components/Tag';

export { TagVariant };
export type CreateLabelType = (filter: FilterBarValue) => string;
export type AdaptFilterBarValuesType = (
  filter: FilterBarValue[],
  createLabel?: CreateLabelType
) => TagType[];

/**
 * Helper function that adapts the filter bar values to match the tags component format.
 * @param {FilterBarValue} filterBarValues
 * @returns {TagType}
 */

const defaultAdaptFilterBarValues = (
  filterBarValues: FilterBarValue[],
  createLabel?: CreateLabelType
): TagType[] => {
  return filterBarValues.flatMap((filter) => {
    const Label = filter.DisplayName || filter.AttributeName;
    if (filter.Tag === TagTypes.Date) {
      const value = filter.Values.join(' - ');
      return {
        id: `${Label}-${filter.Tag}-${value}`,
        label: createLabel ? createLabel(filter) : `${Label}: ${value}`,
        filter,
        value,
      };
    }
    return filter.Values.flatMap((value) => ({
      id: `${Label}-${filter.Tag}-${value}`,
      label: createLabel ? createLabel(filter) : `${Label}: ${value}`,
      filter,
      value,
    }));
  });
};

export type FilterHintsProps = Omit<TagsProps, 'tags'> &
  React.HTMLAttributes<HTMLDivElement> & {
    filters: FilterBarValue[];
    title?: string;
    createLabel?: CreateLabelType;
    adaptFilterBarValues?: AdaptFilterBarValuesType;
  };

/**
 * @description component that renders the filter hints. It receives the filters and renders them as tags.
 * @param {FilterHintsProps} props - The props of the component. Same as the Tags component with the addition of the filters prop.
 * @returns {React.ReactElement}
 */
function FilterHints({
  filters,
  title = 'Filtered by:',
  className,
  createLabel,
  adaptFilterBarValues = defaultAdaptFilterBarValues,
  ...props
}: FilterHintsProps): React.ReactElement {
  const tags = useMemo(() => {
    if (!filters || !filters.length) {
      return [];
    }
    return adaptFilterBarValues?.(filters, createLabel);
  }, [filters]);

  return (
    <div className={className}>
      <Text className="cx-filter-hints-title">{title}</Text>
      <Tags {...props} tags={tags || []} />
    </div>
  );
}

const StyledFiltersHints = styled(FilterHints)(({ theme }) => ({
  '.cx-filter-hints-title': {
    ...theme.typography.small,
    marginBottom: theme.spacing(0.75),
  },
}));

export { StyledFiltersHints as FilterHints };
