import React, { useEffect, useRef, useState } from 'react';
import ReactECharts, { EChartsOption } from 'echarts-for-react';
import {
  axisLabel,
  yAxisLabel,
  axisTick,
  axisLine,
  yAxis,
  npsYAxis,
  richLabelTitle,
  tooltip,
  legendTextStyle,
  legendCommon,
  labelCommon,
} from '../../../constants/chartOption';
import './VerticalBarChart.scss';
import { BaseWidget, CustomConfig } from 'models/WidgetModel';
import { lightenDarkenColor } from 'helpers/darkenLightenColor';
import { getMonthName, getFormattedDate } from 'helpers/dateFormatters';
interface VerticalBarProps extends BaseWidget {
  colors: string[];
  subText?: string;
  highlightLineValueText?: string | number;
  options: any;
  isCustom?: boolean;
  customWidgetConfig?: CustomConfig;
}

const MONTH_TO_DATE = 'Month to Date';
const YEAR_TO_DATE = 'Year to Date';

const VerticalBarChart: React.FC<VerticalBarProps> = ({
  response,
  colors,
  subText,
  options,
  isCustom,
  customWidgetConfig,
  ...props
}) => {
  const [highlightLastValue, setHighlightLastValue] = useState<boolean>(false);
  const [isPercentage, setIsPercentage] = useState<boolean>(true);
  const [isSubtitle, setIsSubtitle] = useState<boolean>(false);
  const [isNegativeBar, setIsNegativeBar] = useState<boolean>(false);
  const [showLegend, setShowLegend] = useState<boolean>(true);
  const [seriesData, setSeriesData] = useState<(string | number)[][]>([]);
  const [seriesNames, setSeriesNames] = useState<string[]>([]);
  const [, setColor] = useState<string[]>([]);
  const [xData, setXData] = useState<(string | number)[]>([]);
  const [barYTDAvg, setBarYTDAvg] = useState<string | number>();
  const { width, height } = props;
  const [optionsChart, setOptionsChart] = useState<any>({});
  const [drawGoalLine, setdrawGoalLine] = React.useState<boolean>(false);
  const [goalValues, setGoalValues] = useState<any>({});

  const optionsRef = useRef<any>();
  const seriesDataRef = useRef<(string | number)[][]>();
  const [colorsRef, setColorRef] = useState<string[]>(colors);
  const [yAxisValues, setYAxisValues] = useState<any>({});

  optionsRef.current = optionsChart;
  seriesDataRef.current = seriesData;

  const {
    goal,
    optYAxisValues,
    drawGoalLine: optDrawGoalLine,
    setYAxisMinMaxValues: optSetYAxisMinMaxValues,
    showNegativeValues,
    showLegend: optShowLegend,
  } = options;

  useEffect(() => {
    if (goal) {
      setGoalValues(goal);
    }
  }, [goal]);

  useEffect(() => {
    if (optYAxisValues) {
      setYAxisValues(optYAxisValues);
    }
  }, [optYAxisValues]);

  useEffect(() => {
    if (optSetYAxisMinMaxValues) {
      setIsPercentage(yAxisValues && yAxisValues.max > 100 ? false : true);
    } else {
      setIsPercentage(response?.data?.isPercentage ?? false);
    }
  }, [yAxisValues]);

  useEffect(() => {
    if (options && 'optYAxisValues' in options && optSetYAxisMinMaxValues) {
      optSetYAxisMinMaxValues ? setYAxisValues(optYAxisValues) : setYAxisValues({});
    } else {
      setYAxisValues({});
    }
  }, [optSetYAxisMinMaxValues]);

  useEffect(() => {
    if (options && 'drawGoalLine' in options) {
      optDrawGoalLine ? setdrawGoalLine(optDrawGoalLine) : setGoalValues({});
    }
  }, [optDrawGoalLine]);

  useEffect(() => {
    if (response && response.data.seriesData) {
      const [firstSerie] = response.data.seriesData;
      if (firstSerie instanceof Array) {
        const defineXData = () => {
          if (isCustom && customWidgetConfig && customWidgetConfig.Trend?.length) {
            const periodType = customWidgetConfig?.Trend[0].TimePeriodType || '';
            return response?.data?.xData?.map((item: string | number) =>
              getFormattedDate(item, periodType, response?.data?.showYear)
            );
          } else {
            return response?.data?.xData?.map((item: string | number) =>
              getMonthName(item, response?.data?.showYear)
            );
          }
        };

        setXData(defineXData);
        setSeriesData(response?.data?.seriesData);
        setSeriesNames(response?.data?.seriesNames);
        setHighlightLastValue(response?.data?.highlightLastValue);
        if (Object.keys(yAxisValues).length > 0 && optSetYAxisMinMaxValues) {
          setIsPercentage(yAxisValues.max <= 100);
        } else {
          setIsPercentage(response?.data?.isPercentage ?? false);
        }
        setShowLegend(response?.data?.showLegend);
        setIsSubtitle(response?.data?.isSubtitle);
        setIsNegativeBar(response?.data?.isNegativeBar);
        setColor(colorValidation());
        setBarPeriods(response);
      }
    }
  }, [response]);

  const colorValidation = () => {
    if (Array.isArray(colors)) {
      const [color1] = colors;
      return [color1];
    }
    return [colors];
  };

  const setBarPeriods = (response: any) => {
    if (response?.data?.barYTDAvg) {
      setBarYTDAvg(response?.data?.barYTDAvg);
    } else {
      if (response?.data?.seriesData?.length) {
        const [barSeries] = response.data.seriesData;
        setBarYTDAvg(barSeries?.length > 0 ? barSeries[barSeries?.length - 1] : '');
      }
    }
  };

  useEffect(() => {
    if (seriesData && seriesData instanceof Array && seriesData?.length) {
      const chartOptions: EChartsOption = initOptions();
      setOptionsChart(chartOptions);
    }
  }, [
    seriesData,
    isPercentage,
    isNegativeBar,
    showLegend,
    colors,
    colorsRef,
    goalValues,
    optDrawGoalLine,
    yAxisValues,
  ]);

  useEffect(() => {
    if (options) {
      'showNegativeValues' in options ? setIsNegativeBar(showNegativeValues) : null;
      'showLegend' in options ? setShowLegend(optShowLegend) : null;
    }
  }, [showNegativeValues, optShowLegend]);

  const initOptions = () => {
    if (optSetYAxisMinMaxValues && yAxisValues && yAxisValues.max > 100) {
      setIsPercentage(false);
    } else {
      setIsPercentage(response?.data?.isPercentage ?? false);
    }
    return {
      grid: {
        left: '32',
        right: '15',
        top: isSubtitle ? '65' : '55',
        bottom: showLegend && seriesNames ? '45' : '25',
      },
      tooltip: {
        ...tooltip,
        formatter: (params: any) => {
          if (params.componentSubType !== 'line') {
            return `${params.value}${isPercentage ? '%' : ''}`;
          } else {
            return `<strong>${params.name}:</strong> ${params.value}${isPercentage ? '%' : ''}`;
          }
        },
      },
      legend: {
        show: showLegend && seriesNames,
        selectedMode: false,
        ...legendCommon,
        textStyle: {
          ...legendTextStyle,
        },
      },
      labelLayout: {
        hideOverlap: true, // hides labels when overlapping
      },
      xAxis: {
        type: 'category',
        data: xData,
        ...axisTick,
        ...axisLabel,
        ...axisLine,
      },
      yAxis: {
        type: 'value',
        show: true,
        ...(isNegativeBar ? npsYAxis : yAxis),
        ...axisTick,
        ...(optSetYAxisMinMaxValues && yAxisValues && yAxisValues.max >= 1000
          ? yAxisLabel(yAxisValues.max)
          : axisLabel),
        ...yAxisValues,
      },
      series: seriesData?.length <= 1 ? getSeries() : [...getSeries(), getLineOptions()],
      ...(isSubtitle ? { title: getBaseTitle(subText) } : {}),
    };
  };

  const seriesDataFormatted = () => {
    if (seriesData && seriesData instanceof Array && seriesData?.length) {
      return seriesData[0]?.map((item, index) => {
        const [lColor] = colors;

        if (highlightLastValue && index + 1 === seriesData[0].length && item !== 0) {
          return {
            value: item,
            itemStyle: { color: lightenDarkenColor(lColor, -80) },
            label: { fontSize: 16, fontWeight: 'bolder' },
          };
        } else {
          return {
            value: item,
            itemStyle: { color: colorsRef },
          };
        }
      });
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (optionsChart?.series?.length) {
      setColorRef(colorValidation());
      const chartOptions: EChartsOption = initOptions();
      setOptionsChart(chartOptions);
    }
  }, [colors]);

  const getSeries = () => {
    if (!seriesData?.length || !(seriesData instanceof Array)) return [];
    if (optSetYAxisMinMaxValues && yAxisValues && yAxisValues.max > 100) {
      setIsPercentage(false);
    } else {
      setIsPercentage(response?.data?.isPercentage ?? false);
    }
    const item =
      seriesData?.length &&
      (seriesData[0].some((v: any) => typeof v === 'number' && v < 0) || seriesData?.length > 1);
    const { lineStyle, lineWidth, goalLineColor, goalValue } = goalValues;
    return [
      {
        markLine: {
          symbol: 'none',
          lineStyle: {
            type: lineStyle ? lineStyle : 'solid',
            width: lineWidth && drawGoalLine ? lineWidth : 0,
            color: goalLineColor ? goalLineColor : '#000',
          },
          data: [
            {
              label: { show: false },
              yAxis: goalValue ? goalValue : 0,
              tooltip: {
                formatter: `<strong>Goal{b}:</strong> {c}${isPercentage ? '%' : ''}`,
              },
            },
          ],
        },
        data: seriesDataFormatted(),
        name: seriesNames ? seriesNames[0] : null,
        type: 'bar',
        itemStyle: {
          borderRadius: 4,
          color: colorsRef,
        },
        barWidth: '94%',
        emphasis: {
          itemStyle: {
            ...(colorsRef.length ? { color: lightenDarkenColor(colorsRef[0], -40) } : {}),
          },
        },
        label: {
          show: true,
          ...labelCommon,
          fontSize: 11,
          formatter: `{c}${isPercentage ? '%' : ''}`,
          ...(item
            ? {
                show: true,
                color: seriesData?.length > 1 ? '#8a8a8a' : 'transparent',
                fontSize: 11,
              }
            : {}),
        },
      },
    ];
  };

  const getBaseTitle = (subText: string | undefined) => {
    let title: any = {};

    if (subText) {
      title = {
        subtext: subText,
        subtextStyle: {
          lineHeight: '2',
        },
      };
    } else {
      let xDataSubText = '';
      if (!isCustom) {
        xDataSubText = xData.length === 12 ? YEAR_TO_DATE : MONTH_TO_DATE;
      }
      const subtext = xData ? xDataSubText : '';
      title = {
        subtext,
        subtextStyle: {
          lineHeight: '2',
        },
      };
    }

    return title;
  };

  // I'm keeping method for reference but title won't be visible anymore since it will be showing values at top of each bar
  const titleFormatter = (title: any) => {
    if (seriesData?.length > 1) {
      const [, lineSeries] = seriesData;
      const highlightLVText = lineSeries?.length > 0 ? lineSeries[lineSeries?.length - 1] : '';
      const text = highlightLVText ? highlightLVText : '-';
      title = {
        ...title,
        text: [
          `{a|${barYTDAvg ? barYTDAvg : '-'}${isPercentage ? '%' : ''}}`,
          `{c|/}`,
          `{b|${text}${isPercentage ? '%' : ''}}`,
        ].join(' '),
        textStyle: {
          rich: {
            a: {
              ...richLabelTitle,
              color: colorsRef,
            },
            b: {
              ...richLabelTitle,
              color: '#22E1BC',
            },
            c: {
              ...richLabelTitle,
              color: '#A4BED0',
            },
          },
        },
      };
    } else {
      title = {
        ...title,
        text: `${isCustom ? '' : barYTDAvg ? barYTDAvg + (isPercentage ? '%' : '') : '-'}`,
        textStyle: {
          ...richLabelTitle,
          color: colorsRef,
        },
      };
    }

    return title;
  };

  const getLineOptions = () => {
    if (seriesData?.length <= 1) return null;

    const lineOptions = {
      name: seriesNames ? seriesNames[seriesNames?.length - 1] : null,
      type: 'line',
      data: seriesData[seriesData?.length - 1],
      symbol: 'circle',
      symbolSize: 5,
      color: '#22E1BC',
      lineStyle: {
        width: 4,
      },
      label: {
        show: true,
        ...labelCommon,
        fontSize: 11,
        color: seriesData?.length > 1 ? '#22E1BC' : 'transparent',
        formatter: `{c}${isPercentage ? '%' : ''}`,
      },
    };

    return lineOptions;
  };

  return (
    <>
      <div className="responsive-chart" data-testid="VerticalBarChart">
        {optionsChart?.series ? (
          <ReactECharts
            opts={{ renderer: 'svg', width, height }}
            option={optionsChart}
            notMerge={true}
          />
        ) : null}
      </div>
    </>
  );
};

export default VerticalBarChart;
