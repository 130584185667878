import { useStore } from 'zustand';
import { useContext } from 'react';
import { ApiType, FilterActions } from 'types';
import { StoreContext, ApiContext } from './context';

export type SelectorType<U> = (state: any) => U;

export function useAppStore<U>(selector: SelectorType<U>) {
  const store = useContext(StoreContext);
  if (!store) {
    throw new Error('Missing StoreProvider');
  }
  return useStore(store, selector);
}

/**
 * Sugar syntax for using the store
 * @description Hook that returns the state of the app.
 * @param selector Callback that selects a slice of the state.
 * @returns {U}
 */

export function useAppState<U>(selector?: SelectorType<U>): U {
  return useAppStore<U>(selector);
}

/**
 * Sugar syntax for using the store.
 * @description Hook that returns the actions of the app.
 * @returns {FilterActions}
 */

export function useAppActions(): FilterActions {
  return useAppStore((state) => state as FilterActions) as FilterActions;
}

export function useApi(): ApiType {
  const api = useContext(ApiContext);
  if (!api) {
    throw new Error('Missing ApiProvider');
  }
  return api;
}
