import { dropDownValue } from './constants';

export const initialWidgetState = {
  action: 'NONE',
  chartView: false,
  optionsL2: [dropDownValue],
  selectedValueL1: dropDownValue,
  selectedValueL2: dropDownValue,
  defaultValueL2: dropDownValue,
  searchFilter: '',
  defaults: {
    SWITCH_BAR_LINE: 'bars',
  },
};
