import MultipleSelect, { SelectOptions } from 'core-ui/MultipleSelect/MultipleSelect';
import { IQueryDefinition } from './QueryBuilder';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@cx/ui';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-github';

interface IQueryBuilderItemProps {
  position: number;
  customWidget: any;
  availableOptions: SelectOptions[];
  onChange: (IQueryData: IQueryDefinition) => void;
  onDelete?: (key: string) => void;
}

const QueryBuilderItem: React.FC<IQueryBuilderItemProps> = ({
  customWidget,
  availableOptions,
  position,
  onChange,
  onDelete,
}) => {
  const [query, setQuery] = useState<string>(customWidget?.payload?.Query || '');
  const [selectedDataSources, setSelectedDataSources] = useState<SelectOptions[]>();

  const handleCollectionChange = (value: SelectOptions[]) => {
    setSelectedDataSources(value);
  };

  const handleQueryChange = (value: string) => {
    setQuery(value);
  };

  useEffect(() => {
    const selectedData: IQueryDefinition = {
      [position.toString()]:
        selectedDataSources?.map((source) => {
          return {
            dataCollectionGuid: source.value,
            query: query,
          };
        }) || [],
    };

    onChange(selectedData);
  }, [query, selectedDataSources]);

  return (
    <>
      <div className="diy-drawer-datasource">
        <div className="diy-drawer-dd">
          <MultipleSelect
            loading={false}
            data-testid="datasource-selector"
            options={availableOptions}
            selectedChange={handleCollectionChange}
            selectedValues={selectedDataSources}
            label="Data Sources"
            onBlurEvent={() => null}
            helperText={'Select one or many data sources to use in your query.'}
          />
        </div>
        {onDelete && (
          <div className="diy-drawer-icon">
            <Icon name="system-delete" onClick={() => onDelete(position.toString())} />
          </div>
        )}
      </div>
      <AceEditor
        mode="javascript"
        theme="github"
        className="diy-query-editor"
        onChange={handleQueryChange}
        value={query}
        name="query"
        setOptions={{
          useWorker: false,
        }}
        height="250px"
        editorProps={{ $blockScrolling: true }}
      />
    </>
  );
};
QueryBuilderItem.propTypes = {
  position: PropTypes.number.isRequired,
  customWidget: PropTypes.any.isRequired,
  availableOptions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};

export default QueryBuilderItem;
