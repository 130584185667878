export const DiyMockDataSource = [
  {
    alias: 'CorporatePulse',
    url: '/data/single-endpoint',
    payload: {
      actionName: 'corporate-pulse',
      ControllerName: 'CorporatePulse',
    },
  },
  {
    alias: 'HorizontalSingleStackedBarChart',
    url: '/data/single-endpoint',
    payload: {
      actionName: 'horizontal-stacked-bar',
      ControllerName: 'HorizontalSingleStackedBarChart',
    },
  },
  {
    alias: 'PieChart',
    url: '/data/single-endpoint',
    payload: {
      actionName: 'diy-pie-chart',
      ControllerName: 'PieChart',
    },
  },
  {
    alias: 'VerticalBarChart',
    url: '/data/single-endpoint',
    payload: {
      actionName: 'vertical-bar-chart-osat',
      ControllerName: 'VerticalBarChart',
    },
  },
  {
    alias: 'GeoMap',
    url: '/data/single-endpoint',
    payload: {
      actionName: 'geo-map',
      ControllerName: 'GeoMap',
    },
  },
  {
    alias: 'StackedBarChart',
    url: '/data/single-endpoint',
    payload: {
      actionName: 'stacked-bar-chart',
      ControllerName: 'StackedBarChart',
    },
  },
  {
    alias: 'DynamicTable',
    url: '/data/single-endpoint-survey-diy',
    payload: {
      actionName: 'dynamic-basic-table',
      ControllerName: 'DynamicTable',
    },
  },
  {
    alias: 'DataGrid',
    url: '/data/single-endpoint-survey-diy',
    payload: {
      actionName: 'dynamic-basic-table',
      ControllerName: 'DataGrid',
    },
  },
  {
    alias: 'MetricSummary',
    url: '/data/single-endpoint',
    payload: {
      actionName: 'metric-summary',
      ControllerName: 'MetricSummary',
    },
  },
  {
    alias: 'VerticalBarChart',
    url: '/data/single-endpoint',
    payload: {
      actionName: 'vertical-bar-chart-osat',
      ControllerName: 'VerticalBarChart',
    },
  },
  {
    alias: 'HorizontalBarChart',
    url: '/data/single-endpoint',
    payload: {
      actionName: 'horizontal-bar-chart',
      ControllerName: 'HorizontalBarChart',
    },
  },
  {
    alias: 'TopBottomFive',
    url: '/data/single-endpoint',
    payload: {
      actionName: 'top-bottom-five',
      ControllerName: 'TopBottomFive',
    },
  },
  {
    alias: 'IndividualMetric',
    url: '/data/single-endpoint',
    payload: {
      actionName: 'chat-individual-sessions',
      ControllerName: 'IndividualMetric',
    },
  },
  {
    alias: 'OneQuestion',
    url: '/data/data-grid-one-question',
    payload: {
      actionName: 'dynamic-basic-table',
      ControllerName: 'DataGrid',
      mockEndpoint: 'data-grid-one-question',
    },
  },
  {
    alias: 'OneQuestionOneCalculation',
    url: '/data/data-grid-calculation-distribution-over-time',
    payload: {
      actionName: 'dynamic-basic-table',
      ControllerName: 'DataGrid',
      mockEndpoint: 'data-grid-calculation-distribution-over-time',
    },
  },
  {
    alias: 'OneQuestionOneCalculationDays',
    url: '/data/data-grid-calculation-distribution-over-time',
    payload: {
      actionName: 'dynamic-basic-table',
      ControllerName: 'DataGrid',
      mockEndpoint: 'data-grid-calculation-distribution-over-time',
    },
  },
  {
    alias: 'OneQuestionOneCalculationWeeks',
    url: '/data/data-grid-calculation-distribution-over-time-weeks',
    payload: {
      actionName: 'dynamic-basic-table',
      ControllerName: 'DataGrid',
      mockEndpoint: 'data-grid-calculation-distribution-over-time-weeks',
    },
  },
  {
    alias: 'OneQuestionOneCalculationMonths',
    url: '/data/data-grid-calculation-distribution-over-time-months',
    payload: {
      actionName: 'dynamic-basic-table',
      ControllerName: 'DataGrid',
      mockEndpoint: 'data-grid-calculation-distribution-over-time-months',
    },
  },
  {
    alias: 'OneQuestionOneCalculationQuarters',
    url: '/data/data-grid-calculation-distribution-over-time-quarters',
    payload: {
      actionName: 'dynamic-basic-table',
      ControllerName: 'DataGrid',
      mockEndpoint: 'data-grid-calculation-distribution-over-time-quarters',
    },
  },
  {
    alias: 'OneQuestionOneCalculationYears',
    url: '/data/data-grid-calculation-distribution-over-time-years',
    payload: {
      actionName: 'dynamic-basic-table',
      ControllerName: 'DataGrid',
      mockEndpoint: 'data-grid-calculation-distribution-over-time-years',
    },
  },
  {
    alias: 'OneQuestionManyCalculations',
    url: '/data/data-grid-calculations-distribution',
    payload: {
      actionName: 'dynamic-basic-table',
      ControllerName: 'DataGrid',
      mockEndpoint: 'data-grid-calculations-distribution',
    },
  },
  {
    alias: 'ManyQuestions',
    url: '/data/data-grid-multiple-questions',
    payload: {
      actionName: 'dynamic-basic-table',
      ControllerName: 'DataGrid',
      mockEndpoint: 'data-grid-multiple-questions',
    },
  },
  {
    alias: 'ManyCalculations',
    url: '/data/data-grid-multiple-calculations',
    payload: {
      actionName: 'dynamic-basic-table',
      ControllerName: 'DataGrid',
      mockEndpoint: 'data-grid-multiple-calculations',
    },
  },
  {
    alias: 'TopFive',
    url: '/data/single-endpoint',
    payload: {
      actionName: 'top-five',
      ControllerName: 'TopFive',
    },
  },
  {
    alias: 'ChatSegmentation',
    url: '/data/single-endpoint',
    payload: {
      actionName: 'chat-segmentation',
      ControllerName: 'ChatSegmentation',
    },
  },
  {
    alias: 'LineChart',
    url: '/data/single-endpoint',
    payload: {
      actionName: 'top-five-category',
      ControllerName: 'LineChart',
    },
  },
  {
    alias: 'LineChartPositiveSentiment',
    url: '/data/single-endpoint',
    payload: {
      actionName: 'top-five-category-positive-sentiment',
      ControllerName: 'LineChartPositiveSentiment',
    },
  },
  {
    alias: 'LineChartNegtiveSentiment',
    url: '/data/single-endpoint',
    payload: {
      actionName: 'top-five-category-negative-sentiment',
      ControllerName: 'LineChartPositiveSentiment',
    },
  },
  {
    alias: 'TopCategories',
    url: '/data/single-endpoint',
    payload: {
      actionName: 'top-categories',
      ControllerName: 'TopCategories',
    },
  },
  {
    alias: 'BiggestMovers',
    url: '/data/single-endpoint',
    payload: {
      actionName: 'biggest-movers',
      ControllerName: 'BiggestMovers',
    },
  },
  {
    alias: 'TextBlock',
    url: '/data/single-endpoint',
    payload: {
      actionName: 'text-block',
      ControllerName: 'TextBlock',
    },
  },
  {
    alias: 'BulletChart',
    url: '/data/single-endpoint',
    payload: {
      actionName: 'bullet-chart',
      ControllerName: 'BulletChart',
    },
  },
];
