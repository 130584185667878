import { useWidgetContext } from 'contexts/WidgetProvider';
import { WidgetAction } from 'models/WidgetModel';
import React, { useEffect, useState } from 'react';
import PckDropDown from '../../Controls/PckDropdown/PckDropdown';
import './MetricsDropdown.scss';

export interface MetricsDropdownProps {
  response: any;
}

const MetricsDropdown: React.FC<MetricsDropdownProps> = ({ response }) => {
  const [optionsL1, setOptionsL1] = useState([]);
  const [defaultValueL1, setDefaultValueL1] = useState({ value: '', label: '' });
  response = response?.data ? response?.data : response;

  const { chartView, selectedValueL1, selectedValueL2, optionsL2, defaultValueL2, setState } =
    useWidgetContext();

  useEffect(() => {
    setState({ action: WidgetAction.METRICS_DROPDOWN });
  }, [selectedValueL1, selectedValueL2]);

  useEffect(() => {
    if (response) {
      setOptionsL1(response?.controlData?.optionsL1);
      setDefaultValueL1(response?.controlData?.defaultValueL1);
    }
  }, [response]);

  return (
    <>
      <div className="pck-dropDowns">
        {optionsL1?.length ? (
          <PckDropDown
            options={optionsL1}
            defaultValue={defaultValueL1}
            onChange={(e: any) => setState({ selectedValueL1: e })}
          ></PckDropDown>
        ) : null}
        {optionsL2?.length && chartView && optionsL2[0]?.value ? (
          <PckDropDown
            options={optionsL2}
            defaultValue={defaultValueL2}
            onChange={(e: any) => setState({ selectedValueL2: e })}
          ></PckDropDown>
        ) : null}
      </div>
    </>
  );
};

export default MetricsDropdown;
