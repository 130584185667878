import { useEffect } from 'react';
import { WidgetAction } from 'models/WidgetModel';
import './SwitchChartTable.scss';
import { appAssets } from 'constants/assets';
import { useWidgetContext } from 'contexts/WidgetProvider';

const SwitchViewTable = () => {
  const { chartView, setState } = useWidgetContext();

  const ChartIcon = appAssets.icons.CHART;
  const TableIcon = appAssets.icons.TABLE;

  useEffect(() => {
    setState({ action: WidgetAction.SWITCH_CHART_TABLE });
  }, [chartView]);

  return (
    <div className="switchAction">
      <span className={!chartView ? 'active' : ''} onClick={() => setState({ chartView: false })}>
        <TableIcon className="icon" /> Table
      </span>{' '}
      <span className={chartView ? 'active' : ''} onClick={() => setState({ chartView: true })}>
        <ChartIcon className="icon" /> Chart
      </span>
    </div>
  );
};

export default SwitchViewTable;
