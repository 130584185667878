import React from 'react';
import { Skeleton, styled, SkeletonVariants } from '@cx/ui';

export type HierarchyFiltersSkeletonProps =
  React.HTMLAttributes<HTMLDivElement> & {
    filterCount?: number;
  };

function HierarchyFiltersSkeleton({
  className = '',
  filterCount = 4,
}: HierarchyFiltersSkeletonProps) {
  return (
    <div className={className}>
      {Array.from(Array(filterCount).keys()).map((index) => (
        <div
          key={`cx-hierarchy-filter-skeleton-${index}`}
          className="cx-hierarchy-filter-skeleton"
        >
          <div className="cx-hierarchy-filter-skeleton-text">
            <Skeleton variant={SkeletonVariants.text} width="100px" />
          </div>
          <Skeleton
            variant={SkeletonVariants.rectangular}
            width="100%"
            height="36px"
          />
        </div>
      ))}
    </div>
  );
}

export default styled(HierarchyFiltersSkeleton)((props) => ({
  width: '100%',
  '.cx-hierarchy-filter-skeleton': {
    padding: props.theme.spacing(1, 0),
  },
  '.cx-hierarchy-filter-skeleton-text': {
    marginBottom: props.theme.spacing(0.25),
  },
}));
