import React, { useEffect, useState } from 'react';
import { library } from 'components';
import LoadingSpinner from 'core-ui/LoadingSpinner/LoadingSpinner';
import { Widget } from 'models/WidgetModel';
import PropTypes from 'prop-types';
import WidgetToRender from 'components/Render/WidgetToRender';
import { useStore as useGlobalStore } from 'store/useGlobalStore';
import { WidgetProvider } from 'contexts/WidgetProvider';
import { useGetWidgetData } from 'hooks/useGetWidgetData';
export interface CardWidgetProps {
  widget: Widget;
  index: number;
  removeWidget?: (id: number) => void;
  previewMode?: boolean;
}

const CardWidget: React.FC<CardWidgetProps> = ({
  widget,
  index,
  removeWidget,
  previewMode = false,
}) => {
  const { url, payload, customWidgetConfig } = widget;

  const [alias, setAlias] = useState<string>();
  const endpoints = useGlobalStore((state) => state.endpoints);
  const dataSourceType = useGlobalStore((state) => state.dataSourceType);
  const setState = useGlobalStore((state) => state.setState);
  const [dateFilterModel, setDateFilterModel] = useState<any>();
  const { data: response, error, getData, loading, waitingForSignalR } = useGetWidgetData();
  useEffect(() => {
    if (widget?.alias !== library.CUSTOM_WIDGET || widget?.alias !== library.QUERY_WIDGET) {
      setAlias(widget.alias);
    }
  }, [widget]);

  useEffect(() => {
    if ((url || endpoints.getWidgetData) && alias && payload) {
      if (customWidgetConfig?.DataSource !== 'generic') getData(payload);
    }
  }, [alias, payload]);

  const validateData = (res: any) => {
    if (!res?.data) return false;

    const {
      series,
      seriesData,
      corporatePulseStats,
      corporatePulseBottomStats,
      topFive,
      bottomFive,
      xData,
      data,
      textContent,
      styles,
    } = res.data;

    const { dateFilterModel } = res;

    const textBlock = textContent && styles;

    const corporatePulseStatsData =
      Array.isArray(corporatePulseStats) && corporatePulseStats.length > 0;

    const corporatePulseBottomStatsData =
      Array.isArray(corporatePulseBottomStats) && corporatePulseBottomStats.length > 0;

    const seriesValues = series?.find((i: any) => {
      return i.value !== 0;
    });

    setDateFilterModel(dateFilterModel);

    return (Array.isArray(series) && series.length > 0 && seriesValues) ||
      (Array.isArray(seriesData) && seriesData.length > 0) ||
      corporatePulseStatsData ||
      corporatePulseBottomStatsData ||
      textBlock ||
      (Array.isArray(topFive) && topFive.length > 0) ||
      (Array.isArray(bottomFive) && bottomFive.length > 0) ||
      (Array.isArray(xData) && xData.length > 0) ||
      (Array.isArray(data) && data.length > 0)
      ? true
      : false;
  };

  const editWidget = (widget: any) => {
    setState({ customWidget: widget });
  };

  if (loading) {
    return (
      <WidgetProvider>
        <LoadingSpinner />
      </WidgetProvider>
    );
  } else if (waitingForSignalR) {
    //TODO: This loading spinner should be a new componente with a new animation. Waiting for UX to provide the design
    return (
      <WidgetProvider>
        <LoadingSpinner text="Your data is taking longer than expected. Please wait." />
      </WidgetProvider>
    );
  } else {
    return (
      <WidgetProvider>
        <WidgetToRender
          {...{
            removeWidget,
            editWidget,
            widget,
            validateData,
            response,
            index,
            loading: loading || waitingForSignalR,
            error,
            dataSourceType,
            customWidgetConfig,
            previewMode,
            dateFilterModel,
          }}
        />
      </WidgetProvider>
    );
  }
};

CardWidget.propTypes = {
  widget: PropTypes.any.isRequired,
};

export default CardWidget;
