export const setNumericData = (data: Array<any>) => {
  const newData: Array<any> = [];
  try {
    data.forEach((element: any) => {
      Object.getOwnPropertyNames(element).forEach((propertyName) => {
        element[propertyName] = Number(element[propertyName])
          ? convertStringToNumber(element[propertyName])
          : element[propertyName];
      });
      newData.push(element);
    });
    return newData;
  } catch (error: any) {
    return data;
  }
};

const convertStringToNumber = (value: any) => {
  if (value.includes('.')) {
    // If the string has a decimal point, parse it as a float
    return value;
  }
  // If it's a whole number, parse it as an integer
  return parseInt(value, 10);
};
