interface MinMax {
  Min: number;
  Max: number;
}
interface Rules {
  Name: string;
  Questions: MinMax;
  Calculations: MinMax;
}

export const helperTextByQuestion = (rules: Rules[]): string => {
  const { qRule, cRule } = getRuleByType(rules, 'Calculations');

  if (qRule.Min === 1 && qRule.Max === 1 && cRule.Min === 1 && cRule.Max >= 1) {
    return `Select one question`;
  }
  if ((qRule.Min === 1 && qRule.Max === 1) || (cRule.Min === 1 && cRule.Max === 1)) {
    return `Select one question`;
  }
  if ((qRule.Min === 1 && qRule.Max > 1) || (cRule.Min === 1 && cRule.Max > 1)) {
    return `Select 1 - ${qRule.Max} questions`;
  }
  if ((qRule.Min === 0 && qRule.Max === 1) || (cRule.Min === 0 && cRule.Max === 1)) {
    return `Optionally select one question`;
  }
  if ((qRule.Min === 0 && qRule.Max > 1) || (cRule.Min === 0 && cRule.Max > 1)) {
    return `Optionally select up to ${qRule.Max} questions`;
  }
  return 'Select a question';
};

export const helperTextByCalculation = (rules: Rules[]): string => {
  const { qRule, cRule } = getRuleByType(rules, 'Calculations');

  if (qRule.Min === 1 && qRule.Max === 1 && cRule.Min === 1 && cRule.Max >= 1) {
    return `Optionally select ${cRule.Max} calculation${cRule.Max > 1 ? 's' : ''}`;
  }
  if ((qRule.Min === 1 && qRule.Max === 1) || (cRule.Min === 1 && cRule.Max === 1)) {
    return `Select one calculation`;
  }
  if ((qRule.Min === 1 && qRule.Max > 1) || (cRule.Min === 1 && cRule.Max > 1)) {
    return `Select 1 - ${cRule.Max} calculations`;
  }
  if ((qRule.Min === 0 && qRule.Max === 1) || (cRule.Min === 0 && cRule.Max === 1)) {
    return `Optionally select one calculation`;
  }
  if ((qRule.Min === 0 && qRule.Max > 1) || (cRule.Min === 0 && cRule.Max > 1)) {
    return `Optionally select up to ${cRule.Max} calculations`;
  }

  return 'Select a calculation';
};

const getRuleByType = (rules: Rules[], ruleType: 'Questions' | 'Calculations') => {
  const qMaxVal = Math.max(...rules.map((r: Rules) => r.Questions.Max));
  const cMaxVal = Math.max(...rules.map((r: Rules) => r.Calculations.Max));
  const qRule =
    // eslint-disable-next-line prettier/prettier
    rules.find((r: Rules) => r.Questions.Max === qMaxVal)?.Questions || ({} as Rules).Questions;
  const cRule =
    rules.find((r: Rules) => r.Calculations.Max === cMaxVal)?.Calculations ||
    ({} as Rules).Calculations;
  return { qRule, cRule };
};
