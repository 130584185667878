import React from 'react';
import { MsalProvider } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';

function LoginProvider({
  children,
  msalInstance,
}: {
  children: React.ReactNode;
  msalInstance: IPublicClientApplication;
}) {
  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
}

export default LoginProvider;
