import React from 'react';
import './CardHeaderActions.scss';
import { headerActionComponents } from 'components/actionsIndex';

export interface HeaderActions {
  actions: string[];
  response?: any;
}

const CardHeaderActions: React.FC<HeaderActions> = ({ actions, response }) => {
  return (
    <>
      {actions?.length ? (
        <>
          {actions?.map((item: any, i: number) => {
            if (headerActionComponents[item]) {
              const ActionComponent = headerActionComponents[item];
              return <ActionComponent key={i} response={response} />;
            }
          })}
        </>
      ) : null}
    </>
  );
};

export default CardHeaderActions;
