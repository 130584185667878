import React, { useEffect } from 'react';
import { HierarchyFilter, HierarchyData, SelectedFilter } from 'types';
import { styled } from 'theme';
import { useAppState, useAppActions, selectors } from 'store';
import { useApi } from 'store/hooks';
import HierarchyFilterDropdown, {
  HierarchyFilterDropdownProps,
} from './HierarchyFilterDropdown';
import HierarchyFiltersSkeleton, {
  HierarchyFiltersSkeletonProps,
} from './HierarchyFiltersSkeleton';

export interface HierarchyFiltersProps {
  /**
   * Callback called when the hierarchy filter changes
   */
  onHierarchyFilterChange?: (state: HierarchyData) => void;
  onHierarchyFilterSelected?: (
    hierarchyFilter: HierarchyFilter,
    values: string[]
  ) => void;
  onFocusHierarchyFilter?: (hierarchyFilter: HierarchyFilter) => void;
  LoadingComponent?: React.ComponentType<HierarchyFiltersSkeletonProps | any>;
  selectProps?: HierarchyFilterDropdownProps['selectProps'];
}

/**
 * @typedef HierarchyFiltersProps
 * @description HierarchyFilters component. This component have to be wrapped with the AppStoreProvider and ThemeProvider components. If you want a standalone version use the {@link HierarchyFiltersWithProviders} component.
 * @category Component
 * */

function HierarchyFilters({
  className = '',
  LoadingComponent = HierarchyFiltersSkeleton,
  selectProps,
  onHierarchyFilterChange = () => {},
  onHierarchyFilterSelected = () => {},
  onFocusHierarchyFilter = () => {},
}: HierarchyFiltersProps & React.HTMLAttributes<HTMLDivElement>) {
  const { initHierarchyFilters, selectHierarchyFilter, updateHierarchyFilter } =
    useAppActions();

  const api = useApi();
  const selectedFilters = useAppState(selectors.hierarchy.getSelectedFilters);
  const isLoading = useAppState(selectors.hierarchy.getIsLoading);
  const filters = useAppState(selectors.hierarchy.getFilters);
  const HierarchyGuid = useAppState(selectors.hierarchy.getHierarchyGuid);
  const WorkspaceId = useAppState(selectors.getWorkspaceId);
  const ViewId = useAppState(selectors.getViewId);
  const FilteringSolution = useAppState(
    selectors.hierarchy.geFilteringSolution
  );
  const handleFocusHierarchyFilter = (hierarchyFilter?: HierarchyFilter) => {
    if (
      hierarchyFilter.ColPosition >= 3 &&
      hierarchyFilter.Choices.length === 0
    ) {
      updateHierarchyFilter(hierarchyFilter);
    }
    onFocusHierarchyFilter?.(hierarchyFilter);
  };

  const handleTypeAhead = async (
    inputValue: string,
    filter: HierarchyFilter
  ) => {
    const { Choices } = filter || {};
    if (Choices[Choices.length - 1]?.Label === '...') {
      const filtersValues = Object.entries(selectedFilters || {})?.map(
        ([, { filter: currentFilter, values }]: [string, SelectedFilter]) => ({
          AttributeName: currentFilter.AttributeName,
          Values: values,
          WildcardExpression: null,
        })
      );

      const bodyRequest = {
        WorkspaceId,
        FilteringSolution,
        Parameters: {
          Levels: [filter.AttributeName],
          Filters: [
            ...filtersValues,
            {
              AttributeName: filter.AttributeName,
              Values: [],
              WildcardExpression: inputValue || null,
            },
          ],
          ViewId,
        },
      };
      const res = await api.updateFilters(bodyRequest);

      return res?.Filters?.[0].Choices.map(
        ({ Label: label, Value: value }: any) => ({ label, value })
      );
    }

    return Choices?.filter?.((item) =>
      item.Value.toUpperCase().includes(inputValue.toUpperCase())
    )?.map(({ Label: label, Value: value }: any) => ({ label, value }));
  };

  const handleSelectHierarchyFilter = (
    hierarchyFilter: HierarchyFilter,
    values: string[]
  ) => {
    selectHierarchyFilter?.(hierarchyFilter, values);
    onHierarchyFilterSelected?.(hierarchyFilter, values);
  };

  useEffect(() => {
    initHierarchyFilters();
  }, []);

  useEffect(() => {
    onHierarchyFilterChange?.({
      SelectedFilters: selectedFilters,
      Filters: filters,
      HierarchyGuid,
    });
  }, [selectedFilters, filters, HierarchyGuid]);

  return (
    <div data-testid="cx-hierarchy-filters-container" className={className}>
      {isLoading || filters?.length === 0 ? (
        <LoadingComponent filterCount={4} />
      ) : (
        filters?.map((item) => (
          <HierarchyFilterDropdown
            key={item.AttributeName}
            isLoading={isLoading}
            selectedFilters={selectedFilters}
            hierarchyFilter={item}
            onSelectHierarchyFilter={handleSelectHierarchyFilter}
            onFocusHierarchyFilter={handleFocusHierarchyFilter}
            onLoadOptions={handleTypeAhead}
            selectProps={selectProps}
          />
        ))
      )}
    </div>
  );
}

export default styled(HierarchyFilters)({
  width: '100%',
});
