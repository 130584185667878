import React, { useEffect } from 'react';
import { useLoginStore } from '../store';

const UserProvider = ({ children }: React.PropsWithChildren) => {
  const { getUser } = useLoginStore((state) => state);

  useEffect(() => {
    getUser();
  }, []);

  return children;
};

export default UserProvider;
