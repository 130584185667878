import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import './multipleSelectSortable.scss';
import React from 'react';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, useSortable, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Select, { components, MultiValueGenericProps, MultiValueProps } from 'react-select';
import { appAssets } from 'constants/assets';
import chipotleStyles from 'core-ui/styles/chipotletSelect';

export interface MultipleSelectProps {
  loading: boolean;
  handleDragEnd: any;
  options: any;
  selectedChange: any;
  selectedValues?: Array<SelectOptions>;
  label: string;
  onBlurEvent: any;
  helperText: string;
}

export interface SelectOptions {
  value: string;
  label: string;
  longText: string;
  raw: any;
}

const SortableMultiValueLabel = (props: MultiValueGenericProps<SelectOptions>) => {
  const { attributes, listeners } = useSortable({ id: props.data.value });
  return (
    <div {...attributes} {...listeners}>
      <components.MultiValueLabel {...props} />
    </div>
  );
};
const SortableMultiValue = (props: MultiValueProps<SelectOptions>) => {
  const { setNodeRef, transform, transition } = useSortable({
    id: props.data.value,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <div ref={setNodeRef} style={style}>
      <components.MultiValue {...props} />
    </div>
  );
};

const MultipleSelectSortable: React.FC<MultipleSelectProps> = ({
  loading,
  handleDragEnd,
  options,
  selectedChange,
  label,
  selectedValues,
  onBlurEvent,
  helperText,
}) => {
  const SearchIcon = appAssets.icons.SEARCH;
  const getDefaultQuestions = () => {
    return [];
  };
  const placeholderComponent = (
    <div className="placeholder-container">
      <SearchIcon />
      <span className="placeholder-text">Search</span>
    </div>
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const MenuList = (props: any) => (
    <components.MenuList {...props}>
      <div className="helper-text">{helperText}</div>
      {props.children}
    </components.MenuList>
  );

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={selectedValues ? selectedValues.map((s) => s.label) : []}>
        <div className="diy-input-container">
          <div className="diy-material-textfield">
            <Select
              isLoading={loading}
              className="diy-select"
              isMulti
              options={options}
              isClearable={false}
              defaultValue={getDefaultQuestions()}
              placeholder={placeholderComponent}
              onBlur={onBlurEvent}
              value={selectedValues}
              onChange={selectedChange}
              styles={chipotleStyles}
              closeMenuOnSelect={false}
              formatOptionLabel={(option: SelectOptions) => (
                <Tippy
                  className="tooltip-container"
                  content={
                    <div>
                      <div className="tooltip-title">{option.label}</div>
                      <div className="tooltip-text">{option.longText}</div>
                    </div>
                  }
                >
                  <div className="diy-option">
                    <span className="diy-select-label">{option.label}</span>
                  </div>
                </Tippy>
              )}
              components={{
                MultiValue: SortableMultiValue,
                MultiValueLabel: SortableMultiValueLabel,
                MenuList: MenuList,
              }}
            />
            <label className="diy-label select">{label}</label>
          </div>
        </div>
      </SortableContext>
    </DndContext>
  );
};

export default MultipleSelectSortable;
