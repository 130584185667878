import { registerMap } from 'echarts';
import { geoAlbersUsa } from 'd3-geo';
import React, { FC, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import usaMap from '../../../assets/maps/usa.json';
import { tooltip } from 'constants/chartOption';
import { BaseWidget } from 'models/WidgetModel';

registerMap('USA', JSON.stringify(usaMap));

interface GeoMapsProps extends BaseWidget {
  colors: string[];
}

const GeoMap: FC<GeoMapsProps> = ({ colors, response, ...props }) => {
  const { width, height } = props;
  const [data, setData] = React.useState<any[]>([]);
  const [colorPalette, setColorPallete] = React.useState<string[]>([]);
  const [minValue, setMinValue] = React.useState<number>(0);
  const [maxValue, setMaxValue] = React.useState<number>(0);
  const [isPercentage, setIsPercentage] = React.useState<boolean>(false);

  const projection = geoAlbersUsa();

  useEffect(() => {
    if (response) {
      setData(response.data.series);
      setColorPallete(colors);
      setIsPercentage(response.isPercentage);
      setMinValue(
        response?.data?.series?.reduce(
          (min: number, item: any) => (item.value < min ? item.value : min),
          response.data.series[0].value
        )
      );
      setMaxValue(
        response?.data?.series?.reduce(
          (max: number, item: any) => (item.value > max ? item.value : max),
          response.data.series[0].value
        )
      );
    }
  }, [response]);

  const options = {
    tooltip: {
      ...tooltip,
      trigger: 'item',
      showDelay: 0,
      transitionDuration: 0.2,
      formatter: (params: any) => {
        const value = `${params.value} ${isPercentage ? '%' : ''}`;
        return `<strong>${params.name}:</strong> ${params.value ? value : 'No Data'}`;
      },
    },
    visualMap: {
      show: false,
      min: minValue,
      max: maxValue,
      inRange: {
        color: colorPalette,
      },
      calculable: true,
    },
    toolbox: {
      show: false,
    },
    series: [
      {
        type: 'map',
        map: 'USA',
        emphasis: {
          label: {
            show: false,
          },
        },
        select: false,
        projection: {
          project: function (point: any) {
            return projection(point);
          },
          unproject: function (point: any) {
            return projection.invert ? projection.invert(point) : [NaN, NaN];
          },
        },
        data: data,
      },
    ],
  };

  return (
    <>
      <div className="responsive-chart">
        <ReactECharts opts={{ renderer: 'svg', width, height }} option={options} />
      </div>
    </>
  );
};

export default GeoMap;
