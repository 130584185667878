import GoalLine from 'core-ui/GoalLine/GoalLine';
import YAxisValueComponent from 'core-ui/yAxisValueComponent/yAxisValueComponent';

export const OptionSelector = {
  option: {
    GoalLine,
    YAxisValueComponent,
  },
  // eslint-disable-next-line prettier/prettier
} as const;

export type ChartOptionSelector = keyof typeof OptionSelector.option;
