import { decimalPlaces } from 'constants/chartOption';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';

export interface SelectDecimalPlaces {
  option: any;
  onChangeHandler: any;
}

const customStyles = {
  option: (styles: any, state: any) => {
    const newStyles = {
      ...styles,
      '.diy-select-description': {
        color: state.isSelected ? 'white' : '#999999',
      },
    };
    return { ...newStyles };
  },
};

const selectDecimalPlaces: React.FC<SelectDecimalPlaces> = ({ option, onChangeHandler }) => {
  const [selectedValue, setSelected] = useState<any>({
    label: option.value.toString(),
    value: option.value,
  });

  const clickHandler = (event: any, target: any) => {
    setSelected(event);
    onChangeHandler(event, target);
  };

  useEffect(() => {
    clickHandler(selectedValue, option);
  }, []);

  return (
    <>
      <div className="option-item">
        <span className="toggler-label">{option.displayName}</span>
        <Select
          className="diy-select"
          options={decimalPlaces}
          name={option.actionName}
          value={selectedValue}
          onChange={clickHandler}
          styles={customStyles}
        />
      </div>
    </>
  );
};

export default selectDecimalPlaces;
