import React, { useEffect, useRef, useState } from 'react';
import ReactECharts, { EChartsOption } from 'echarts-for-react';
import {
  axisLabel,
  yAxisLabel,
  axisTick,
  axisLine,
  splitLine,
  tooltip,
  stackedBarOption,
  stackedLineOption,
  legendTextStyle,
  legendCommon,
  labelCommon,
} from '../../../constants/chartOption';
import './StackedBarChart.scss';
import { BaseWidget, WidgetAction, CustomConfig } from 'models/WidgetModel';
import { eyeIcon } from 'constants/assets';
import { legendSelectSeries } from 'helpers/selectStackedBarSerie';
import { getMonthName, getFormattedDate } from 'helpers/dateFormatters';
import { useWidgetContext } from 'contexts/WidgetProvider';

interface stackedBarProps extends BaseWidget {
  colors: string[];
  options: any;
  isCustom?: boolean;
  customWidgetConfig?: CustomConfig;
}

const StackedBarChart: React.FC<stackedBarProps> = ({
  response,
  colors,
  options,
  isCustom,
  customWidgetConfig,
  alias,
  dataSourceType,
  ...props
}) => {
  const { isLine, showLineChart } = options;
  const [data, setData] = useState<(string[] | number[])[]>([]);
  const [optionsChart, setOptionsChart] = useState<any>();
  const [colorPalette, setColorPallete] = useState<string[]>([]);
  const [isPercentage, setIsPercentage] = useState<boolean>(false);
  const [showLegend, setShowLegend] = useState<boolean>(true);
  const [showValues, setShowValues] = useState<boolean>(false);
  const [drawGoalLine, setdrawGoalLine] = React.useState<boolean>(false);
  const [goalValues, setGoalValues] = useState<any>({});
  const [yAxisValues, setYAxisValues] = useState<any>({});

  const [switchView, setSwitchView] = useState<boolean>(true);
  const { action, defaults, setState } = useWidgetContext();
  const { width, height } = props;

  let echartInstance: any;
  const [selectedSeries, setSelectedSeries] = useState<any>([]);

  const colorsRef = useRef<string[]>();
  colorsRef.current = colorPalette;

  const commonStackedBar = switchView ? stackedLineOption : stackedBarOption;

  const {
    goal,
    optYAxisValues,
    setYAxisMinMaxValues: optSetYAxisMinMaxValues,
    drawGoalLine: optDrawGoalLine,
    showLegend: optShowLegend,
    showValues: optShowValues,
  } = options;

  useEffect(() => {
    if (goal) {
      setGoalValues(goal);
    }
  }, [goal]);

  useEffect(() => {
    if (optYAxisValues) {
      setYAxisValues(optYAxisValues);
    }
  }, [optYAxisValues]);

  useEffect(() => {
    if (optSetYAxisMinMaxValues) {
      setIsPercentage(yAxisValues && yAxisValues.max > 100 ? false : true);
    } else {
      setIsPercentage(response?.data?.isPercentage ?? false);
    }
  }, [yAxisValues]);

  useEffect(() => {
    if (options && 'optYAxisValues' in options && optSetYAxisMinMaxValues) {
      optSetYAxisMinMaxValues ? setYAxisValues(optYAxisValues) : setYAxisValues({});
    } else {
      setYAxisValues({});
    }
  }, [optSetYAxisMinMaxValues]);

  useEffect(() => {
    if (options && 'drawGoalLine' in options) {
      optDrawGoalLine ? setdrawGoalLine(optDrawGoalLine) : setGoalValues({});
    }
  }, [optDrawGoalLine]);

  useEffect(() => {
    if (isCustom) {
      setShowLegend(optShowLegend);
    }
  }, [optShowLegend]);

  useEffect(() => {
    if (isCustom) {
      setShowValues(optShowValues);
    }
  }, [optShowValues]);

  useEffect(() => {
    if (response?.data?.series) {
      const responseData = response.data.series;
      const periodType =
        isCustom && customWidgetConfig?.Trend
          ? customWidgetConfig.Trend[0]?.TimePeriodType || ''
          : '';

      if (Array.isArray(responseData[0])) {
        responseData[0] = responseData[0]?.map((item: string | number) => {
          if (isCustom && customWidgetConfig?.Trend) {
            return getFormattedDate(item, periodType, response?.data?.showYear);
          } else {
            return getMonthName(item, response?.data?.showYear);
          }
        });
      } else {
        responseData[0] = {};
      }

      setData(response?.data?.series);
      setColorPallete(colors);
      if (Object.keys(yAxisValues).length > 0 && optSetYAxisMinMaxValues) {
        setIsPercentage(yAxisValues.max <= 100);
      } else {
        setIsPercentage(response?.data?.isPercentage ?? false);
      }
    }
  }, [response]);

  useEffect(() => {
    if (colors) {
      setColorPallete(colors);
    }
  }, [colors]);

  useEffect(() => {
    if (action === WidgetAction.SWITCH_BAR_LINE) {
      setSwitchView(!switchView);
      setState({ action: WidgetAction.NONE });
    }
  }, [action]);

  useEffect(() => {
    setSwitchView(options?.showLineChart);
    setState({
      defaults: {
        ...defaults,
        SWITCH_BAR_LINE: showLineChart ? 'line' : 'bars',
      },
    });
  }, [options?.showLineChart]);

  useEffect(() => {
    if (data && data?.length) {
      const optionsObj: EChartsOption = initOptions();
      setOptionsChart(optionsObj);
    }
  }, [
    data,
    switchView,
    colorPalette,
    options,
    colors,
    optShowLegend,
    optShowValues,
    goalValues,
    optDrawGoalLine,
    yAxisValues,
  ]);

  const GetSeries = () => {
    const { lineStyle, lineWidth, goalLineColor, goalValue } = goalValues;
    if (optSetYAxisMinMaxValues && yAxisValues && yAxisValues.max > 100) {
      setIsPercentage(false);
    } else {
      setIsPercentage(response?.data?.isPercentage ?? false);
    }
    return data
      .map((s: any, i: number) => {
        const stackedOption = isCustom
          ? {
              ...commonStackedBar,
              markLine: {
                symbol: 'none',
                lineStyle: {
                  type: lineStyle ? lineStyle : 'solid',
                  width: lineWidth && drawGoalLine ? lineWidth : 0,
                  color: goalLineColor ? goalLineColor : '#000',
                },
                data: [
                  {
                    label: {
                      show: false,
                    },
                    tooltip: {
                      formatter: `<strong>Goal{b}:</strong> {c}${isPercentage ? '%' : ''}`,
                    },
                    yAxis: goalValue ? goalValue : 0,
                  },
                ],
              },
            }
          : i !== data.length - 1 && !isLine
          ? commonStackedBar
          : stackedLineOption;

        return {
          ...stackedOption,
          itemStyle: {
            color: colorsRef.current?.length ? colorsRef.current[i - 1] : colorPalette[i - 1],
            borderRadius: 4,
          },
        };
      })
      .filter((s: any, i: number) => i !== 0);
  };

  const initOptions = () => {
    if (optSetYAxisMinMaxValues && yAxisValues && yAxisValues.max > 100) {
      setIsPercentage(false);
    } else {
      setIsPercentage(response?.data?.isPercentage ?? false);
    }
    if (!colorsRef.current?.length) return {};
    return {
      legend: {
        ...legendCommon,
        textStyle: {
          ...legendTextStyle,
        },
        selector: [
          {
            type: 'all',
            title: '{icon|}{a|Show All}',
          },
        ],
        selectorLabel: {
          show: true,
          rich: {
            a: {
              fontSize: 11,
            },
            icon: {
              width: 30,
              backgroundColor: {
                image: eyeIcon,
              },
            },
          },
          borderWidth: 0,
        },
        emphasis: {
          selectorLabel: {
            color: '#8a8a8a',
            backgroundColor: 'transparent',
          },
        },
        selectorPosition: 'end',
        selectedMode: true,
        show: optShowLegend,
      },
      label: {
        // show values at top when `showValues` is true
        show: optShowValues,
        ...labelCommon,
        fontSize: 11,
        formatter: (params: any) => {
          const seriesValue = params.value[params.seriesIndex + 1];
          if (params.componentSubType !== 'line') {
            return `${seriesValue}${isPercentage ? '%' : ''}`;
          } else {
            return `${seriesValue}${isPercentage ? '%' : ''}`;
          }
        },
      },
      grid: {
        left: '32',
        right: '15',
        top: '20',
        bottom: optShowLegend ? '45' : '15',
        containLabel: true,
      },
      tooltip: {
        ...tooltip,
        formatter: (params: any) => {
          const seriesValue = params.value[params.seriesIndex + 1];
          if (params.componentSubType !== 'line') {
            return `${seriesValue}${isPercentage ? '%' : ''}`;
          } else {
            return `<strong>${params.seriesName}:</strong> ${seriesValue}${
              isPercentage ? '%' : ''
            }`;
          }
        },
      },
      dataset: {
        source: data,
      },
      xAxis: {
        type: 'category',
        ...axisTick,
        ...axisLabel,
        ...axisLine,
      },
      yAxis: {
        type: 'value',
        ...axisTick,
        ...(optSetYAxisMinMaxValues && yAxisValues && yAxisValues.max >= 1000
          ? yAxisLabel(yAxisValues.max)
          : axisLabel),
        ...axisLine,
        ...splitLine,
        ...yAxisValues,
      },
      series: [...GetSeries()],
    };
  };

  const selectedSeriesHandler = (params: any) => {
    !selectedSeries.includes(params.name)
      ? selectedSeries.push(params.name)
      : selectedSeries.splice(selectedSeries.indexOf(params.name), 1);
    setSelectedSeries(selectedSeries);
  };

  const dispatchLegendAction = (seriesName: string) => {
    echartInstance?.setOption({ animation: false });
    echartInstance?.dispatchAction({
      type: 'legendSelect',
      name: seriesName,
    });
    echartInstance?.setOption({ animation: true });
  };

  const selectSeries = () => {
    echartInstance?.setOption({ animation: false });
    const optionSeries: any = echartInstance?.getOption().series;
    const temporalSeries = legendSelectSeries(
      selectedSeries,
      optionSeries,
      colorsRef.current || [],
      data
    );
    echartInstance?.setOption({ animation: true, series: temporalSeries });
  };

  const selectAllSeries = () => {
    echartInstance?.setOption({ animation: false });
    setSelectedSeries([]);
    echartInstance?.dispatchAction({
      type: 'legendSelectAll',
    });
    setOptionsChart({ ...optionsChart });
    echartInstance?.setOption({ animation: true });
  };

  const echartOnEvents = {
    legendselectchanged: (params: any) => {
      selectedSeriesHandler(params);
      dispatchLegendAction(params.name);
      selectSeries();
    },
    legendselectall: () => {
      selectAllSeries();
    },
  };

  return (
    <div className="responsive-chart" data-testid="StackedBarChart">
      {optionsChart?.series ? (
        <ReactECharts
          ref={(ei) => {
            echartInstance = ei?.getEchartsInstance();
            selectSeries();
          }}
          opts={{ renderer: 'svg', width, height }}
          option={optionsChart}
          onEvents={echartOnEvents}
          notMerge={true}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default StackedBarChart;
