import { isHosted } from '../utils';
import { acquireAccessToken } from './acquireAccessToken';

type TempStorage = {
  [key: string]: any;
};

const tempStorage: TempStorage = {
  accessToken: null,
};

/**
 * Method to get the user token.
 * @returns {Promise<string>} - The user token.
 */
export const getUserToken = async () => {
  if (isHosted()) {
    if (tempStorage.accessToken) return tempStorage.accessToken;
    const response = await fetch('/user/GetAccessToken');
    const { accessToken } = await response.json();
    tempStorage.accessToken = accessToken;
    return accessToken;
  }
  const accessToken = await acquireAccessToken(
    window.msalInstance.current,
    window.scopesRef.current
  );
  return accessToken;
};

/**
 * Fetch method to make an authenticated request to the API.
 * @param url - The URL to fetch.
 * @param param1 - Additional options for the request.
 * @returns {Promise<Response | null>} - The fetch response or null if an error occurs.
 */
export const request = async (
  url: string,
  { contentType = 'application/json', body = null, ...options }: any
) => {
  try {
    const accessToken = await getUserToken();
    return await fetch(url, {
      ...options,
      headers: {
        'Content-Type': contentType,
        ...(options?.headers || {}),
        Authorization: `Bearer ${accessToken}`,
      },
      ...(body ? { body: JSON.stringify(body) } : {}),
    });
  } catch (error) {
    console.error('Error:login:request', { error });
    return null;
  }
};
