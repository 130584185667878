import {
  takeEvery,
  select,
  getContext,
  fork,
  takeLeading,
} from 'redux-saga/effects';
import {
  FECTH_ADVANCED_FILTERS_QUESTION_LIST,
  FECTH_ADVANCED_FILTERS_QUESTION_LIST_SUCCESS,
  FECTH_ADVANCED_FILTERS_QUESTION_LIST_ERROR,
  ADD_ADVANCED_FILTER,
  REMOVE_ADVANCED_FILTER,
  FECTH_ATTRIBUTE_CHOICES,
  UPDATE_ADVANCED_FILTER,
  AddAdvancedFilterActions,
  RemoveAdvancedFilterActions,
  UpdateAdvancedFilterActions,
  FECTH_QUESTION_DETAILS,
  FetchQuestionDetailsActions,
  INIT_ADVANCED_FILTER,
  InitAdvancedFilterAction,
} from 'store/actions';
import { generateId, getSetStateMethods } from 'utils';
import {
  SelectedAdvancedFilter,
  FilterSlice,
  ApiType,
  FilteringSolutionEnum,
  AdvancedFiltersActions,
} from 'types';
import selectors from 'store/selectors';

const { setDataState, setUiState } =
  getSetStateMethods<FilterSlice>('advanced');

// Helper function to merge the advanced filter with the previous state
const mergeAdvancedFilter = ({
  filter,
  selectedAdvancedFilter,
  index,
  ...props
}: {
  filter: SelectedAdvancedFilter;
  selectedAdvancedFilter: SelectedAdvancedFilter[];
  index: number;
  [key: string]: any;
}) => {
  const prevProps = selectedAdvancedFilter[index] || {};
  // In case the filter come from initial state, we need to generate an id
  const id = prevProps?.id || generateId();
  return {
    id,
    ...prevProps,
    ...filter,
    ...props,
  };
};

function* initAdvancedFiltersSagas({
  callback,
}: InitAdvancedFilterAction): Generator<any> {
  try {
    const { getQuestionDetails } = (yield select(
      (state) => state
    )) as AdvancedFiltersActions;
    const selectedFilters = (yield select(
      selectors.advanced.getSelectedFilters
    )) as SelectedAdvancedFilter[];

    for (let i = 0; i < selectedFilters.length; i += 1) {
      const filter = selectedFilters[i];
      if (
        filter?.AttributeGUID &&
        filter?.OpType &&
        filter?.SubAttribute &&
        filter?.Values &&
        !filter?.questionDetails
      ) {
        yield fork(getQuestionDetails, {
          filter,
          index: i,
        });
      }
    }
  } catch (e) {
    console.log(e);
  } finally {
    callback?.();
  }
}

function* fetchAdvancedFiltersQuestionList(): Generator<any> {
  const api = (yield getContext('api')) as ApiType;
  const WorkspaceId = (yield select(selectors.getWorkspaceId)) as string;
  const AdvanceFiltersGuid = (yield select(
    selectors.advanced.getAdvanceFiltersGuid
  )) as string;
  const filteringSolution = (yield select(
    selectors.advanced.geFilteringSolution
  )) as FilteringSolutionEnum;
  const {
    getAdvancedFiltersQuestionListError,
    getAdvancedFiltersQuestionListSuccess,
  }: FilterSlice | any = yield select((state) => state);

  try {
    yield setUiState({ loading: true });
    const data = yield api.getAdvancedFiltersQuestionList({
      WorkspaceId,
      AdvanceFiltersGuid,
      FilteringSolution: filteringSolution,
    });
    yield getAdvancedFiltersQuestionListSuccess({ Filters: data });
  } catch (e) {
    yield getAdvancedFiltersQuestionListError(e);
    console.log(e);
  } finally {
    yield setUiState({ loading: false });
  }
}
function* fetchAdvancedFiltersQuestionListSuccess({
  data,
}: any): Generator<any> {
  try {
    yield setDataState(data);
  } catch (e) {
    console.log(e);
  }
}
function* fetchAdvancedFiltersQuestionListError(error: any): Generator<any> {
  try {
    yield setUiState({ error });
  } catch (e) {
    console.log(e);
  }
}

function* getQuestionDetailsSagas({
  filter,
  index,
}: FetchQuestionDetailsActions): Generator<any> {
  if (filter?.questionDetails) return;
  const api = (yield getContext('api')) as ApiType;
  try {
    const selectedAdvancedFilter =
      ((yield select(
        selectors.advanced.getSelectedFilters
      )) as SelectedAdvancedFilter[]) || [];
    const filteringSolution = (yield select(
      selectors.advanced.geFilteringSolution
    )) as FilteringSolutionEnum;
    if (filter?.AttributeGUID) {
      yield setUiState({ loading: true });
      const questionDetails = yield api.getQuestionDetails(
        filter?.AttributeGUID,
        filteringSolution
      );

      selectedAdvancedFilter[index] = mergeAdvancedFilter({
        filter,
        selectedAdvancedFilter,
        index,
        questionDetails,
      });

      yield setDataState({
        SelectedFilters: selectedAdvancedFilter,
      });
    }
  } catch (error) {
    console.log(error);
    yield setUiState({ error });
  } finally {
    yield setUiState({ loading: false });
  }
}

function* addAdvancedFilter({
  filter,
  index,
  callback,
}: AddAdvancedFilterActions): Generator<any> {
  try {
    const { getQuestionDetails } = (yield select(
      (state) => state
    )) as AdvancedFiltersActions;
    const selectedAdvancedFilter =
      ((yield select(
        selectors.advanced.getSelectedFilters
      )) as SelectedAdvancedFilter[]) || [];

    selectedAdvancedFilter[index] = mergeAdvancedFilter({
      filter: {
        ...filter,
        Values: [],
      },
      selectedAdvancedFilter: [],
      index,
      id: generateId(),
    });

    yield setDataState({
      SelectedFilters: selectedAdvancedFilter,
    });

    yield getQuestionDetails({ filter, index });
  } catch (e) {
    console.log(e);
  } finally {
    yield callback?.();
  }
}

function* getAttributeChoices({ filter, index }: any): Generator<any> {
  const api = (yield getContext('api')) as ApiType;
  try {
    const filteringSolution = (yield select(
      selectors.advanced.geFilteringSolution
    )) as FilteringSolutionEnum;

    const selectedAdvancedFilter =
      ((yield select(
        selectors.advanced.getSelectedFilters
      )) as SelectedAdvancedFilter[]) || [];
    const { questionDetails, AttributeGUID } = filter || {};
    if (AttributeGUID) {
      if (questionDetails?.SubAttributes[0]?.RequestChoicesFromTheServer) {
        const SubAttributes = questionDetails?.SubAttributes[0];
        const attributeChoices = yield api.getAttributeChoices(
          AttributeGUID,
          SubAttributes?.SubAttributeLabel,
          filteringSolution
        );

        selectedAdvancedFilter[index] = mergeAdvancedFilter({
          filter,
          selectedAdvancedFilter,
          index,
          questionDetails: {
            ...questionDetails,
            attributeChoices,
          },
        });
        yield setDataState({
          SelectedFilters: selectedAdvancedFilter,
        });
      }
    }
  } catch (error) {
    console.log(error);
    yield setUiState({ error });
  } finally {
    yield setUiState({ loading: false });
  }
}

function* removeAdvancedFilter({
  index,
  callback,
}: RemoveAdvancedFilterActions): Generator<any> {
  try {
    const selectedAdvancedFilter = (yield select(
      selectors.advanced.getSelectedFilters
    )) as SelectedAdvancedFilter[];
    yield setDataState({
      SelectedFilters: selectedAdvancedFilter
        .filter((_, currentIndex) => currentIndex !== index)
        .map((filter, i) => ({ ...filter, index: i })),
    });
  } catch (e) {
    console.log(e);
  } finally {
    yield callback?.();
  }
}

function* updateAdvancedFilter({
  filter,
  index,
  callback,
}: UpdateAdvancedFilterActions): Generator<any> {
  try {
    const selectedAdvancedFilter =
      ((yield select(
        selectors.advanced.getSelectedFilters
      )) as SelectedAdvancedFilter[]) || [];

    const attributeChoices =
      selectedAdvancedFilter[index]?.questionDetails?.attributeChoices;

    // If the operation option is EQUAL it only allows one value selected
    const newFilterValues =
      filter?.OperatorOption?.value === '=' && filter?.Values?.length > 1
        ? {
            Values: [attributeChoices?.Choices?.[0]?.Value],
          }
        : {};

    selectedAdvancedFilter[index] = mergeAdvancedFilter({
      filter,
      selectedAdvancedFilter,
      index,
      ...newFilterValues,
    });

    yield setDataState({
      SelectedFilters: selectedAdvancedFilter,
    });
  } catch (error) {
    console.log(error);
    yield setUiState({ error });
  } finally {
    yield setUiState({ loading: false });
    yield callback?.();
  }
}

export default function* saga() {
  yield takeEvery(
    FECTH_ADVANCED_FILTERS_QUESTION_LIST,
    fetchAdvancedFiltersQuestionList
  );
  yield takeEvery(
    FECTH_ADVANCED_FILTERS_QUESTION_LIST_SUCCESS,
    fetchAdvancedFiltersQuestionListSuccess
  );
  yield takeEvery(
    FECTH_ADVANCED_FILTERS_QUESTION_LIST_ERROR,
    fetchAdvancedFiltersQuestionListError
  );
  yield takeEvery(ADD_ADVANCED_FILTER, addAdvancedFilter);
  yield takeEvery(REMOVE_ADVANCED_FILTER, removeAdvancedFilter);
  yield takeEvery(FECTH_ATTRIBUTE_CHOICES, getAttributeChoices);
  yield takeEvery(UPDATE_ADVANCED_FILTER, updateAdvancedFilter);
  yield takeEvery(FECTH_QUESTION_DETAILS, getQuestionDetailsSagas);
  yield takeLeading(INIT_ADVANCED_FILTER, initAdvancedFiltersSagas);
}
