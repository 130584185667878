import { DateOption } from './common';
import { UI } from './ui';

export enum FilterDateAttributeName {
  Survey = 'Survey Date',
  Transaction = 'Transaction Date',
}

export type CalendarState = {
  ui: UI<{
    isDropdownButtons?: boolean;
  }>;
  data: {
    DateOptions: DateOption[] | null;
    SelectedFilters?: DateOption;
    HasTransactionDate?: boolean;
    TransactionDateLabel?: string | null;
    SurveyDateLabel?: string | null;
    AttributeName?: string;
  };
};
