import { useState } from 'react';
import './SortBy.scss';
import { WidgetAction } from 'models/WidgetModel';
import { useWidgetContext } from 'contexts/WidgetProvider';

const SortBy = () => {
  const [sort, setSort] = useState('Descending');
  const { setState } = useWidgetContext();

  const sortHandler = (val: string) => {
    if (val !== sort) {
      setState({ action: WidgetAction.SORT });
      setSort(val);
    }
  };

  return (
    <div className="sortAction">
      Sort By:
      <span
        onClick={() => sortHandler(sort === 'Ascending' ? 'Descending' : 'Ascending')}
        data-testid="showHideIcon"
      >
        {sort}
      </span>
    </div>
  );
};

export default SortBy;
