import ColorPicker from 'core-ui/ColorPicker/ColorPicker';
import { ColorList } from 'helpers/ColorPickerList';
import React, { useEffect, useRef, useState } from 'react';
export interface ColorInputValues {
  name: string;
  color: string;
}

export interface ColorInputProps {
  inputTitle: string;
  index?: number;
  defaultColor: string;
  setSelectedColor: any;
}

const ColorInput: React.FC<ColorInputProps> = ({
  inputTitle,
  index,
  defaultColor,
  setSelectedColor,
}) => {
  const [textValue, setTextValue] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [tempColor, setTempColor] = useState<string>(defaultColor);
  const pickerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener('mousedown', outsideClickListener);
    return () => {
      document.removeEventListener('mousedown', outsideClickListener);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      setIsOpen(!isOpen);
    }
    setSelectedColor({ index, color: tempColor, textValue });
  }, [tempColor, textValue]);

  const toggleColorPicker = () => {
    setIsOpen(!isOpen);
  };

  const nameOnChange = (event: { target: { value: any } }) => {
    setTextValue(event.target.value);
  };

  const outsideClickListener = (event: any) => {
    if (
      pickerRef.current &&
      pickerRef.current !== event.target &&
      !pickerRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  return (
    <div className="diy-input-container">
      <div className="diy-material-colorfield">
        <input
          className="diy-input"
          type="text"
          name="color"
          maxLength={50}
          onChange={nameOnChange}
          onClick={toggleColorPicker}
          readOnly
        />
        <div className="diy-color-input" onClick={toggleColorPicker}>
          <span className="diy-selected-color" style={{ backgroundColor: tempColor }}></span>
        </div>
        <label className="diy-label">{inputTitle}</label>
      </div>
      {isOpen ? (
        <div ref={pickerRef}>
          <ColorPicker
            {...{
              colorList: ColorList,
              selectedColor: tempColor,
              setSelectedColor: setTempColor,
              toggleColorPicker,
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ColorInput;
