/**
 * Returns a fetch method with the setup needed to make request to the CXUI controllers
 * @param url Endpoint of the request
 * @param options Options of the fetch method
 */

const fetchLegacy = (
  url: string,
  options: Omit<RequestInit, 'body'> & { body: any }
) => {
  return fetch(url, {
    ...options,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    ...(options.body ? { body: JSON.stringify(options.body) } : {}),
  });
};

export default fetchLegacy;
