export const boldNumbersInString = (str: string, showTotals = false, isExport = false) => {
  const numericValues = str.match(/(\d+)/g);
  if (numericValues) {
    for (const val of numericValues) {
      let numericVal;
      if (showTotals && Number(val)) {
        numericVal = Number(val) > 0 ? Number(val) - 1 : val;
        showTotals = false;
      } else {
        numericVal = val;
      }
      str = isExport
        ? (str = str.replaceAll(val, `${numericVal}`))
        : str.replaceAll(val, `<b>${numericVal}</b>`);
    }
  }
  return str;
};
