import React, { useEffect, useState } from 'react';
import './TextAnalytics.scss';
import { BaseWidget } from 'models/WidgetModel';
import TACardWidget from 'components/TACardWidget/TACardWidget';

const TextAnalytics: React.FC<BaseWidget> = ({ ...props }) => {
  const { options } = props;
  const [childrenWidgets, setChildrenWidgets] = useState<any[]>([]);
  const [selectedWidget, setSelectedWidget] = useState<any>();

  useEffect(() => {
    if (options?.childrenWidgets && options?.childrenWidgets.length) {
      setChildrenWidgets(options.childrenWidgets);
      setSelectedWidget(options.childrenWidgets[0]);
    }
  }, [options]);

  return (
    <div className="text-analytics-widget">
      {childrenWidgets ? (
        <div className="main-widgets">
          {childrenWidgets.map((widget: any, i: number) => {
            const { dependencyOn } = widget;
            if (!dependencyOn) {
              return (
                <TACardWidget
                  key={i}
                  selectedWidget={selectedWidget}
                  setSelectedWidget={setSelectedWidget}
                  {...widget}
                />
              );
            }
          })}
        </div>
      ) : null}
      {selectedWidget ? (
        <div className="dependent-widgets">
          {childrenWidgets.map((widget: any, i: number) => {
            const { dependencyOn } = widget;
            if (dependencyOn && dependencyOn.includes(selectedWidget.payload.actionName)) {
              return <TACardWidget key={i} {...widget} />;
            }
          })}
        </div>
      ) : null}
    </div>
  );
};

export default TextAnalytics;
