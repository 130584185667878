import { takeEvery, select, getContext } from 'redux-saga/effects';
import {
  SELECT_HIERARCHY_FILTER,
  UPDATE_HIERARCHY_FILTER,
  UPDATE_FILTERS,
  UPDATE_FILTERS_SUCCESS,
  UPDATE_FILTERS_ERROR,
  INIT_HIERARCHY_FILTER,
  type SelectHierarchyFilterAction,
  type UpdateFiltersSuccessAction,
  type UpdateFiltersErrorAction,
  REMOVE_HIERARCHY_FILTER_VALUE,
  RemoveHierarchyFilterValueAction,
  UpdateHierarchyFiltersAction,
  UpdateFiltersAction,
  InitHierarchyFilterAction,
} from 'store/actions';
import { setState } from '@cx/zustand-saga';
import {
  FilterSlice,
  HierarchyFilter,
  SelectedFilters,
  SelectedFilter,
  HierarchyState,
  HierarchyFiltersActions,
  FilterState,
  ApiType,
  FilteringSolutionEnum,
} from 'types';
import { getSetStateMethods } from 'utils';
import selectors from 'store/selectors';

const { setDataState, setUiState } =
  getSetStateMethods<FilterSlice>('hierarchy');

function* initHierarchyFiltersSagas({
  callback,
}: InitHierarchyFilterAction): Generator<any> {
  const api = (yield getContext('api')) as ApiType;
  const { updatefilters }: HierarchyFiltersActions = yield select(
    (state) => state
  );
  const selectedFilters: any = yield select(
    selectors.hierarchy.getSelectedFilters
  );

  try {
    const filters = (yield select(
      selectors.hierarchy.getFilters
    )) as HierarchyFilter[];

    const ViewId = (yield select(selectors.getViewId)) as number;
    const WorkspaceId = (yield select(selectors.getWorkspaceId)) as string;

    if (!filters && ViewId && WorkspaceId) {
      const filteringSolution = (yield select(
        selectors.hierarchy.geFilteringSolution
      )) as FilteringSolutionEnum;
      yield setUiState({ loading: true });
      const filterBar = yield api.getFilterBar(
        WorkspaceId,
        ViewId,
        filteringSolution
      );
      const { Filters, CurrentGUID }: any = filterBar;
      const hierarchyData = {
        data: {
          Filters,
          SelectedFilters: selectedFilters,
          HierarchyGuid: CurrentGUID,
        },
      };
      yield setState((state: FilterSlice) => ({
        ...state,
        hierarchy: {
          ...(state?.hierarchy || {}),
          ...hierarchyData,
        },
      }));
      yield setUiState({ loading: false });
    }
    if (Object.keys(selectedFilters || {}).length > 0) {
      yield updatefilters();
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield callback?.();
  }
}

function* selectHierarchyFilterSagas({
  filter,
  values,
  callback,
}: SelectHierarchyFilterAction): Generator<any> {
  try {
    const { updatefilters }: HierarchyFiltersActions = yield select(
      (state) => state
    );
    const selectedFilters = (yield select<
      (state: FilterState) => SelectedFilters
    >(selectors.hierarchy.getSelectedFilters)) as SelectedFilters;

    let selectedFiltersFiltered = Object.entries(selectedFilters || {})
      .filter(([, { filter: currentFilter }]) => {
        return filter?.ColPosition >= currentFilter?.ColPosition;
      })
      .reduce(
        (prev, [, { filter: currentFilter, values: currentValues }]) => ({
          ...prev,
          [currentFilter.AttributeName]: {
            filter: currentFilter,
            values: currentValues,
          },
        }),
        {}
      ) as SelectedFilters;

    if (values?.length === 0) {
      delete selectedFiltersFiltered[filter.AttributeName];
    } else {
      selectedFiltersFiltered = {
        ...selectedFiltersFiltered,
        [filter.AttributeName]: {
          filter,
          values,
        },
      };
    }

    yield setDataState({
      SelectedFilters: selectedFiltersFiltered,
    });

    yield updatefilters();
  } catch (error) {
    console.log(error);
  } finally {
    yield callback?.();
  }
}

function* updateHierarchyFilter({
  filter,
  callback,
}: UpdateHierarchyFiltersAction): Generator<any> {
  const {
    updateHierarchyFiltersSuccess,
    updateHierarchyFiltersError,
  }: HierarchyFiltersActions = yield select((state) => state);
  const api = (yield getContext('api')) as ApiType;
  const shouldSetLoading =
    filter.ColPosition < 3 && filter.Choices.length !== 0;
  try {
    const filters = (yield select(
      selectors.hierarchy.getFilters
    )) as HierarchyFilter[];
    const selectedFilters = yield select(
      selectors.hierarchy.getSelectedFilters
    );
    const ViewId = yield select(selectors.getViewId);
    const WorkspaceId: string = (yield select<
      (state: HierarchyState) => string
    >(selectors.getWorkspaceId)) as string;
    const filteringSolution = (yield select(
      selectors.hierarchy.geFilteringSolution
    )) as FilteringSolutionEnum;

    const bodyRequest = {
      WorkspaceId,
      FilteringSolution: filteringSolution,
      Parameters: {
        Filters: Object.entries(selectedFilters || {})
          ?.filter(([AttributeName]) => AttributeName !== filter?.AttributeName)
          ?.map(([AttributeName, currentFilter]) => ({
            AttributeName,
            Values: currentFilter.values,
            WildcardExpression: null,
          })),
        Levels: [filter.AttributeName],
        ViewId: Number(ViewId),
      },
    };
    if (shouldSetLoading)
      yield setUiState({
        loading: true,
      });

    const res: any = yield api.updateFilters(bodyRequest);

    if (res?.Filters) {
      const filterToUpdate: HierarchyFilter | undefined = res?.Filters[0];
      if (filterToUpdate) {
        const newFilters = filters?.map((currentFilter: HierarchyFilter) =>
          currentFilter.AttributeName === filterToUpdate.AttributeName
            ? { ...currentFilter, Choices: filterToUpdate.Choices }
            : currentFilter
        );

        yield setDataState({
          Filters: newFilters,
        });
      }
    }

    yield updateHierarchyFiltersSuccess();
  } catch (error) {
    console.log(error);
    yield updateHierarchyFiltersError();
  } finally {
    yield setUiState({ loading: false });
    yield callback?.();
  }
}

/**
 * Remove one value from a hierarchy filter
 * @param param0
 */
function* removeHierarchyFilterValue({
  filter,
  value,
  callback,
}: RemoveHierarchyFilterValueAction): Generator<any> {
  try {
    const { selectHierarchyFilter }: HierarchyFiltersActions = yield select(
      (state) => state
    );

    const selectedFilters = (yield select(
      selectors.hierarchy.getSelectedFilters
    )) as SelectedFilters;

    const currentValues =
      selectedFilters[filter.AttributeName as keyof SelectedFilters];
    if (currentValues) {
      const newValues = currentValues.values.filter(
        (currentValue) => currentValue !== value
      );

      yield selectHierarchyFilter(currentValues.filter, newValues, callback);
    }
  } catch (error) {
    console.log(error);
  }
}

/**
 * @description Generator function triggered when the UPDATE_FILTERS action is dispatched to the store.
 * This function send a request to the API to update the filters, triggers the UPDATE_FILTERS_SUCCESS if there is no error in the request or UPDATE_FILTERS_ERROR if there is an error.
 */
function* updateFiltersBarSagas({
  callback,
}: UpdateFiltersAction): Generator<any> {
  const api = (yield getContext('api')) as ApiType;
  const { updatefiltersSuccess, updatefiltersError }: HierarchyFiltersActions =
    yield select((state: HierarchyFiltersActions) => state);

  try {
    const filters = yield select(selectors.hierarchy.getFilters);
    const selectedFilters: any = yield select(
      selectors.hierarchy.getSelectedFilters
    );
    const ViewId = yield select(selectors.getViewId);
    const WorkspaceId = yield select<(state: FilterSlice) => string>(
      selectors.getWorkspaceId
    );
    const Levels = (filters as HierarchyFilter[])
      ?.map((item) => item.AttributeName)
      .filter((AttributeName) => !selectedFilters[AttributeName]);

    const filteringSolution = (yield select(
      selectors.hierarchy.geFilteringSolution
    )) as FilteringSolutionEnum;

    const bodyRequest = {
      WorkspaceId: String(WorkspaceId),
      FilteringSolution: filteringSolution,
      Parameters: {
        Filters: Object.entries(selectedFilters || {}).map(
          ([AttributeName, filter]: [string, SelectedFilter]) => ({
            AttributeName,
            Values: filter.values,
            WildcardExpression: null,
          })
        ),
        Levels,
        ViewId: Number(ViewId),
      },
    };

    const res: any = yield api.updateFilters(bodyRequest);

    if (res?.Error) return yield updatefiltersError(res.Error);
    return yield updatefiltersSuccess(res);
  } catch (error) {
    yield updatefiltersError(error);
    console.log(error);
    return null;
  } finally {
    yield callback?.();
  }
}

/**
 * @remarks
 * Generator function triggered when the UPDATE_FILTERS_SUCCESS action is dispatched to the store.
 * This function gets the data received from the updateFilters request  and update the filters in the state.
 * @param {Object} pram0 Data object
 */

function* updateFiltersBarSuccessSagas({
  data,
  callback,
}: UpdateFiltersSuccessAction) {
  try {
    const filters: HierarchyFilter[] = yield select(
      selectors.hierarchy.getFilters
    );

    const filtersToUpdate = filters?.map((item: any) => {
      const filter = data?.Filters?.find(
        ({ AttributeName }: { AttributeName: string }) =>
          AttributeName === item.AttributeName
      );
      if (filter)
        return {
          ...filter,
          ColPosition: item.ColPosition,
          Label: item.Label,
          SubAttributeName: item.SubAttributeName,
        };
      return item;
    });

    yield setDataState({
      Filters: filtersToUpdate,
    });
  } catch (error) {
    console.log({ error });
  } finally {
    yield callback?.();
  }
}

/**
 * @description Generator function triggered when the UPDATE_FILTERS_ERROR action is dispatched to the store.
 * This function set a error in the hierarchy state
 * @param {Object} pram0 Should have an `error` property to be set in the hierarchy state.
 */

function* updateFiltersBarErrorSagas({
  error,
  callback,
}: UpdateFiltersErrorAction) {
  yield setUiState({ error });
  yield callback?.();
}

export default function* saga() {
  yield takeEvery(INIT_HIERARCHY_FILTER, initHierarchyFiltersSagas);
  yield takeEvery(SELECT_HIERARCHY_FILTER, selectHierarchyFilterSagas);
  yield takeEvery(UPDATE_HIERARCHY_FILTER, updateHierarchyFilter);
  yield takeEvery(UPDATE_FILTERS, updateFiltersBarSagas);
  yield takeEvery(UPDATE_FILTERS_SUCCESS, updateFiltersBarSuccessSagas);
  yield takeEvery(UPDATE_FILTERS_ERROR, updateFiltersBarErrorSagas);
  yield takeEvery(REMOVE_HIERARCHY_FILTER_VALUE, removeHierarchyFilterValue);
}
