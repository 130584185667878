import { useState } from 'react';
import { appAssets } from 'constants/assets';
import { useWidgetContext } from 'contexts/WidgetProvider';
import { WidgetAction } from 'models/WidgetModel';
import './ExpandDataGrid.scss';

const Expand = appAssets.icons.UP_ARROW;

const ExpandDataGrid = () => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { setState } = useWidgetContext();

  const toggleExpanded = () => {
    setState({ action: WidgetAction.EXPANDABLE });
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={`pck-expand`}>
      <div className={`pck-expand-button`} onClick={toggleExpanded}>
        <Expand className={`pck-expand-icon ${isExpanded && 'pck-expanded'}`} />
      </div>
    </div>
  );
};

export default ExpandDataGrid;
