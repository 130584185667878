import React from 'react';
import { nonHeaderActionComponents } from 'components/actionsIndex';

export interface HeaderActions {
  actions: string[];
  isCustom?: boolean;
  response?: any;
}

const CardFooterActions: React.FC<HeaderActions> = ({ actions, response, isCustom }) => {
  return (
    <>
      {actions?.length && response?.data?.data?.length > 5 ? (
        <>
          {actions?.map((item: any, i: number) => {
            if (!isCustom && nonHeaderActionComponents[item]) {
              const ActionComponent = nonHeaderActionComponents[item];
              return <ActionComponent key={i} response={response} />;
            }
          })}
        </>
      ) : null}
    </>
  );
};

export default CardFooterActions;
