export const highlightStyles = {
  ':nth-child(1)': {
    backgroundColor: '#fdedb9',
    fontWeight: 'bold',
  },
};
export const cellLink = {
  ':nth-child(1)': {
    fontWeight: 'bold',
    color: '#33929e',
    cursor: 'pointer',
  },
};

export const customStyles = {
  headRow: {
    style: {
      fontFamily: 'Roboto',
      fontWeight: 'bold',
      backgroundColor: '#cecece',
      minHeight: '35px',
      maxHeight: '35px',
      paddingLeft: '0',
      paddingRight: '0',
    },
  },
  headCells: {
    style: {
      paddingLeft: '10px',
      paddingRight: '10px',
      color: '#333e48',
      fontSize: '13px',
      fontWeight: 'bold',
      '>div': {
        flexDirection: 'row-reverse',
        justifyContent: 'flex-end',
      },
    },
  },
  rows: {
    style: {
      minHeight: '35px',
      maxHeight: '35px',
      borderStyle: 'none',
      fontFamily: 'Roboto',
      borderBottomStyle: 'none',
      ':not(:last-of-type)': {
        borderBottomStyle: 'none',
      },
      ':hover': {
        backgroundColor: '#d8f5ed',
      },
    },
  },
  striped: {
    style: {
      backgroundColor: '#f1f1f1',
    },
  },
  cells: {
    style: {
      borderStyle: 'none',
      borderBottomStyle: 'none',
      fontFamily: 'Roboto',
      paddingLeft: '10px',
      paddingRight: '10px',
      ':not(:last-of-type)': {
        borderBottomStyle: 'none',
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: '#d9d9d9',
      },
      ':nth-child(1)': {
        fontWeight: 'bold',
      },
    },
  },
};
