export const axisTick = {
  axisTick: {
    show: false,
  },
};

export const axisLine = {
  axisLine: {
    show: true,
    lineStyle: {
      color: '#d6d6d6',
    },
  },
};

export const yAxis = {
  splitLine: {
    show: false,
  },
  ...axisLine,
};

export const npsYAxis = {
  max: 100,
  min: -100,
  splitNumber: 2,
  splitLine: {
    show: true,
  },
  axisLine: {
    show: false,
  },
};

export const axisLabel = {
  axisLabel: {
    color: '#8a8a8a',
    fontSize: 11,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    overflow: 'none',
    ellipsis: '...',
    width: 100,
  },
};

export const yAxisLabel = (maxValue: any) => {
  let fontSize = '11';
  let overflow = 'none';
  if (maxValue >= 1000 && maxValue < 10000) {
    fontSize = '0.7em';
  } else if (maxValue >= 10000 && maxValue < 100000) {
    fontSize = '0.6em';
  } else if (maxValue >= 100000 && maxValue < 1000000) {
    fontSize = '0.5em';
  } else if (maxValue >= 1000000) {
    fontSize = '0.4em';
    overflow = 'truncate';
  }
  return {
    axisLabel: {
      color: '#8a8a8a',
      fontSize: fontSize,
      fontWeight: 'bold',
      fontFamily: 'Roboto',
      overflow: overflow,
      ellipsis: '...',
      width: 25,
    },
  };
};

export const splitLine = {
  splitLine: {
    show: false,
  },
};

export const richLabelTitle = {
  fontSize: '24',
  lineHeight: '18',
  fontWeight: 'bold',
};

export const tooltip = {
  position: 'top',
  backgroundColor: '#333E49',
  borderWidth: 0,
  confine: true,
  textStyle: {
    color: '#fff',
    fontweight: 'lighter',
  },
  extraCssText: 'box-shadow: none; border-radius: 2px;',
};

export const trendsIcon = {
  height: 25,
  width: 25,
  align: 'center',
};

export const stackedBarOption = {
  type: 'bar',
  seriesLayoutBy: 'row',
  barGap: '10%',
  itemStyle: {
    borderRadius: 4,
  },
};

export const stackedLineOption = {
  type: 'line',
  symbol: 'emptyCircle',
  seriesLayoutBy: 'row',
  itemStyle: {
    normal: {
      borderWidth: 4,
      borderType: 'solid',
    },
  },
  lineStyle: {
    width: 4,
  },
  symbolSize: 12,
};

export const horizontalStackedSeriesOption = (value: any) => {
  return {
    type: 'bar',
    stack: 'total',
    itemStyle: {
      borderColor: value >= 1 ? 'transparent' : null,
      borderRadius: 6,
      borderWidth: 2.5,
    },
  };
};

export const legendTextStyle = {
  color: '#8a8a8a',
  fontSize: 11,
};

export const legendCommon = {
  type: 'scroll',
  bottom: 0,
  width: '90%',
  itemHeight: 12,
  itemWidth: 12,
  itemGap: 20,
};

export const labelCommon = {
  position: 'top',
  distance: 5,
  color: '#37474f',
  fontWeight: 'bold',
  fontFamily: 'Roboto',
};

export const decimalPlaces = [
  { value: 0, label: '0' },
  { value: 1, label: '1' },
  { value: 2, label: '2' },
];
