import { DIYChartOption } from 'constants/chart-selector';
import React, { useEffect, useState } from 'react';
import Select, { SingleValue } from 'react-select';

export interface SelectChartInputProps {
  loading: boolean;
  options: DIYChartOption[] | undefined;
  selectChartType: any;
  defaultChartType: DIYChartOption;
}

const SelectChartInput: React.FC<SelectChartInputProps> = ({
  loading,
  options,
  selectChartType,
  defaultChartType,
}) => {
  const [chartOptions, setChartOptions] = useState<DIYChartOption[]>(options || []);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState<DIYChartOption | undefined>(undefined);

  useEffect(() => {
    if (defaultChartType.isChild) {
      const newOptions = [...chartOptions];
      const parent = newOptions.find(
        (chartOption) => chartOption.chartType === defaultChartType.parent
      );
      if (parent) {
        parent.isOpen = true;
      }
    }
  }, []);

  const clickHandler = (newValue: SingleValue<DIYChartOption>) => {
    if (newValue?.children && newValue?.children.length) {
      const newOptions = [...chartOptions];
      const newOption = newOptions.find((option) => option === newValue);
      if (newOption) {
        newOption.isOpen = !newOption?.isOpen;
        setChartOptions(newOptions);

        const child = newOptions.find((option) => option.label === newValue.children?.[0].label);
        if (child) {
          selectChartType(child);
          setSelected(child.chartType);
          setIsOpen(true);
          setSelectedOption(child);
        }
      }
    } else {
      if (newValue?.chartType) {
        const newOptions = [...chartOptions];
        const parent = newOptions.find((chartOption) => chartOption.chartType === newValue.parent);

        if (selected !== '' && selected !== parent?.chartType) {
          newOptions.forEach((option) => {
            option.isOpen = false;
          });
        }

        setIsOpen(false);
        selectChartType(newValue);
        setSelected(newValue.chartType);
        setSelectedOption(newValue);
      }
    }
  };

  const checkParentIsOpened = (option: DIYChartOption) => {
    if (option.parent) {
      const parent = chartOptions.find((chartOption) => chartOption.chartType === option.parent);
      if (parent) return parent.isOpen;
    }
    return false;
  };

  const customStyles = {
    option: (styles: any, state: any) => {
      const newStyles = {
        ...styles,
        '.diy-select-description': {
          color: state.isSelected ? 'white' : '#999999',
        },
      };
      return { ...newStyles };
    },
  };

  return (
    <div className="diy-input-container">
      <div className="diy-material-textfield">
        {chartOptions?.length ? (
          <Select
            isLoading={loading}
            className="diy-select"
            options={chartOptions}
            defaultValue={defaultChartType || chartOptions?.[0]}
            formatOptionLabel={(option: DIYChartOption) => (
              <>
                <div
                  className={`diy-option ${
                    option.isChild && !checkParentIsOpened(option) ? 'pck-closed' : ''
                  }`}
                >
                  {/* Showing icon */}
                  {option.icon && (!isOpen || !option.isChild) ? <option.icon /> : <></>}
                  {/* Showing label */}
                  <div
                    className={`diy-select-label flex-fill ${
                      option.isChild && isOpen ? 'is-child' : ''
                    }`}
                  >
                    <div>{option.label}</div>

                    {/* Showing description only if the property isChild is true */}
                    {option.description && isOpen ? (
                      <div className="diy-select-description">{option.description}</div>
                    ) : null}
                  </div>
                  {option.children && option.children.length ? (
                    <svg height="20" width="20" viewBox="0 0 20 20" className="arrow-svg">
                      <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                    </svg>
                  ) : null}
                </div>
              </>
            )}
            onChange={clickHandler}
            openMenuOnClick={true}
            closeMenuOnSelect={false}
            menuIsOpen={isOpen}
            onMenuClose={() => setIsOpen(false)}
            onMenuOpen={() => setIsOpen(true)}
            styles={customStyles}
            value={selectedOption}
          />
        ) : null}

        <label className="diy-label select">Chart Type</label>
      </div>
    </div>
  );
};

export default SelectChartInput;
