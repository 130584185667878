import { CustomConfig } from 'models/WidgetModel';
import { RulesName } from 'constants/rulesName';

export const cleanObject = (obj: any) => {
  const keys = Object.keys(obj);
  for (const key in keys) {
    if (obj[key] === undefined) {
      delete obj[key];
    }
  }
  return obj;
};

// Sum all values of specific property of an object
export const sumPropertyValues = (objArr: any, prop: string) => {
  return objArr.reduce((accumulator: number, object: any) => {
    const amount = isNaN(Number(object[prop])) ? 0 : Number(object[prop]);
    return accumulator + amount;
  }, 0);
};

export const strip = (html: string) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
};

export const displayColorsByFamily = (colorFamily: string[]): string[] => {
  const colorFamilyIndexes = [0, 1, 2, 3];
  return colorFamily.filter((v: string, i: number) => colorFamilyIndexes.includes(i));
};

// Method to convert hierarchy filter coming from cx 2.0 component to the one CX needs
export const convertJsonToHierarchyFilter = (jsonData: any) => {
  const filters = [];
  for (const key in jsonData) {
    if (Object.hasOwnProperty.call(jsonData, key)) {
      const filterData = jsonData[key];
      const attributeName = key;
      const values = filterData.values;
      const label = filterData?.filter?.Label;
      const colPosition = filterData?.filter?.ColPosition;

      // Create an object with explicit keys
      const filterObject = {
        DisplayName: label,
        ColPosition: colPosition,
        AttributeName: attributeName,
        Values: values,
      };

      // Push the object into the hierarchyFilter array
      filters.push(filterObject);
    }
  }

  return filters;
};

// Method to convert advanced filter coming from cx 2.0 component to the one CX needs
export const convertJsonToAdvancedFilter = (jsonData: any) => {
  const filters = [];
  for (const key in jsonData) {
    if (Object.hasOwnProperty.call(jsonData, key)) {
      const filterData = jsonData[key];
      if (filterData.Name !== undefined) {
        const values = filterData.Values;
        const attributeGUID = filterData.AttributeGUID;
        const label = filterData?.Label;
        const attributeNamelabel = filterData?.Name;
        const operatorType = filterData?.OperatorOption?.value;
        const subAttribute = filterData?.questionDetails?.SubAttributes[0]?.SubAttributeName;
        const operatorOption = filterData?.OperatorOption;

        // Create an object with explicit keys
        const filterObject = {
          AttributeGuid: attributeGUID,
          DisplayName: label,
          AttributeName: attributeNamelabel,
          OpType: operatorType,
          Values: values,
          SubAttribute: subAttribute,
          OperatorOption: operatorOption,
        };

        // Push the object into the advancedFilter array
        filters.push(filterObject);
      }
    }
  }

  return filters;
};

export function findLongestStringLength(arr: string[]): number {
  let maxLength = 0;
  arr.forEach((str: string) => {
    if (str.length > maxLength) {
      maxLength = str.length;
    }
  });
  return maxLength;
}

///Returns Question label if subTitle is not retrieved for "One Question" datagrids
export function getOneQuestionSubTitle(customWidgetConfig: CustomConfig) {
  if (customWidgetConfig?.Questions?.length === 1 && customWidgetConfig?.Rules?.length === 1) {
    if (customWidgetConfig?.Rules[0]?.RuleName === 'OneQuestion')
      return customWidgetConfig?.Questions[0]?.ShortText;
    // add calculation when is OneQuestionOneCalculation
    if (customWidgetConfig?.Rules[0]?.RuleName === RulesName.OneQuestionOneCalculation)
      return customWidgetConfig?.Calculations[0]?.DisplayName;
  }
  return null;
}

export function getLabelWithCustomDecimalPlaces(
  value: number,
  isPercentage: boolean,
  decimalPlaces?: number
) {
  let lblValue =
    typeof decimalPlaces !== 'undefined'
      ? formatterWithDecimalPlaces(value, decimalPlaces)
      : `${value}`;
  lblValue += isPercentage ? ' %' : '';
  return lblValue;
}

function formatterWithDecimalPlaces(value: number, decimalPlaces = 2) {
  const numericValue =
    typeof value === 'string'
      ? Number(value)
      : value; /*Somehow the received value is coming as string and breaking the method*/
  const roundedValue = decimalPlaces === 0 ? Math.round(numericValue) : numericValue;
  return roundedValue.toFixed(decimalPlaces);
}

export const getFilteringObject = (inputData: any) => {
  if (!inputData || inputData.length === 0) return undefined;
  return inputData.reduce((acc: any, item: any) => {
    acc[item.AttributeName] = {
      filter: {
        Label: item.DisplayName,
        AttributeName: item.AttributeName,
        ColPosition: item.ColPosition,
      },
      values: item.Values,
    };
    return acc;
  }, {});
};

export const transformToAdvancedFilterAttributes = (inputArray: any) => {
  if (inputArray == null || inputArray.length === 0) {
    return [];
  }

  return inputArray?.map((item: any) => {
    if (
      item.AttributeGuid == null ||
      item.DisplayName == null ||
      item.AttributeName == null ||
      item.Values == null
    ) {
      return {};
    }
    return {
      AttributeGUID: item.AttributeGuid,
      Label: item.DisplayName,
      Name: item.AttributeName,
      Values: item.Values,
      OpType: item?.OpType,
      SubAttribute: item?.SubAttribute,
      OperatorOption: item?.OperatorOption,
    };
  });
};

export const getHierarchyFiltersLabelData = (hierarchyFilters: any) => {
  if (!hierarchyFilters || hierarchyFilters.length === 0) {
    return [];
  }
  return hierarchyFilters.map((item: any) => `${item.DisplayName}: ${item.Values.join(', ')}`);
};

export const getAdvancedFiltersLabelData = (advancedFilters: any) => {
  if (!advancedFilters || advancedFilters.length === 0) {
    return [];
  }
  return advancedFilters.map(
    (item: any) =>
      `${item.DisplayName}: ${
        item.Values
          ? translateSymbol(item.OperatorOption?.label || '') + ' ' + item.Values.join(', ')
          : ''
      }`
  );
};

// Translate symbol coming from cx 2.0 to text if it is not one of these it will show label
const translateSymbol = (operatorOptionLabel: any) => {
  switch (operatorOptionLabel) {
    case '>':
      return 'Greater Than';
    case '>=':
      return 'Greater Than or Equal To';
    case '<':
      return 'Less Than';
    case '<=':
      return 'Less Than or Equal To';
    case '=':
      return 'Equal To';
    default:
      return operatorOptionLabel;
  }
};
