import React from 'react';
import './multipleSelect.scss';
import Select, { components } from 'react-select';
import chipotleStyles from 'core-ui/styles/chipotletSelect';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { appAssets } from 'constants/assets';

export interface MultipleSelectProps {
  loading: boolean;
  options: any;
  selectedChange: any;
  selectedValues?: Array<SelectOptions>;
  label: string;
  onBlurEvent: any;
  helperText: string;
}

export interface SelectOptions {
  value: string;
  label: string;
  longText: string;
  raw: any;
}

const MultipleSelect: React.FC<MultipleSelectProps> = ({
  loading,
  options,
  selectedChange,
  label,
  selectedValues,
  onBlurEvent,
  helperText,
}) => {
  const SearchIcon = appAssets.icons.SEARCH;
  const getDefaultQuestions = () => {
    return [];
  };
  const placeholderComponent = (
    <div className="placeholder-container">
      <SearchIcon />
      <span className="placeholder-text">Search</span>
    </div>
  );
  const MenuList = (props: any) => (
    <components.MenuList {...props}>
      <div className="helper-text">{helperText}</div>
      {props.children}
    </components.MenuList>
  );
  return (
    <div className="diy-input-container">
      <div className="diy-material-textfield">
        <Select
          isLoading={loading}
          className="diy-select"
          options={options}
          isMulti
          isClearable={false}
          defaultValue={getDefaultQuestions()}
          value={selectedValues}
          placeholder={placeholderComponent}
          onBlur={onBlurEvent}
          formatOptionLabel={(option: SelectOptions) => (
            <Tippy
              className="tooltip-container"
              content={
                <div>
                  <div className="tooltip-title">{option.label}</div>
                  <div className="tooltip-text">{option.longText}</div>
                </div>
              }
            >
              <div className="diy-option">
                <span className="diy-select-label">{option.label}</span>
              </div>
            </Tippy>
          )}
          onChange={selectedChange}
          styles={chipotleStyles}
          components={{ MenuList }}
        />
        <label className="diy-label select">{label}</label>
      </div>
    </div>
  );
};

export default MultipleSelect;
