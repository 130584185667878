export const customStyles = {
  headRow: {
    style: {
      fontFamily: 'Roboto',
      fontWeight: 'bold',
      minHeight: '35px',
      maxHeight: '35px',
      paddingLeft: '0',
      paddingRight: '0',
    },
  },
  headCells: {
    style: {
      paddingLeft: '10px',
      paddingRight: '10px',
      color: '#333e48',
      fontSize: '13px',
      '>div': {
        flexDirection: 'row-reverse',
        justifyContent: 'flex-end',
      },
    },
  },
  rows: {
    style: {
      minHeight: '55px',
      maxHeight: '65px',
      borderStyle: 'none',
      fontFamily: 'Roboto',
      borderBottomStyle: 'none',
      cursor: 'pointer',
      ':not(:last-of-type)': {
        borderBottomStyle: 'none',
      },
      ':hover': {
        backgroundColor: '#d8f5ed',
      },
    },
  },

  cells: {
    style: {
      borderStyle: 'none',
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderBottomColor: '#d9d9d9',
      fontFamily: 'Roboto',
    },
  },
};
