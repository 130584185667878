import React from 'react';

export interface CalendarDateRangeLabelProps {
  startDate: Date;
  endDate: Date;
}

const CalendarDateRangeLabel: React.FC<CalendarDateRangeLabelProps> = ({ startDate, endDate }) => {
  // Format the navigation label based on the selected date range
  const startDateLabel = startDate.toLocaleDateString('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  const endDateLabel = endDate.toLocaleDateString('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  return (
    <div>
      <span className="dateRangeContainer">Start: {startDateLabel}</span>-
      <span className="dateRangeContainer">End: {endDateLabel}</span>
    </div>
  );
};

export default CalendarDateRangeLabel;
