import React, { FC, useEffect, useRef } from 'react';
import ReactECharts, { EChartsOption } from 'echarts-for-react';
import './PieChart.scss';
import { tooltip } from 'constants/chartOption';
import { BaseWidget } from 'models/WidgetModel';
import FootersChart from '../../FootersChart/FootersChart';

interface PieChartProps extends BaseWidget {
  colors: string[];
  options: any;
}

const PieChart: FC<PieChartProps> = ({ response, colors, loading, error, options, ...props }) => {
  const [series, setSeries] = React.useState<any[]>([]);
  const [isPercentage, setIsPercentage] = React.useState<boolean>(false);
  const [showLabels, setShowLabels] = React.useState<boolean>(true);
  const [optionsChart, setOptionsChart] = React.useState<any>();
  const chart = useRef<any>(null);
  const optionsRef = useRef<any>();
  const divRef = useRef<any>();

  optionsRef.current = optionsChart;

  const { width, height } = props;

  useEffect(() => {
    if (optionsChart) {
      optionsRef.current = optionsChart;
    }
  }, [optionsChart]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      adjustLabel();
    });
    const divElement = divRef.current;
    resizeObserver.observe(divElement);
    return () => {
      resizeObserver.unobserve(divElement);
    };
  }, [divRef.current]);

  useEffect(() => {
    if (response) {
      setSeries(removeNoValueSeries(response.data.series));
      setIsPercentage(response.data.isPercentage);
      setShowLabels(response.data.showLabels);
    }
  }, [response]);

  useEffect(() => {
    if (options && 'showLabels' in options) {
      setShowLabels(options?.showLabels);
    }
  }, [options?.showLabels]);

  const getLabel = (params: any) => {
    const label = params?.name ? params.name : '';
    const value = params?.value ? `${params.value}${isPercentage ? '%' : ''}` : '';

    return `${label}\n${value}`;
  };

  const removeNoValueSeries = (series: any[]) => {
    return series?.map((s: any) => {
      return s.value !== 0 ? s : null;
    });
  };

  const initOptions = () => {
    return {
      firstRender: true,
      tooltip: {
        ...tooltip,
        formatter: `<strong>{b}:</strong> {c}${isPercentage ? '%' : ''}`,
      },
      grid: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
      color: colors,
      series: [
        {
          type: 'pie',
          radius: ['40%', '60%'],
          avoidLabelOverlap: false,
          label: {
            show: showLabels,
            color: '#37474F',
            fontSize: 14,
            fontWeight: 'bold',
            fontFamily: 'Roboto',
            overflow: 'none',
            ellipsis: '...',
            distanceToLabelLine: 30,
            width: 150,
            formatter: (params: any) => getLabel(params),
          },
          labelLayout: {
            align: 'center',
          },
          labelLine: {
            show: true,
            minTurnAngle: 180,
            lineStyle: {
              color: '#37474f',
            },
          },
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 2,
          },
          data: series,
        },
      ],
    };
  };

  useEffect(() => {
    const optionsObj: EChartsOption = initOptions();
    if (Object.keys(optionsObj).length) {
      setOptionsChart(optionsObj);
    }
  }, [series, showLabels, isPercentage, colors]);

  const adjustLabel = () => {
    if (chart.current) {
      if (optionsChart && !Object.keys(optionsChart).length) return;
      const { width: chartWidth } = chart.current
        .getEchartsInstance()
        .getDom()
        .getBoundingClientRect();
      const overflow = chartWidth >= 1000 ? 'none' : 'truncate';
      const savedOptions = optionsRef.current;
      if (savedOptions?.series?.[0]?.data?.length) {
        savedOptions.series = savedOptions.series.map((serie: any) => {
          serie.label = {
            ...serie.label,
            overflow,
          };
          return serie;
        });
        const newOptions = {
          ...savedOptions,
          firstRender: false,
        };
        setOptionsChart(() => newOptions);
      }
    }
  };

  const eventsMap = {
    finished: function () {
      if (optionsChart.firstRender) adjustLabel();
    },
  };

  return (
    <>
      <div className="responsive-chart" data-testid="PieChart" ref={divRef}>
        {optionsChart ? (
          <ReactECharts
            ref={chart}
            opts={{ renderer: 'svg', width, height }}
            option={optionsChart}
            onEvents={eventsMap}
          ></ReactECharts>
        ) : (
          <></>
        )}
      </div>
      {!loading && !error ? <FootersChart baseSize={response?.data?.baseSize} /> : null}
    </>
  );
};

export default PieChart;
