import { EChartsOption } from 'echarts-for-react';

export const hideSeries = (params: EChartsOption, data: any, handleData: any) => {
  const selected = params.name;
  const condition =
    selected === 'Positive Change'
      ? (netChange: any) => netChange >= 0
      : (netChange: any) => netChange < 0;

  const header = data[0];
  const newRawData = data.filter((item: any) => {
    const netChange = item[3];
    return condition(netChange);
  });

  handleData([header, ...newRawData]);
};

export const showAllSeries = (data: any, handleData: any) => {
  handleData(data);
};
