import React from 'react';

export interface TextInputProps {
  customWidgetTitle: string;
  setWidgetTitle: any;
  required: boolean;
  setRequired: any;
  maxLength: number;
}

const TextInput: React.FC<TextInputProps> = ({
  customWidgetTitle = 'Widget Name',
  setWidgetTitle,
  required,
  setRequired,
  maxLength,
}) => {
  const isValid = (value: string) => {
    setRequired(false);
    setWidgetTitle(value);
    if (!value.length) setRequired(true);
  };

  return (
    <div className="diy-input-container">
      <div className="diy-material-textfield">
        <input
          className="diy-input"
          type="text"
          name="widgetName"
          onChange={(event) => isValid(event.target.value)}
          maxLength={maxLength}
          required={required}
          defaultValue={customWidgetTitle}
        />
        <label className="diy-label">Widget Title</label>
      </div>
      {required ? <span className="diy-required-field">This field is required</span> : null}
    </div>
  );
};

export default TextInput;
