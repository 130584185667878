import './DynamicTable.scss';

import { Icon } from '@cx/ui';

import { library } from 'components';
import HorizontalBarChart from 'components/Charts/HorizontalBarChart/HorizontalBar';
import SingleHorizontalBarChart from 'components/Charts/SingleHorizontalBarChart/SingleHorizontalBarChart';
import SummaryStats from 'components/SummaryStats/SummaryStats';
import { useWidgetContext } from 'contexts/WidgetProvider';
import { boldNumbersInString } from 'core-ui/BoldNumberInString/boldNumbersInString';
import SortIcon from 'core-ui/Sort/Sort';
import { sort } from 'fast-sort';
import { setNumericData } from 'helpers/setNumericData';
import { useAxios } from 'hooks/useAxios';
import { BaseWidget, WidgetAction } from 'models/WidgetModel';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import DataTable, { createTheme, SortOrder, TableColumn } from 'react-data-table-component';

import {
  exportToExcel,
  convertToCSV,
  removeOmittedColumns,
  convertFieldObjectToValue,
  exportToHtml,
} from 'helpers/exporFileUtilities';
import Tippy from '@tippyjs/react';
import { getDataFromSingleEndpoint } from 'services/Torogoz/TogorozApi';
import { useStore as useGlobalStore } from 'store/useGlobalStore';
import Tooltip from './DynamicTableTooltip';

import { cellLink, customStyles, highlightStyles } from './DynamicTable.style';
import { getOneQuestionSubTitle } from 'helpers/utilities';
import { extractValue } from 'helpers/sorting';
import { saveAs } from 'file-saver';

createTheme('solarized', {
  divider: {
    default: '#d9d9d9',
  },
  striped: {
    default: '#f1f1f1',
    text: '#333333',
  },
});

const getValueFromRow = (row: any, field: string) => {
  const fieldV = row[field];
  // if fieldV is object, return the value
  if (fieldV && typeof fieldV === 'object' && 'value' in fieldV) {
    return fieldV.value;
  }

  return fieldV;
};

const getSignificanceValueFromRow = (row: any, field: string) => {
  const fieldV = row[field];
  if (fieldV && typeof fieldV === 'object' && 'significanceIndicator' in fieldV) {
    const trendup = (
      <div className="pck-child-div significanceIndicator">
        <Icon name="metricArrowUp" className="biggest-green-arrow "></Icon>
      </div>
    );
    const trenddown = (
      <div className="pck-child-div significanceIndicator">
        <Icon name="metricArrowDown" className="biggest-red-arrow"></Icon>
      </div>
    );
    const trendflat = (
      <div className="pck-child-div significanceIndicator">
        <Icon name="metricArrowRight" className="biggest-yellow-arrow"></Icon>
      </div>
    );
    if (fieldV.significanceIndicator && fieldV.valueDifference > 0) return trendup;
    else if (fieldV.significanceIndicator && fieldV.valueDifference < 0) return trenddown;
    else if (fieldV.significanceIndicator && fieldV.valueDifference == 0) return trendflat;
  }
  return <></>;
};

type DynamicTableProps = BaseWidget;

const DynamicTable: React.FC<DynamicTableProps> = ({
  response: defaultResponse,
  payload,
  options,
  customWidgetConfig,
  ...props
}) => {
  const [table, setTable] = useState<{
    rawData: { [key: string]: any };
    columns: TableColumn<any>[];
    totalHeader: any;
    data: any[];
    paginationTotalRows: number;
    paginationPerPage: number;
    stats: any;
  }>();

  const container = useRef<HTMLDivElement>(null);
  const [sortDirection, setSort] = useState<string>('desc');
  const [showScroll, setShowScroll] = useState<boolean>(false);
  const [controllerNameValue, setControllerNameValue] = useState<string>('');
  const { response, axiosFetch } = useAxios();
  const { loading } = props;

  const { endpoints } = useGlobalStore((state) => ({
    widgets: state.widgets,
    endpoints: state.endpoints,
  }));

  const { action, chartView, selectedValueL1, selectedValueL2, searchFilter, setState } =
    useWidgetContext();

  const [chartdata, setChartData] = useState<any>({});

  const { striped, pagination, paginationServer, fixedHeader, showTotals, sortServer } =
    options?.table || {};

  const scroll = useRef<HTMLDivElement>(null);
  const sumaryStats = useRef<HTMLDivElement>(null);
  const footerNote = useRef<HTMLDivElement>(null);
  const baseSize = useRef<HTMLDivElement>(null);
  const dynamicTable = useRef<HTMLDivElement>(null);

  const firstRender = useRef(true);
  const totalsFirstRow = useRef();
  totalsFirstRow.current = table?.totalHeader;

  useEffect(() => {
    firstRender.current = true;
    if (defaultResponse?.data?.data) {
      setState({
        selectedValueL1: defaultResponse?.data?.controlData?.defaultValueL1,
        selectedValueL2: defaultResponse?.data?.controlData?.defaultValueL2,
        defaultValueL2: defaultResponse?.data?.controlData?.defaultValueL2,
      });
      setNumericData(defaultResponse?.data?.data ? defaultResponse?.data?.data : []);
      setTableData(defaultResponse);
      setDropdownValues(defaultResponse);
    }
  }, [defaultResponse]);

  useEffect(() => {
    if (action === WidgetAction.SWITCH_CHART_TABLE) {
      setState({ action: WidgetAction.NONE });
    }
    if (action === WidgetAction.SEARCH_FILTER) {
      firstRender.current = false;
      getData(1);
      setState({ action: WidgetAction.NONE });
    }
    if (action === WidgetAction.EXPORT_TO_CSV) {
      exportToCSV();
      setState({ action: WidgetAction.NONE });
    }
    if (action === WidgetAction.EXPORT_TO_XLSX) {
      generateExcelFile();
      setState({ action: WidgetAction.NONE });
    }
    if (action == WidgetAction.EXPORT_TO_HTML) {
      const clonedTable = JSON.parse(JSON.stringify(table));
      exportToHtml(clonedTable, props.displayName);
      setState({ action: WidgetAction.NONE });
    }
  }, [action]);

  useEffect(() => {
    if (
      (selectedValueL1 && selectedValueL1?.label !== '') ||
      (selectedValueL2 && selectedValueL2?.label !== '')
    ) {
      getData(1);
      firstRender.current = false;
    }
  }, [selectedValueL1, selectedValueL2]);

  const setDropdownValues = (response: any) => {
    if (response?.data?.controlData?.optionsL2) {
      setState({ optionsL2: response?.data?.controlData?.optionsL2 });
    }
  };

  useEffect(() => {
    if (response?.data?.data) {
      setTableData(response);
      setNumericData(response?.data?.data ? response?.data?.data : []);

      if (chartView) {
        setChartData(response?.data);
      }

      setDropdownValues(response);
    }
  }, [response]);

  useEffect(() => {
    getData(1);
  }, [chartView]);

  const setTableData = (res: any) => {
    if (res?.data && res?.data?.columns) {
      const data = res?.data?.data;
      const columns = getColumns(res?.data);
      const totalHeader = showTotals && data instanceof Array && data.length ? data[0] : null;

      setTable(() => ({
        rawData: res?.data,
        columns,
        data,
        totalHeader,
        paginationTotalRows: res?.data?.total,
        paginationPerPage: res?.data?.per_page,
        stats: res?.data.summaryStats,
      }));
    }
  };

  /** Search and return a property name of an object based in a lowercase string */
  const getNonCaseSensitiveProp = (prop: string) => {
    return Object.keys(payload).find((key) => key.toLowerCase() === prop) || 'controllerName';
  };

  /** Get controllerName based on chartView property and if controllerName is present in the widget payload */
  const getControllerName = () => {
    const viewName = chartView ? library.HORIZONTAL_BAR_CHART : library.DYNAMIC_TABLE;

    const searchKey = 'controllername';
    const nonCaseSensitiveValue = getNonCaseSensitiveProp(searchKey);
    const controlerNameValue = payload[nonCaseSensitiveValue];

    setControllerNameValue(controlerNameValue);

    const ctrlChart = controlerNameValue ? `${controlerNameValue}Chart` : viewName;
    const ctrlTable = controlerNameValue ? `${controlerNameValue}` : viewName;

    return chartView ? ctrlChart : ctrlTable;
  };

  const getData = async (
    page: number,
    perPage: number = table?.paginationPerPage || 5,
    selectedColumn: any = null,
    direction = 'asc'
  ) => {
    if (firstRender.current) return;
    const controllerName = getControllerName();

    const dropdownValues = searchFilter
      ? { [selectedValueL1?.value]: searchFilter }
      : { Parameter: selectedValueL1?.value };

    const GridFilters: any = chartView
      ? [selectedValueL1?.value, selectedValueL2?.value]
      : dropdownValues;

    const dropdownValuesFiltered = searchFilter
      ? { [selectedValueL1?.value]: searchFilter }
      : [selectedValueL1?.value];

    const Filters: any = chartView
      ? [selectedValueL1?.value, selectedValueL2?.value]
      : dropdownValuesFiltered;

    const newPayload =
      pagination || sortServer
        ? {
            ...payload,
            page,
            perPage,
            selectedColumn,
            sortDirection: direction,
            ControllerName: controllerName,
            GridFilters,
            Filters,
            current1: firstRender.current,
          }
        : {
            ...payload,
            ControllerName: controllerName,
            GridFilters,
            Filters,
            current2: firstRender.current,
          };
    if (chartView) {
      delete newPayload.GridFilters;
    } else {
      delete newPayload.Filters;
    }
    await getDataFromSingleEndpoint(endpoints.getWidgetData, newPayload, axiosFetch);
  };

  const getDefaultColumn = (col: any) => {
    return {
      name: (
        <Tooltip text={col.displayName}>
          <span>{col.displayName}</span>
        </Tooltip>
      ),
      omit: col.colType === 'Hidden',
      wrap: true,
      tooltip: col.displayName,
      selector: (row: { [x: string]: any }) => {
        return { value: row[`${col.field}`], field: col.field };
      },
      sortField: col.field,
      format: (row: { [x: string]: string | any }) => {
        if (col.isPercentage) {
          const field = `${col.field}`;
          return `${getValueFromRow(row, field)}%`;
        }
        return row[`${col.field.slice(0, 100)}`];
      },
      cell: (row: { [x: string]: any }) => {
        const fieldValue = `${col.field}`;
        const rowValue = getValueFromRow(row, fieldValue);
        const statTestingValue = getSignificanceValueFromRow(row, fieldValue);
        return (
          <Tippy
            content={
              <div title={rowValue}>
                <div className="tooltip-title">{rowValue}</div>
              </div>
            }
          >
            <>
              <div
                className={`pck-card-title ${row['rowType'] === 'L1' ? 'l1-row' : ''} ${
                  row['rowType'] === 'L2' ? 'l2-row' : ''
                }`}
                title={rowValue}
                style={{
                  maxWidth: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                <span title={rowValue}>{col.isPercentage ? rowValue + '%' : rowValue}</span>
              </div>
              {statTestingValue}
            </>
          </Tippy>
        );
      },
    };
  };

  const getColumns = (data: any) => {
    const cols = data?.columns;
    const localresponse = response ? response : defaultResponse;
    return cols.map((col: any, index: any) => {
      const columnData = data?.data ?? [];

      let maxLenght = columnData
        .filter((x: any) => x[col.field])
        .map((x: any) => x[col.field])
        .reduce((maxLength: any, str: any) => {
          let currentLength = str?.value?.toString().length ?? str.toString().length;
          if (str?.significanceIndicator || false) {
            //Give extra 5 characters to make space for the significance indicator
            currentLength += 5;
          }
          return Math.max(maxLength, currentLength);
        }, 0);
      maxLenght = Math.max(maxLenght, col.displayName.length);

      const calculateColumnWidth = (maxLength: any) => {
        return `${Math.max(5, maxLength / 2 + 1)}em`;
      };

      if (index === 0 && col.displayName === '') {
        col.displayName = selectedValueL1?.label
          ? selectedValueL1?.label
          : localresponse.data.controlData.defaultValueL1.label;
      }
      if (col.displayName === '') {
        return {
          cell: (row: { [x: string]: string | any }) => (
            <SingleHorizontalBarChart
              value={row[`${col.field}`]}
              isPercentage
              color="#0071c1"
              showLabel={true}
            />
          ),
          ignoreRowClick: true,
        };
      }

      const defaultColumn = getDefaultColumn(col);
      if (payload?.QueriesMetadata !== null) {
        return {
          ...defaultColumn,
          sortable: payload?.QueriesMetadata?.length === 1 ? true : false,
          ignoreRowClick: true,
          minWidth: calculateColumnWidth(maxLenght),
        };
      }
      if (!col.isLink || !localresponse?.data?.idSurveyPanel) {
        return {
          ...defaultColumn,
          sortable: true,
          ignoreRowClick: true,
          minWidth: calculateColumnWidth(maxLenght),
        };
      }
      return {
        ...defaultColumn,
        sortable: col.displayName !== '' ? true : false,
        minWidth: calculateColumnWidth(maxLenght),
      };
    });
  };

  const handlePageChange = (page: number) => {
    if (page !== 1) {
      firstRender.current = false;
      getData(page, table?.paginationPerPage);
    }
  };

  const handlePerRowsChange = async (perPage: number, page: number) => {
    if (table) {
      setTable({
        ...table,
        paginationPerPage: perPage,
      });
      if (page !== 1) {
        firstRender.current = false;
        getData(page, perPage);
      }
    }
  };

  const handleSort = (column: TableColumn<any>, direction: SortOrder) => {
    // This fires only if the sort is managed by server side
    setSort(direction);
    getData(1, table?.paginationPerPage, column.sortField, direction);
  };

  const setSortIcon = (_column: TableColumn<any>, direction: SortOrder) => {
    setSort(direction);
  };

  const customSort = (rows: any[], selector: (arg0: any) => any, direction: string) => {
    let rowCollection = showTotals ? rows.slice(1) : rows;
    const { field } = selector([rowCollection]);

    rowCollection = rowCollection.sort((a, b) => {
      const aValue = extractValue(a[field]);
      const bValue = extractValue(b[field]);

      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return direction === 'desc' ? bValue - aValue : aValue - bValue;
      } else if (typeof aValue === 'number') {
        return direction === 'desc' ? -1 : 1;
      } else if (typeof bValue === 'number') {
        return direction === 'desc' ? 1 : -1;
      } else if (typeof aValue === 'string' && typeof bValue === 'string') {
        return direction === 'desc'
          ? bValue.localeCompare(aValue, undefined, { numeric: true, sensitivity: 'base' })
          : aValue.localeCompare(bValue, undefined, { numeric: true, sensitivity: 'base' });
      } else if (typeof aValue === 'string') {
        return direction === 'desc' ? -1 : 1;
      } else if (typeof bValue === 'string') {
        return direction === 'desc' ? 1 : -1;
      } else {
        return 0;
      }
    });

    if (showTotals) {
      rowCollection.unshift(totalsFirstRow.current);
    }

    return rowCollection;
  };

  const handleRowClick = (row: any) => {
    try {
      const surveyParams: any = {
        idSurveyPanel: response?.data?.idSurveyPanel | defaultResponse?.data?.idSurveyPanel,
        idSurvey: row['field_0'],
      };
      // eslint-disable-next-line prettier/prettier
      (window as any)['commentSurveyLinkPeacock'](surveyParams);
    } catch (error: any) {
      console.error(`${error.message}, be sure you are running from CX application`);
    }
  };

  const dataTableProps = useMemo(() => {
    if (!chartView && table && table?.columns.length && (response || defaultResponse)) {
      return {
        ...(pagination
          ? {
              pagination,
              paginationTotalRows: table.paginationTotalRows,
              paginationPerPage: table.paginationPerPage,
            }
          : {}),
        onChangeRowsPerPage: handlePerRowsChange,
        onChangePage: handlePageChange,
        ...(paginationServer ? { paginationPerPage: table?.paginationPerPage } : {}),
        ...(striped ? { striped, theme: 'solarized' } : {}),
        ...(sortServer ? { onSort: handleSort, sortServer } : {}),
        ...{ sortFunction: customSort, onSort: setSortIcon },
        customStyles: {
          ...(showTotals
            ? {
                ...customStyles,
                rows: { style: { ...customStyles.rows.style, ...highlightStyles } },
                cells:
                  response?.data?.idSurveyPanel || defaultResponse?.data?.idSurveyPanel
                    ? { style: { ...customStyles.cells.style, ...cellLink } }
                    : { style: { ...customStyles.cells.style } },
              }
            : {
                ...customStyles,
                cells:
                  response?.data?.idSurveyPanel || defaultResponse?.data?.idSurveyPanel
                    ? { style: { ...customStyles.cells.style, ...cellLink } }
                    : { style: { ...customStyles.cells.style } },
              }),
          responsiveWrapper: {
            style: {
              height: '100%',
            },
          },
        },
        defaultSortFieldId: 1,
      };
    }

    return {};
  }, [table?.data]);

  useEffect(() => {
    const handleResize = () => {
      if (dynamicTable.current) {
        setShowScroll(true);
      } else {
        setShowScroll(false);
      }
    };

    // Attach the event listener when the component mounts
    window.addEventListener('resize', handleResize);

    // Call the handler once to initialize the divWidth state
    handleResize();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderToolTip = (cellData: any) => {
    return (
      <div>
        <span>{cellData}</span>
      </div>
    );
  };

  const generateExcelFile = () => {
    // deep clone the table variable
    const clonedTable = JSON.parse(JSON.stringify(table));
    let filteredTable = removeOmittedColumns(clonedTable);
    filteredTable = convertFieldObjectToValue(filteredTable);
    exportToExcel(filteredTable, props.displayName, showTotals, customWidgetConfig);
    toggleMenu();
  };

  const exportToCSV = () => {
    // find index column with the omit flag
    const clonedTable = JSON.parse(JSON.stringify(table));

    let filteredTable = removeOmittedColumns(clonedTable);
    filteredTable = convertFieldObjectToValue(filteredTable);
    const csvData = convertToCSV(filteredTable, props.displayName, showTotals, customWidgetConfig);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, 'data.csv');
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div
      className={`table-container ${
        table?.rawData?.subTitle || getOneQuestionSubTitle(customWidgetConfig) !== null
          ? 'separator-line'
          : ''
      }`}
      ref={container}
    >
      {table?.rawData?.subTitle?.length || getOneQuestionSubTitle(customWidgetConfig) !== null ? (
        <div ref={sumaryStats}>
          <div id="qLongText" className="question-text">
            {table?.rawData?.subTitle || getOneQuestionSubTitle(customWidgetConfig)}
          </div>
          {table?.rawData?.baseSize ? (
            <SummaryStats stats={table?.stats} baseSize={table?.rawData?.baseSize}></SummaryStats>
          ) : null}
        </div>
      ) : (
        <></>
      )}
      {table?.rawData?.baseSize &&
      !table?.rawData?.subTitle &&
      getOneQuestionSubTitle(customWidgetConfig) === null &&
      (controllerNameValue !== 'TierPerformance' ||
        (controllerNameValue === 'TierPerformance' && !chartView)) ? (
        <span className="base-size-span" ref={baseSize}>
          <span
            dangerouslySetInnerHTML={{
              __html: boldNumbersInString(table?.rawData?.baseSize, showTotals),
            }}
          ></span>
        </span>
      ) : (
        <></>
      )}
      <div
        className={`dynamic-table 
        ${showScroll ? 'sc-container' : ''} 
        ${chartView ? 'dt-max-height' : fixedHeader ? 'fixed-header' : ''}`}
        ref={dynamicTable}
      >
        <div ref={scroll} className={`${chartView ? 'dt-max-height' : ''}`}>
          {chartView ? (
            <HorizontalBarChart colors={['']} options={options} {...props} response={chartdata} />
          ) : table?.columns && table?.data && Array.isArray(table.data) ? (
            <DataTable
              {...dataTableProps}
              columns={table.columns}
              data={table.data}
              onRowClicked={handleRowClick}
              sortIcon={<SortIcon status={sortDirection} />}
              progressPending={loading}
            />
          ) : null}
        </div>
      </div>

      {table?.rawData?.footNote ? (
        <span className="table-grid-foot-note" ref={footerNote}>
          {table?.rawData?.footNote}
        </span>
      ) : (
        <></>
      )}
    </div>
  );
};

export default DynamicTable;
