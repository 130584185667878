import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import ColorInput from '../../core-ui/ColorInput/ColorInput';
import { colorInitialState, ColorInputValue } from '../../constants/colorsInitialStates';
import { marks } from 'constants/customOptionConstants';
import GenericNumberInput from '../genericNumberInput/genericNumberInput';

export interface OptionCheckboxProps {
  option: any;
  onChangeHandler: any;
  onSetGoalValuesChange: any;
  childComponent: any;
  customValues: [];
}

const GoalLine: React.FC<any> = ({ onSetGoalValuesChange, customValues }) => {
  const [goal, setGoal] = useState<any>(customValues);
  const [isValidNumericGoal, setIsValidNumericGoal] = useState<boolean>(false);
  const [selectedColor, setSelectedColor] = useState<ColorInputValue>(colorInitialState);
  const [selectedOption, setSelectedOption] = useState(customValues?.lineStyle || 'solid');

  const options = [
    { value: 'solid', label: 'Solid', icon: 'long-arrow-right' },
    { value: 'dashed', label: 'Dashed', icon: 'long-arrow-right' },
  ];

  const initColor = (): string => {
    if (customValues?.goalLineColor) return customValues?.goalLineColor;
    return '#000';
  };

  useEffect(() => {
    if (goal) {
      onSetGoalValuesChange(goal);
    }
  }, [goal]);

  useEffect(() => {
    setGoal({ ...goal, goalLineColor: selectedColor.color });
  }, [selectedColor]);

  const handleNumericGoal = (event: { target: { value: any } }) => {
    const numericGoalValue = event.target.value;
    setGoal({
      ...goal,
      goalValue: numericGoalValue > 100 || numericGoalValue <= 0 ? 1 : event.target.value,
    });
    setIsValidNumericGoal(numericGoalValue > 100);
  };

  const handleLineWeight = (value: any) => {
    setGoal({ ...goal, lineWidth: value });
  };

  const handleLineStyle = (lineStyle: any) => {
    setSelectedOption(lineStyle.value);
    setGoal({ ...goal, lineStyle: lineStyle.value });
  };

  return (
    <>
      <div className="diy-input-container">
        <GenericNumberInput
          onChangeMethod={handleNumericGoal}
          value={customValues?.goalValue}
          label="Enter Numeric Goal"
        />
        {isValidNumericGoal ? (
          <span className="diy-required-field">Minimum value is 1 and maximum value is 100</span>
        ) : null}
        <div className="diy-material-textfield" style={{ paddingBottom: 20 }}>
          <ColorInput
            inputTitle={'Color'}
            index={0}
            defaultColor={initColor()}
            setSelectedColor={setSelectedColor}
          />
        </div>
        <div className="diy-select-goal-line" style={{ paddingBottom: 20 }}>
          <Select
            options={options}
            onChange={handleLineStyle}
            defaultValue={customValues?.lineStyle || options?.[0]}
            value={options.filter(function (option) {
              return option.value === selectedOption;
            })}
          />
          <label className="diy-label select">Line Style</label>
        </div>
        <div className="diy-select-goal-line" style={{ paddingTop: 10, paddingBottom: 20 }}>
          <Slider
            min={0}
            max={5}
            dots
            marks={marks}
            onChange={handleLineWeight}
            value={customValues?.lineWidth}
          />
          <label className="diy-label-slider">Weight</label>
        </div>
      </div>
    </>
  );
};

export default GoalLine;
