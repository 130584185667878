export const diyActionsOptions = {
  HorizontalBarChart: [
    {
      actionName: 'SORT',
      displayName: 'Make Sortable',
      isChecked: false,
    },
  ],
  StackedBarChart: [
    { actionName: 'SWITCH_BAR_LINE', displayName: 'Display Bar/Line Toggle', isChecked: false },
  ],
};

export const diyChartOptions = {
  PieChart: [
    {
      actionName: 'showTitle',
      displayName: 'Display Title',
      isChecked: true,
    },
    {
      actionName: 'showLabels',
      displayName: 'Display Labels',
      isChecked: true,
    },
    {
      actionName: 'decimalPlaces',
      displayName: 'Decimal Places',
      value: 2,
    },
  ],
  VerticalBarChart: [
    {
      actionName: 'showNegativeValues',
      displayName: 'Display Negative Values',
      isChecked: false,
    },
    {
      actionName: 'showLegend',
      displayName: 'Display Legend',
      isChecked: true,
    },
    {
      actionName: 'drawGoalLine',
      displayName: 'Set Goal',
      isChecked: false,
      childComponent: 'GoalLine',
    },
    {
      actionName: 'decimalPlaces',
      displayName: 'Decimal Places',
      value: 2,
    },
    {
      actionName: 'setYAxisMinMaxValues',
      displayName: 'Set Y Range',
      isChecked: false,
      childComponent: 'YAxisValueComponent',
    },
  ],
  StackedBarChart: [
    {
      actionName: 'showLegend',
      displayName: 'Display Legend',
      isChecked: true,
    },
    {
      actionName: 'showLineChart',
      displayName: 'Set Line Chart as Default',
      isChecked: false,
    },
    {
      actionName: 'showValues',
      displayName: 'Display Values Automatically',
      isChecked: false,
    },
    {
      actionName: 'drawGoalLine',
      displayName: 'Set Goal',
      isChecked: false,
      childComponent: 'GoalLine',
    },
    {
      actionName: 'setYAxisMinMaxValues',
      displayName: 'Set Y Range',
      isChecked: false,
      childComponent: 'YAxisValueComponent',
    },
    {
      actionName: 'decimalPlaces',
      displayName: 'Decimal Places',
      value: 2,
    },
  ],
  HorizontalBarChart: [
    {
      actionName: 'showRowsWithNoData',
      displayName: 'Show Rows with No Data',
      isChecked: false,
    },
    {
      actionName: 'drawGoalLine',
      displayName: 'Set Goal',
      isChecked: false,
      childComponent: 'GoalLine',
    },
    {
      actionName: 'decimalPlaces',
      displayName: 'Decimal Places',
      value: 2,
    },
  ],
  // BulletChart options will be enabled later
  BulletChart: [
    {
      actionName: 'decimalPlaces',
      displayName: 'Decimal Places',
      value: 2,
    },
  ],
  OneQuestionOneCalculation: [
    {
      actionName: 'decimalPlaces',
      displayName: 'Decimal Places',
      value: 2,
    },
  ],
  OneQuestionManyCalculations: [
    {
      actionName: 'decimalPlaces',
      displayName: 'Decimal Places',
      value: 2,
    },
  ],
  OneQuestion: [
    {
      actionName: 'decimalPlaces',
      displayName: 'Decimal Places',
      value: 2,
    },
  ],
};
