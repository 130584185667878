import React from 'react';
import './PerformanceTable.scss';

interface PerformanceTableProps {
  data: any;
  isPercentage: boolean;
  selectedRowData: any;
  setSelectedRowData: React.Dispatch<any>;
  labels: any;
}

const PerformanceTable: React.FC<PerformanceTableProps> = ({
  data,
  isPercentage,
  selectedRowData,
  setSelectedRowData,
  labels,
}) => {
  const headers = [
    'Rank',
    labels?.displayNameLabel ? labels?.displayNameLabel : 'Agent',
    'Base',
    labels?.metricLabel ? labels?.metricLabel : 'Satisfaction with Associate',
  ];

  return (
    <>
      <div className="pck-performance-table">
        <div className="table-row head">
          {headers.map((header, i) => (
            <div className="header-item" key={i}>
              {header}
            </div>
          ))}
        </div>
        {data?.map((item: any, i: any) => (
          <div
            className={`table-row body ${selectedRowData.i === i ? 'selected' : ''}`}
            key={i}
            onClick={() => {
              setSelectedRowData({ i, data: item });
            }}
          >
            <div className="body-item">
              <span>{item.rank}</span>
            </div>
            <div className="body-item pck-tooltip">
              <div className="truncate-text">{item.displayName}</div>
              <span className="tooltiptext right">{item.displayName}</span>
            </div>
            <div className="body-item">{item.base}</div>
            <div className="body-item">
              {item.metric} {isPercentage ? <>%</> : null}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default PerformanceTable;
