import React, { useState } from 'react';
import Select, { components } from 'react-select';
import './PckDropdown.scss';

export interface PckDropDownProps {
  options: any;
  defaultValue: any;
}

const primaryColor = '#00a0af';

const textStyle = {
  color: primaryColor,
  fontFamily: '"Roboto",sans-serif',
  fontSize: '18px',
  fontWeight: 600,
};

const Icon: React.FC<any> = ({ innerRef, innerProps }) => (
  <span className="caret-icon" ref={innerRef} {...innerProps}></span>
);

const Empty: React.FC<any> = ({ innerRef, innerProps }) => (
  <>
    <div ref={innerRef} {...innerProps}></div>
  </>
);

const Menu: React.FC<any> = (innerProps) => (
  <>
    <div className="arrow"></div>
    <components.Menu {...innerProps}>{innerProps.children}</components.Menu>
  </>
);

const _components = {
  DropdownIndicator: Icon,
  IndicatorSeparator: Empty,
  Menu: Menu,
};

const PckDropDown: React.FC<any> = ({ options, defaultValue, onChange }) => {
  const [textInputStyle, setTextInputStyle] = useState(primaryColor);
  const onMenuOpen = () => setTextInputStyle('transparent');
  const onMenuClose = () => setTextInputStyle(primaryColor);
  const maxWidth = (extra: number) => {
    return `${8 * Math.max(...options.map((o: any) => o.label.length)) + extra}px`;
  };

  const customStyles = {
    control: (provided: any) => {
      const style = {
        border: 0,
        minHeight: '1em',
      };

      return { ...provided, ...style };
    },
    singleValue: (provided: any) => {
      const style = {
        ...textStyle,
        color: textInputStyle,
      };
      return { ...provided, ...style };
    },
    input: (provided: any) => {
      const style = {
        ...textStyle,
      };
      return { ...provided, ...style };
    },
    option: (provided: any, state: any) => {
      const style = {
        ...textStyle,
        padding: '8px 15px 8px 15px',
        cursor: 'pointer',
        fontSize: '13px',
        lineHeight: 1.42857143,

        color: state.isFocused ? 'white' : primaryColor,
        backgroundColor: state.isFocused ? primaryColor : 'transparent',

        whiteSpace: 'nowrap',
      };
      return { ...provided, ...style };
    },
    menu: (provided: any) => {
      const style = {
        width: maxWidth(10),
        zIndex: 999,
        borderRadius: '0px',
        marginTop: '10px',
        border: '0px',
      };
      return { ...provided, ...style };
    },
    menuList: (provided: any) => {
      return {
        ...provided,

        '::-webkit-scrollbar': {
          width: '10px',
        },
        '::-webkit-scrollbar-track': {
          background: 'transparent',
        },
        '::-webkit-scrollbar-track:hover': {
          background: '#eee',
        },
        '::-webkit-scrollbar-thumb': {
          background: '#aaaa',
          borderRadius: '10px',
          border: '2px solid transparent',
          backgroundClip: 'padding-box',
        },
        '::-webkit-scrollbar-thumb:hover': {
          background: '#aaaa',
          border: '0px',
        },
      };
    },
  };

  const theme = (theme: any) => {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary: primaryColor,
        primary25: primaryColor,
        primary50: primaryColor,
      },
    };
  };
  return (
    <div>
      <Select
        placeholder=""
        options={options}
        components={_components}
        styles={customStyles}
        theme={theme}
        defaultValue={defaultValue}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        onChange={(value) => onChange(value)}
      />
    </div>
  );
};

export default PckDropDown;
