import { lightenDarkenColor } from './darkenLightenColor';

export const legendSelectSeries = (
  selectedSeries: any,
  optionSeries: any,
  colorPalette: string[],
  data: any
) => {
  const selectedIndexes = selectedSeries.map((key: any) => {
    return data.findIndex((item: any) => item[0] === key);
  });

  return optionSeries?.map((s: any, i: number) => {
    if (!selectedIndexes.includes(i + 1) && selectedIndexes.length > 0) {
      return {
        ...s,
        itemStyle: { color: '#DBDBDB' },
        emphasis: { itemStyle: { color: '#DBDBDB' } },
      };
    } else {
      return {
        ...s,
        itemStyle: { color: colorPalette[i] },
        emphasis: { itemStyle: { color: lightenDarkenColor(colorPalette[i], -40) } },
      };
    }
  });
};
