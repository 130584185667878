import { appAssets } from 'constants/assets';
import React from 'react';

import './Sort.scss';
interface SortIconProps {
  status: string;
}

const SortIcon: React.FC<SortIconProps> = ({ status = 'asc' }) => {
  const ArrowBoth = appAssets.icons.ARROW_BOTH;
  const ArrowDown = appAssets.icons.ARROW_DOWN;

  const sortIcon = (sort: string) => {
    if (sort === 'asc' || sort === 'desc') return <ArrowDown />;

    return <ArrowBoth />;
  };
  return <div className="sort-icon">{sortIcon(status)}</div>;
};

export default SortIcon;
