import React, { FC, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import { axisLabel, axisLine, axisTick, tooltip, trendsIcon } from 'constants/chartOption';
import { BaseWidget } from 'models/WidgetModel';
import { smileyIcon, neutralIcon, frownIcon } from 'constants/assets';
import { lightenDarkenColor } from 'helpers/darkenLightenColor';
interface HorizontalChartTrendsProps extends BaseWidget {
  colors: string[];
}

const HorizontalBarTrendsChart: FC<HorizontalChartTrendsProps> = ({
  response,
  colors,
  ...props
}) => {
  const [series, setSeries] = React.useState<any[]>([]);
  const [options, setOptions] = React.useState<any>();
  const [colorPalette, setColorPallete] = React.useState<string[]>([]);
  const [isPercentage, setIsPercentage] = React.useState<boolean>(false);
  const { width, height } = props;

  useEffect(() => {
    if (response) {
      setSeries(response.data.seriesData);
      setColorPallete(colors);
      setIsPercentage(response.data.isPercentage);
    }
  }, [response]);

  const sentimentTrends = (val: string | number) => {
    if (val >= 1) return { sentiment: 'positive', color: colorPalette[0] };
    if (val <= -1) return { sentiment: 'negative', color: colorPalette[2] };
    return { sentiment: 'neutral', color: colorPalette[1] };
  };

  const xData = series?.map((item: any) => {
    return item.xValue;
  });

  const trendsData = series?.map((item: any) => {
    return {
      value: 100,
      sentiment: item.sentiment,
      itemStyle: {
        color: sentimentTrends(item.sentiment).color,
      },
    };
  });

  const seriesData = series?.map((item: any) => {
    return {
      value: item.yValue,
      itemStyle: {
        color: sentimentTrends(item.sentiment).color,
      },
      emphasis: {
        itemStyle: {
          ...(colors?.length
            ? { color: lightenDarkenColor(sentimentTrends(item.sentiment).color, -40) }
            : {}),
        },
      },
    };
  });

  const yAxis = {
    type: 'category',
    data: xData,
    ...axisTick,
    ...axisLabel,
    axisLine: {
      show: false,
    },
  };

  const xAxis = {
    type: 'value',
    show: false,
    splitLine: {
      show: false,
    },
    ...axisTick,
    ...axisLabel,
    ...axisLine,
  };

  useEffect(() => {
    const optionsObject = {
      yAxis: [
        {
          ...yAxis,
          show: true,
          gridIndex: 0,
        },
        {
          ...yAxis,
          show: false,
          gridIndex: 1,
        },
      ],
      xAxis: [
        {
          ...xAxis,
          gridIndex: 0,
        },
        {
          ...xAxis,
          gridIndex: 1,
        },
      ],
      grid: [
        {
          show: false,
          width: '40',
          left: '110',
          right: '15',
          top: '20',
          bottom: '5',
          tooltip: {
            show: false,
          },
        },
        {
          show: false,
          width: '70%',
          left: '160',
          right: '15',
          top: '20',
          bottom: '5',
        },
      ],
      tooltip: {
        ...tooltip,
        formatter: `<strong>{b}:</strong> {c}${isPercentage ? '%' : ''}`,
      },
      series: [
        {
          type: 'bar',
          data: trendsData,
          itemStyle: {
            borderRadius: 5,
          },
          barWidth: '94%',
          height: '40',
          xAxisIndex: 0,
          yAxisIndex: 0,
          animation: false,
          silent: true,
          label: {
            show: true,
            formatter: (x: any) => {
              return `{${sentimentTrends(x.data.sentiment).sentiment}| }`;
            },
            rich: {
              positive: {
                ...trendsIcon,
                backgroundColor: {
                  image: smileyIcon,
                },
              },
              neutral: {
                ...trendsIcon,
                backgroundColor: {
                  image: neutralIcon,
                },
              },
              negative: {
                ...trendsIcon,
                backgroundColor: {
                  image: frownIcon,
                },
              },
            },
          },
        },
        {
          data: seriesData,
          type: 'bar',
          itemStyle: {
            borderRadius: 5,
          },
          barWidth: '94%',
          color: colorPalette,
          xAxisIndex: 1,
          yAxisIndex: 1,
          label: {
            show: true,
            position: 'right',
            distance: 5,
            color: '#383838',
            fontWeight: 'bold',
            formatter: `{c}${isPercentage ? '%' : ''}`,
          },
        },
      ],
    };
    setOptions(optionsObject);
  }, [series]);

  return options ? (
    <div className="responsive-chart">
      <ReactECharts opts={{ renderer: 'svg', width, height }} option={options} />
    </div>
  ) : null;
};

export default HorizontalBarTrendsChart;
