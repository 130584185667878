import React, { PropsWithChildren, createContext, useRef } from 'react';
import { createAppStore, CreateAppStoreType } from './createStore';

export const StoreContext = createContext(null);

export interface StoreProviderProps<T> extends CreateAppStoreType<T> {}

export const ApiContext = createContext(null);

/**
 * @description StoroProvider component. This component is used to provide the store to the application.
 * @prop {React.ReactNode} children - The children of the component
 * @prop {any} initialState - Initial state of the store.
 * @prop {Function} createSlice - Function to create the state and actions of the store.
 * @prop {any} persistConfig - Persist configuration of the store.
 * @prop {GeneratorFunction[]} sagas - Sagas of the store
 * @category Component
 */
export function StoreProvider<T extends object>({
  children,
  initialState,
  createSlice,
  api,
  sagas,
  middleware,
}: PropsWithChildren<StoreProviderProps<T>>) {
  const storeRef = useRef(null);
  if (!storeRef.current) {
    storeRef.current = createAppStore<T>({
      initialState,
      createSlice,
      sagas,
      api,
      middleware,
    });
  }
  return (
    <StoreContext.Provider value={storeRef.current}>
      <ApiContext.Provider value={api}>{children}</ApiContext.Provider>
    </StoreContext.Provider>
  );
}
