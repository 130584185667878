export enum ControllerName {
  LoyaltyEffortDashboard = 'LoyaltyEffortDashboard',
  ClientIssueExperienced = 'ClientIssueExperienced',
  ManagementScorecard = 'ManagementScorecard',
  ScoreCard = 'ScoreCard',
  OverallRep = 'OverallRep',
  OverallRepTable = 'OverallRepTable',
  CorporatePulse = 'CorporatePulse',
  UnstructuredData = 'UnstructuredData',
  FrontlineDashboard = 'FrontlineDashboard',
  SOSWorkflow = 'SOSWorkflow',
  ClosedLoopTool = 'ClosedLoopTool',
  ClosedLoopRecord = 'ClosedLoopRecord',
  IndividualCoaching = 'IndividualCoaching',
  OuterLoopCreateRecord = 'OuterLoopCreateRecord',
  CoachingHistory = 'CoachingHistory',
  TierPerformance = 'TierPerformance',
  IndividualSurveyTable = 'IndividualSurveyTable',
  TextMiningEmergingTrends = 'TextMiningEmergingTrends',
  TextMiningFrequency = 'TextMiningFrequency',
  TextMiningWordCloud = 'TextMiningWordCloud',
  KeywordSummary = 'KeywordSummary',
  QuestionLevelAggregate = 'QuestionLevelAggregate',
  QuestionLevelAggregateChart = 'QuestionLevelAggregateChart',
  OuterLoopSnapshot = 'OuterLoopSnapshot',
  CoachingTool = 'CoachingTool',
  CoachingCards = 'CoachingCards',
  AdHocExports = 'AdHocExports',
  ExploreWithELLIE = 'ExploreWithELLIE',
  ReportUsage = 'ReportUsage',
  ProjectCustomerSearch = 'ProjectCustomerSearch',
  DIYDashboard = 'DIYDashboard',
  IndividualSurvey = 'IndividualSurvey',
  TierPerformanceChart = 'TierPerformanceChart',
  QuestionLevelAggregateOther = 'QuestionLevelAggregateOther',
  UDTopFocusAreas = 'UDTopFocusAreas',
  TextMiningBubbleChart = 'TextMiningBubbleChart',
  PDFViewer = 'PDFViewer',
}
