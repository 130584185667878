import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import './SingleHorizontalBarChart.scss';
import { tooltip } from 'constants/chartOption';
import { lightenDarkenColor } from 'helpers/darkenLightenColor';
import { getLabelWithCustomDecimalPlaces } from 'helpers/utilities';

interface singleHorizontalBarChartProps {
  value: number;
  isPercentage: boolean;
  width?: number;
  height?: number;
  color?: string;
  showLabel?: boolean;
  decimalPlaces?: number;
}

const SingleHorizontalBarChart: React.FC<singleHorizontalBarChartProps> = ({
  value,
  isPercentage,
  color = '#00656E',
  showLabel = false,
  decimalPlaces,
  ...props
}) => {
  const [options, setOptions] = useState<any>({});
  const { width, height } = props;

  useEffect(() => {
    const optionsObj = {
      grid: {
        show: false,
        left: '0',
        right: '0',
        top: '0',
        bottom: '0',
      },
      yAxis: {
        type: 'category',
        data: ['x'],
        show: false,
      },
      xAxis: {
        type: 'value',
        show: false,
      },

      tooltip: {
        ...tooltip,
        formatter: (value: any) =>
          getLabelWithCustomDecimalPlaces(value.value, isPercentage, decimalPlaces),
      },
      series: [
        {
          type: 'bar',
          animation: false,
          stack: 'chart',
          label: {
            position: value >= 90 ? 'insideRight' : 'right',
            show: showLabel,
            textBorderColor: 'transparent',
            color: value >= 90 ? '#ffffff' : '#000000',
          },
          itemStyle: {
            color,
            borderRadius: [3, 0, 0, 3],
          },
          emphasis: {
            itemStyle: {
              color: lightenDarkenColor(color, -40),
            },
          },
          data: [value],
        },
        {
          type: 'bar',
          stack: 'chart',
          silent: true,
          itemStyle: {
            color: '#e0e0e0',
            borderRadius: [0, 3, 3, 0],
          },
          data: [100 - value],
        },
      ],
    };

    setOptions(optionsObj);
  }, [value, color, decimalPlaces]);

  return (
    <div className="single-bar">
      {options ? (
        <ReactECharts opts={{ renderer: 'svg', width, height }} option={options} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default SingleHorizontalBarChart;
