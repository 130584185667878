import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { all } from 'redux-saga/effects';
import { combineWatchers } from 'redux-saga-combine-watchers';
import sagaMiddleware from '@cx/zustand-saga';

export function* rootSaga(sagas: GeneratorFunction[]): any {
  yield all(combineWatchers(sagas));
}

export type CreateActionsParams = {
  set: any;
  get: any;
  store: any;
};

export interface CreateAppStoreType<T> {
  /**
   * @description API base url for fetching filters data.
   */
  api: any;
  /**
   * @description Function to create the state and actions of the store.
   * @param initialState - Initial state of the store.
   * @returns
   */
  createSlice: (initialState: any) => (set: any, get: any, api: any) => any;
  /**
   * @description Initial state of the store.
   */
  initialState?: T;
  /**
   * @description Sagas of the store
   */
  sagas?: GeneratorFunction[];
  /**
   * @description Middlewares to apply to the store.
   */
  middleware?: (appMiddlewares: any) => any;
}

/**
 * @description Creates a store with the given configuration. Allows to create dynamically the store allowing to have multple stores in the same application.
 * @prop {any} initialState - Initial state of the store.
 * @prop {Function} createSlice - Function to create the state and actions of the store.
 * @prop {GeneratorFunction[]} sagas - Sagas of the store
 * @returns
 */

export const createAppStore = <T>({
  createSlice,
  initialState,
  sagas = [],
  api,
  middleware = (appMiddlewares: any) => appMiddlewares,
}: CreateAppStoreType<T>) => {
  return create<T>()(
    devtools(
      middleware(
        sagaMiddleware(
          () => rootSaga(sagas),
          (...args) => ({
            ...createSlice(initialState)(...args),
          }),
          { api }
        )
      )
    )
  );
};
