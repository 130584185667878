import React from 'react';
import '../ThemePicker/ThemePicker.scss';
import { displayColorsByFamily } from 'helpers/utilities';

export interface ColorFamilyPickerProps {
  ColorFamiliesList: string[][];
  selectedColorFamily: string[];
  setSelectedColorFamily: React.Dispatch<string[]>;
}

const ColorFamilyPicker: React.FC<ColorFamilyPickerProps> = ({
  ColorFamiliesList,
  selectedColorFamily,
  setSelectedColorFamily,
}) => {
  const cfamilyChange = (event: any) => {
    setSelectedColorFamily(ColorFamiliesList[event.target.value]);
  };

  return (
    <div className="pck-theme-picker">
      <div className="theme-section">
        <form className="themeForm" onChange={cfamilyChange}>
          {ColorFamiliesList.map((cfItem: string[], i: number) => {
            return (
              <div className="form-check" key={i}>
                <input
                  id={`cfamily-${i}`}
                  type="radio"
                  name="cfamily"
                  value={i}
                  className="form-check-input"
                  defaultChecked={
                    i ==
                    ColorFamiliesList.findIndex(
                      (item: any) => item.toString() === selectedColorFamily.toString()
                    )
                  }
                />
                <label htmlFor={`cfamily-${i}`}>
                  {displayColorsByFamily(cfItem).map((colorItem: string, j: number) => {
                    return (
                      <span
                        className="color-container"
                        key={j}
                        style={{ backgroundColor: colorItem }}
                      ></span>
                    );
                  })}
                </label>
              </div>
            );
          })}
        </form>
      </div>
    </div>
  );
};

export default ColorFamilyPicker;
