import React, { useState, useEffect } from 'react';
import './Drawer.scss';
import DrawerCard from '../DrawerCard/DrawerCard';
import { useAxios } from 'hooks/useAxios';
import { getWidgets } from 'services/Torogoz/TogorozApi';
import Scrollbars from 'react-custom-scrollbars-2';
import { initWidget, Widget, WidgetAlias, WidgetCollection } from 'models/WidgetModel';
import { appAssets } from 'constants/assets';
import { sortByString, SortOptions } from 'helpers/sorting';
import BuildCustomWidget, { CustomWidgetAlias } from './BuildCustomWidget/BuildCustomWidget';
import { useStore } from 'store/useGlobalStore';

export interface DrawerProps {
  defaultWidgets?: any[];
  showDiyToolbox?: boolean;
  useDiyQueryBuilder?: boolean;
  devEnv?: boolean;
}

const Drawer: React.FC<DrawerProps> = ({
  defaultWidgets = [],
  showDiyToolbox = false,
  useDiyQueryBuilder = false,
  devEnv = false,
}) => {
  const CloseRound = appAssets.icons.CLOSE_ROUND;
  const SearchIcon = appAssets.icons.SEARCH;

  const [showDetail, setShowDetail] = useState<boolean>(true);
  const { response, axiosFetch, loading } = useAxios();
  const [featureFlag, setFeatureFlag] = useState<any>();
  const BUILD_CUSTOM_WIDGET = 'BuildCustomWidget';
  const BUILD_QUERY_WIDGET = 'BuildQueryWidget';

  const {
    widgetsAvailable,
    drawerWidgets,
    searchFilter,
    dashboardView,
    widgets,
    external,
    dataSourceType,
    widgetsList,
    setState,
  } = useStore((state) => ({
    widgetsAvailable: state.widgetsAvailable,
    drawerWidgets: state.drawerWidgets,
    searchFilter: state.searchFilter,
    dashboardView: state.dashboardView,
    widgets: state.widgets,
    external: state.configDashboard.external,
    dataSourceType: state.dataSourceType ? state.dataSourceType : ['survey'],
    widgetsList: state.widgetsList,
    setState: state.setState,
  }));

  const [widgetsOnGrid, setWidgetsOnGrid] = useState<string[]>([]);

  const CloseIcon = appAssets.icons.CLOSE;

  useEffect(() => {
    // Quick fix until Consumer fix showDiyToolbox returned as a boolean.
    if (typeof showDiyToolbox === 'object') {
      // If showDiyToolbox comes as object, we need
      // to parse 'False' value for 'Value' property as Boolean
      const valueString: string = showDiyToolbox['Value'];
      setFeatureFlag(JSON.parse(valueString.toLowerCase()));
    } else {
      setFeatureFlag(Boolean(showDiyToolbox));
    }

    const getData = async () => {
      await getWidgets(axiosFetch);
    };
    getData();
  }, []);

  useEffect(() => {
    if (defaultWidgets.length) {
      initialize(widgetsList);
    }
  }, [defaultWidgets]);

  useEffect(() => {
    if (widgets.length) getWidgetsOnGrid();
  }, [widgets]);

  useEffect(() => {
    if (response && !response?.dataSourceType) {
      setState({ widgetsList: response });
      initialize(response);
    }
  }, [response]);

  useEffect(() => {
    getWidgetsOnGrid();
  }, [widgetsAvailable]);

  useEffect(() => {
    if (widgetsOnGrid.length) setDrawerWidgetList();
  }, [widgetsOnGrid]);

  useEffect(() => {
    if (searchFilter) {
      const result = widgetsAvailable.filter(
        (widget: Widget) =>
          widget.displayName?.toLowerCase().includes(searchFilter.toLowerCase()) ||
          widget.viewName?.toLowerCase().includes(searchFilter.toLowerCase()) ||
          widget.description?.toLowerCase().includes(searchFilter.toLowerCase())
      );

      setState({
        drawerWidgets: result.filter((widget) => widgetsOnGrid.indexOf(widget.viewId) <= -1),
      });
    } else {
      setState({
        drawerWidgets: widgetsAvailable.filter(
          (widget) => widgetsOnGrid.indexOf(widget.viewId) <= -1
        ),
      });
    }
  }, [searchFilter, widgetsOnGrid]);

  const getWidgetsOnGrid = () => {
    const wog = widgets.filter((widget) => widget.viewId).map((widget) => widget.viewId);
    setWidgetsOnGrid(wog);
  };

  const setDrawerWidgetList = () => {
    const aWidgets = widgetsAvailable.filter((widget) => !widgetsOnGrid.includes(widget.viewId));
    setState({ drawerWidgets: aWidgets });
  };

  const existingWidget = (wDefault: WidgetCollection, widgetConfig: any) => {
    const [alias] = wDefault.alias.filter(
      (alias: WidgetAlias) => alias.controllerName === widgetConfig.controllerName
    );
    const card: Widget = initWidget(wDefault, alias, widgetConfig);
    return card;
  };

  const nonExistingWidget = (widgetConfig: any, widgetsCollection: WidgetCollection[]) => {
    for (const tWidget of widgetsCollection) {
      const [alias] = tWidget.alias.filter(
        (alias: WidgetAlias) => alias.controllerName === widgetConfig.controllerName
      );

      if (alias) {
        // Create widget
        const card: Widget = initWidget(tWidget, alias, widgetConfig);
        return card;
      }
    }
  };

  const initialize = (widgetsCollection: WidgetCollection[]) => {
    const widgetsList: any[] = [];
    // widgets comes from props;
    if (widgetsCollection?.length && defaultWidgets?.length) {
      defaultWidgets.forEach((widgetConfig: any) => {
        // Search for direct match between athena widget controllerName and torogoz widget component
        const wDefault = widgetsCollection.find(
          (widget: WidgetCollection) => widget.component === widgetConfig.controllerName
        );

        // Even when the component name match with athena controllerName,
        // we need to check if the widget has it own alias configuration
        // If controllerName of widget does not exist in torogoz component names,
        // Iterate over all widget componets and search for alias with the same controllerName
        // wDefault ? existingWidget(wDefault, widgetConfig) : nonExistingWidget(widgetConfig);

        if (wDefault) {
          const exist = existingWidget(wDefault, widgetConfig);
          if (exist) widgetsList.push(exist);
        } else {
          const nonExist = nonExistingWidget(widgetConfig, widgetsCollection);
          if (nonExist) widgetsList.push(nonExist);
        }
      });
    }

    const widgetsListFilter = widgetsList.filter((widget: Widget) => {
      // TODO - Remove this when we have a better way to handle this
      return widget.dataSourceTypes?.filter((item) => dataSourceType.includes(item));
    });

    const buildCustomWidget = {
      alias: 'BuildCustomWidget',
      displayName: 'Build Custom Widget',
      viewName: 'Build Custom Widget',
      description: `Build your own widget with data you choose. Drag this onto the dashboard.`,
    };

    const buildQueryWidget = {
      alias: BUILD_QUERY_WIDGET,
      displayName: 'Build Query Widget',
      viewName: 'Build Query Widget',
      description: `Build your own widget with a query. Drag this onto the dashboard.`,
    };

    const widgets = useDiyQueryBuilder
      ? [buildCustomWidget, buildQueryWidget, ...widgetsListFilter]
      : [buildCustomWidget, ...widgetsListFilter];

    const sortedWidgets = sortByString(widgets, 'displayName', SortOptions.ASC);
    setState({ widgetsAvailable: sortedWidgets });
  };

  const clearFilter = () => {
    setState({ searchFilter: '' });
  };

  const addFilter = () => {
    return (
      <div className="drawer-search">
        <div className="input-box">
          <SearchIcon className="search-icon" />
          <input
            className="search-input"
            value={searchFilter}
            onChange={(e) => setState({ searchFilter: e.target.value })}
            placeholder="Search Widgets"
          />
          <CloseRound className="x-icon-search" onClick={clearFilter} />
        </div>
      </div>
    );
  };

  const handleOptionChange = (e: any) => {
    setState({ dashboardView: e.target.value });
  };

  return (
    <>
      {loading ? (
        <>loading</>
      ) : (
        <div className={`drawer ${showDetail ? 'open' : 'closed'}`}>
          <div className="drawer-container">
            <div className="drawer-title">Widgets</div>
            {addFilter()}
            {!external ? (
              <>
                <div className="dashboard-view-title">Dashboard View</div>
                <div className="dashboard-view-options">
                  <label className="dashboard-view-label">
                    <input
                      type="radio"
                      value="survey"
                      checked={dashboardView === 'survey'}
                      onChange={handleOptionChange}
                    />
                    Survey
                  </label>
                  <br />
                  <label className="dashboard-view-label">
                    <input
                      type="radio"
                      value="chat"
                      checked={dashboardView === 'chat'}
                      onChange={handleOptionChange}
                    />
                    Chat
                  </label>
                  <br />
                  <label className="dashboard-view-label">
                    <input
                      type="radio"
                      value="text-analytics"
                      checked={dashboardView === 'text-analytics'}
                      onChange={handleOptionChange}
                    />
                    Text Analytics
                  </label>
                </div>
              </>
            ) : null}
            <>
              {featureFlag
                ? drawerWidgets
                    .filter((widget) => widget.alias === BUILD_CUSTOM_WIDGET)
                    .map((widget, index) => (
                      <BuildCustomWidget
                        type={CustomWidgetAlias.legacy}
                        key={index}
                        setShowDetail={setShowDetail}
                        widget={widget}
                      />
                    ))
                : null}
            </>
            {drawerWidgets.filter((widget) => widget.alias === BUILD_QUERY_WIDGET).length > 0 ? (
              <BuildCustomWidget
                type={CustomWidgetAlias.query}
                setShowDetail={setShowDetail}
                widget={drawerWidgets.filter((widget) => widget.alias === BUILD_QUERY_WIDGET)[0]}
              />
            ) : null}
            <Scrollbars
              autoHeight={true}
              autoHeightMax={'100%'}
              className={`drawer-scroll ${featureFlag ? 'with-diy' : ''}`}
            >
              {drawerWidgets
                .filter((card) => card.alias !== BUILD_CUSTOM_WIDGET)
                .filter((card) => card.alias !== BUILD_QUERY_WIDGET)
                .map((card, index) => (
                  <DrawerCard key={index} card={card} setShowDetail={setShowDetail} />
                ))}
            </Scrollbars>
            <div className={`drawer-handle`} onClick={() => setShowDetail(!showDetail)}>
              <div
                className="drawer-handle-icon"
                data-testid="showHideIcon"
                style={{ rotate: 'revert' }}
              >
                {showDetail ? 'Hide' : 'Show'}
              </div>
            </div>
            {devEnv ? (
              <div className={`drawer-close`} onClick={() => setState({ editing: false })}>
                <div className="drawer-handle-icon" data-testid="showHideIcon">
                  <CloseIcon />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};

export default Drawer;
