import React, { useEffect, useState } from 'react';
import './CategoryMetricsModal.scss';
import Modal from 'react-modal';
import CategoryDataGrid from 'components/CategoryDataGrid/CategoryDataGrid';
import { BaseWidget } from 'models/WidgetModel';
import { useStore as useGlobalStore } from 'store/useGlobalStore';
import { useAxios } from 'hooks/useAxios';
import { getDataFromSingleEndpoint } from 'services/Torogoz/TogorozApi';
import SessionExplorerContainer from 'components/SessionExplorerContainer/SessionExplorerContainer';
import { appAssets } from 'constants/assets';

interface CategoryMetricsModalProps extends BaseWidget {
  modalOpen: boolean;
  onRequestClose: () => void;
}

const BackIcon = appAssets.icons.UP_ARROW;

const CategoryMetricsModal: React.FC<CategoryMetricsModalProps> = ({
  response,
  options,
  modalOpen,
  onRequestClose,
  ...props
}) => {
  props.colors = [];
  const [showSessionExplorer, setShowSessionExplorer] = useState<boolean>(false);
  const [sessionExplorerData, setSessionExplorerData] = useState<any>();
  const endpoints = useGlobalStore((state) => state.endpoints);

  const { response: secondaryCategoryData, axiosFetch, loading } = useAxios();

  useEffect(() => {
    if (sessionExplorerData) {
      setShowSessionExplorer(true);
    }
  }, [sessionExplorerData]);

  useEffect(() => {
    if (!response) return;
    const getData = async () => {
      await getDataFromSingleEndpoint(
        endpoints.getWidgetData,
        {
          filters: {
            category_id: response.field_0,
          },
          source: 'chat',
          actionName: 'datagrid-subcategories-detail',
          external: true,
        },
        axiosFetch
      );
    };
    getData();
  }, [response]);

  useEffect(() => {
    closeSessionExplorer();
  }, []);

  const closeSessionExplorer = () => {
    setShowSessionExplorer(false);
    setSessionExplorerData(null);
  };

  const closeModal = () => {
    onRequestClose();
    closeSessionExplorer();
  };

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={closeModal}
      className={'custom-modal'}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        content: {
          width: '80%',
        },
      }}
    >
      <div className="grid-container">
        {!loading && (
          <>
            {!showSessionExplorer ? (
              <>
                <div className="modal-header">
                  <span className="label">{response?.field_1}</span>
                </div>
                <CategoryDataGrid
                  response={secondaryCategoryData}
                  options={options}
                  dataOnClick={setSessionExplorerData}
                  {...props}
                />
              </>
            ) : (
              <>
                <div className="modal-header">
                  <span className="back" onClick={() => closeSessionExplorer()}>
                    <BackIcon color="#33929e" />
                  </span>
                  Session Explorer
                </div>
                <SessionExplorerContainer
                  response={sessionExplorerData}
                  options={options}
                  {...props}
                />
              </>
            )}
          </>
        )}
      </div>
      <div className="modal-footer">
        <button className="modal-button modal-button-enabled-inverse" onClick={closeModal}>
          Close
        </button>
      </div>
    </Modal>
  );
};

export default CategoryMetricsModal;
