export enum TagTypes {
  Hierarchy = 'Hierarchy',
  Advanced = 'Advanced',
  Date = 'Date',
}

export enum SourceType {
  Hierarchy = 'Hierarchy',
  OtherFilters = 'OtherFilters',
  Calendar = 'Calendar',
}

export type FilterBarValue = {
  DimName?: string;
  Tag?: TagTypes;
  DisplayName?: string;
  DimId?: number;
  AttributeGuid?: string;
  AttributeName?: string;
  Op1?: string | null;
  Op2?: string | null;
  OpType?: string;
  SubAttribute?: string;
  Source?: SourceType;
  Values?: string[];
  index?: number;
};
