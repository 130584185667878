import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export type StoreReturnType<T> = T & {
  // eslint-disable-next-line prettier/prettier
  setState: (newState: Partial<T>) => void;
};

export function createStore<Store>(initialState: Store) {
  const state = initialState;

  return create<StoreReturnType<Store>>()(
    devtools((set) => ({
      ...state,
      setState: (newState: Partial<Store>) => set((state) => ({ ...state, ...newState })),
    }))
  );
}
