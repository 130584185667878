import React from 'react';
import Select from 'react-select';
import NumberInput from 'core-ui/NumberInput/NumberInput';
import { useStore } from 'store/useGlobalStore';
export interface SelectTrendInputProps {
  loading: boolean;
  options: any;
  selectTrend: any;
  defaultTrend: any;
  selectCount: any;
  selectedChart: TrendOptions | undefined;
  setSelectedChartTrend: any;
}

export interface TrendOptions {
  value: string;
  label: string;
  Trend: Trend;
}

export interface Trend {
  DisplayName?: string;
  TimePeriodType?: string;
  IntervalMaxValue?: number;
  IntervalMinValue?: number;
  SelectedValue?: number | undefined;
}

const SelectTrendInput: React.FC<SelectTrendInputProps> = ({
  loading,
  options,
  selectTrend,
  defaultTrend,
  selectCount,
  selectedChart,
}) => {
  const customWidget = useStore((state) => state.customWidget);

  const customStyles = {
    control: (styles: any) => ({
      ...styles,
      borderRadius: '3px 0 0 3px',
    }),
  };
  const getDefaultTrend = () => {
    const customTrendValue =
      customWidget.customWidgetConfig?.Trend && customWidget.customWidgetConfig.Trend.length > 0
        ? customWidget.customWidgetConfig?.Trend[0].TimePeriodType
        : '';
    const defaultTimePeriodType: string = defaultTrend ? defaultTrend.value : customTrendValue;
    return options?.find((trend: any) => trend.value === defaultTimePeriodType);
  };
  return (
    <div className="diy-input-container">
      <div className="diy-material-trend">
        <Select
          isLoading={loading}
          className="diy-select"
          options={options}
          defaultValue={getDefaultTrend()}
          formatOptionLabel={(option: TrendOptions) => (
            <div className="diy-option">
              <span className="diy-select-label">{option.label}</span>
            </div>
          )}
          onChange={selectTrend}
          styles={customStyles}
        />
        <label className="diy-label select">Interval Time</label>
        <div className="number-input">
          {!Number.isNaN(selectedChart?.Trend.SelectedValue) ? (
            <NumberInput
              selectCount={selectCount}
              min={options && getDefaultTrend() ? getDefaultTrend().Trend.IntervalMinValue : 0}
              max={options && getDefaultTrend() ? getDefaultTrend().Trend.IntervalMaxValue : 0}
              selectedChart={selectedChart}
              label={'Count'}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectTrendInput;
