import React, { useState } from 'react';
import './accordionDrawer.scss';

interface AccordionProps {
  title: string;
  children: React.ReactNode;
}

const Accordion: React.FC<AccordionProps> = ({ title, children }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleAccordion = () => {
    setIsExpanded((prevState) => !prevState);
  };

  return (
    <div className={`pck-accordion ${isExpanded ? 'expanded' : ''}`}>
      <div className="pck-accordion-button" onClick={toggleAccordion}>
        <span className="diy-drawer-section-label">{title}</span>
        <span className={`pck-accordion-icon ${isExpanded ? 'down' : 'up'}`}>
          <span className="triangle-icon"></span>
        </span>
      </div>
      <div className="pck-accordion-content">{isExpanded ? children : null}</div>
    </div>
  );
};

export default Accordion;
