import { Widget } from 'models/WidgetModel';

export const setSessionStorage = (key: string, value: any) => {
  if (key && value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
};

export const getSessionStorage = (key: string) => {
  if (key) {
    return JSON.parse(sessionStorage.getItem(key) || '{}');
  }
};

export const getWidgetFromSessionStorage = (id: any) => {
  const widgets = sessionStorage.getItem('widgets');

  const widgetsObject = widgets ? JSON.parse(widgets) : {};

  const widget: Widget = widgetsObject?.find((item: any) => item.id == id);

  return widget;
};
