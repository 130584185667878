import {
  AdvancedFilterType,
  AdvancedFiltersActions,
  AdvancedFiltersCallBack,
  AppAction,
  SelectedAdvancedFilter,
} from 'types';

export const FECTH_ADVANCED_FILTERS_QUESTION_LIST =
  'filter-bar/fetch-advanced-filters-questions-list';
export const FECTH_ADVANCED_FILTERS_QUESTION_LIST_SUCCESS =
  'filter-bar/fetch-advanced-filters-questions-list/success';
export const FECTH_ADVANCED_FILTERS_QUESTION_LIST_ERROR =
  'filter-bar/fetch-advanced-filters-questions-list/error';

export const FECTH_QUESTION_DETAILS = 'filter-bar/fetch-question-details';
export const FECTH_QUESTION_DETAILS_SUCCESS =
  'filter-bar/fetch-question-details/success';
export const FECTH_QUESTION_DETAILS_ERROR =
  'filter-bar/fetch-question-details/error';

export const FECTH_ATTRIBUTE_CHOICES = 'filter-bar/fetch-question-details';
export const FECTH_ATTRIBUTE_CHOICES_SUCCESS =
  'filter-bar/fetch-question-details/success';
export const FECTH_ATTRIBUTE_CHOICES_ERROR =
  'filter-bar/fetch-question-details/error';

export const INIT_ADVANCED_FILTER = 'filter-bar/init-advanced-filter';
export const ADD_ADVANCED_FILTER = 'filter-bar/add-advanced-filter';
export const REMOVE_ADVANCED_FILTER = 'filter-bar/remove-advanced-filter';
export const UPDATE_ADVANCED_FILTER = 'filter-bar/update-advanced-filter';

export const fetchAdvancedFiltersQuestionList = () => ({
  type: FECTH_ADVANCED_FILTERS_QUESTION_LIST,
});

export const fetchAdvancedFiltersQuestionListSuccess = (
  data: AdvancedFilterType[]
) => ({
  type: FECTH_ADVANCED_FILTERS_QUESTION_LIST_SUCCESS,
  data,
});

export const fetchAdvancedFiltersQuestionListError = (error: string) => ({
  type: FECTH_ADVANCED_FILTERS_QUESTION_LIST_ERROR,
  error,
});

export type ParamsFetchQuestionDetailsSuccess = {
  filter: SelectedAdvancedFilter;
  index: number;
};
export type FetchQuestionDetailsActions =
  AppAction<ParamsFetchQuestionDetailsSuccess>;

export const fetchQuestionDetails = (
  params: ParamsFetchQuestionDetailsSuccess
) => ({
  type: FECTH_QUESTION_DETAILS,
  ...params,
});

export const fetchQuestionDetailsSuccess = () => ({
  type: FECTH_QUESTION_DETAILS_SUCCESS,
});

export const fetchQuestionDetailsError = (error: string) => ({
  type: FECTH_QUESTION_DETAILS_ERROR,
  error,
});

export const fetchAttributeChoices = (
  filter: SelectedAdvancedFilter,
  index?: number
) => ({
  type: FECTH_ATTRIBUTE_CHOICES,
  filter,
  index,
});

export const fetchAttributeChoicesSuccess = () => ({
  type: FECTH_ATTRIBUTE_CHOICES_SUCCESS,
});

export const fetchAttributeChoicesError = (error: string) => ({
  type: FECTH_ATTRIBUTE_CHOICES_ERROR,
  error,
});

export type AddAdvancedFilterActions = AppAction<{
  filter: SelectedAdvancedFilter;
  index?: number;
  callback?: AdvancedFiltersCallBack;
}>;

export const addAdvancedFilter = (
  filter: SelectedAdvancedFilter,
  index?: number,
  callback?: AdvancedFiltersCallBack
) => ({
  type: ADD_ADVANCED_FILTER,
  filter,
  index,
  callback,
});

export type RemoveAdvancedFilterActions = AppAction<{
  filter: SelectedAdvancedFilter;
  index?: number;
  callback?: AdvancedFiltersCallBack;
}>;

export const removeAdvancedFilter = (
  filter: SelectedAdvancedFilter,
  index?: number,
  callback?: AdvancedFiltersCallBack
): RemoveAdvancedFilterActions => ({
  type: REMOVE_ADVANCED_FILTER,
  filter,
  index,
  callback,
});

export type UpdateAdvancedFilterActions = AppAction<{
  filter: SelectedAdvancedFilter;
  index?: number;
  callback?: AdvancedFiltersCallBack;
}>;

export const updateAdvancedFilter = (
  filter: SelectedAdvancedFilter,
  index?: number,
  callback?: AdvancedFiltersCallBack
): UpdateAdvancedFilterActions => ({
  type: UPDATE_ADVANCED_FILTER,
  filter,
  index,
  callback,
});

export type InitAdvancedFilterParams =
  AdvancedFiltersActions['initAdvancedFilters'];
export type InitAdvancedFilterAction = AppAction<{
  callback: InitAdvancedFilterParams;
}>;

export const initAdvancedFilters = (
  callback: InitAdvancedFilterParams
): InitAdvancedFilterAction => ({
  type: INIT_ADVANCED_FILTER,
  callback,
});
