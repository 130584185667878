import React, { useEffect, useState } from 'react';
import { WidgetAction } from 'models/WidgetModel';
import './SwitchBarLine.scss';
import { appAssets } from 'constants/assets';
import { useWidgetContext } from 'contexts/WidgetProvider';

const SwitchView = () => {
  const { setState, defaults } = useWidgetContext();
  if (defaults.SWITCH_BAR_LINE === undefined) defaults.SWITCH_BAR_LINE = 'bars';
  const [switchView, setSwitchView] = useState(defaults.SWITCH_BAR_LINE === 'bars' ? true : false);

  const BarChartIcon = appAssets.icons.BAR_CHART;
  const LineChartIcon = appAssets.icons.LINE_CHART;

  const switchViewHandler = (val: boolean) => {
    if (val !== switchView) {
      setState({ action: WidgetAction.SWITCH_BAR_LINE });
      setSwitchView(val);
    }
  };

  useEffect(() => {
    setSwitchView(defaults.SWITCH_BAR_LINE === 'bars' ? true : false);
  }, [defaults.SWITCH_BAR_LINE]);

  return (
    <div className="switchAction">
      <span className={switchView ? 'active' : ''} onClick={() => switchViewHandler(true)}>
        <BarChartIcon className="icon" /> Bar
      </span>{' '}
      <span className={!switchView ? 'active' : ''} onClick={() => switchViewHandler(false)}>
        <LineChartIcon className="icon" /> Line
      </span>
    </div>
  );
};

export default SwitchView;
