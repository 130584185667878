import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import './RadialGaugeSimple.scss';
import { legendTextStyle } from 'constants/chartOption';

interface RadialGaugeSimpleProps {
  data: any;
  isPercentage: boolean;
  colors: string[];
  width?: any;
  height?: any;
  labels: any;
}

const RadialGaugeSimple: React.FC<RadialGaugeSimpleProps> = ({
  data,
  isPercentage,
  colors,
  labels,
  ...props
}) => {
  const [options, setOptions] = useState<any>(null);
  const [gaugeData, setGaugeData] = useState<any>([]);
  const [innerColor = '#1473C0', outerColor = '#81DFC3'] = colors;
  const { width, height = 230 } = props;

  useEffect(() => {
    if (data) {
      setGaugeData(data);
    }
  }, [data]);

  useEffect(() => {
    const optionsObj = {
      grid: {
        top: 'top',
      },
      legend: {
        show: true,
        bottom: 40,
        textStyle: {
          ...legendTextStyle,
        },
        selectedMode: false,
        data: [
          {
            name: labels?.individualLabel ? labels?.individualLabel : 'Associate',
          },
          {
            name: labels?.teamLabel ? labels?.teamLabel : 'All',
          },
        ],
      },
      series: [
        {
          type: 'pie',
          center: ['50%', '35%'],
          radius: ['65%', '57%'],
          data: [
            {
              value: gaugeData.metric,
              name: labels?.individualLabel ? labels?.individualLabel : 'Associate',
            },
            { value: 100 - gaugeData.metric, name: 'inverse1' },
          ],
          label: {
            formatter: () => {
              return `{associate|${gaugeData.metric}${isPercentage ? ' %' : ''}}\n{all|${
                gaugeData.all
              }${isPercentage ? ' %' : ''}}`;
            },
            rich: {
              associate: {
                color: innerColor,
                fontSize: 18,
                fontWeight: 'bold',
              },
              all: {
                color: outerColor,
                fontSize: 16,
                fontWeight: 'bold',
              },
            },
            show: true,
            align: 'center',
            position: 'center',
          },
          emphasis: {
            disabled: true,
          },
          color: [innerColor, '#EEEEEE'],
        },
        {
          type: 'pie',
          center: ['50%', '35%'],
          radius: ['57%', '49%'],
          label: {
            show: false,
          },
          data: [
            { value: gaugeData.all, name: labels?.teamLabel ? labels?.teamLabel : 'All' },
            { value: 100 - gaugeData.all, name: 'inverse2' },
          ],
          avoidLabelOverlap: true,
          emphasis: {
            disabled: true,
          },
          color: [outerColor, 'transparent'],
        },
      ],
    };

    setOptions(optionsObj);
  }, [gaugeData]);

  return (
    <div className="responsive-chart">
      {options ? (
        <ReactECharts opts={{ renderer: 'svg', width, height }} option={options} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default RadialGaugeSimple;
