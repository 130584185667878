export type DateOption = {
  id?: string;
  type?: string;
  startDate: Date | null;
  endDate: Date | null;
  label: string | null;
};

export enum TimePeriodTypeEnum {
  Unknown = 'Unknown',
  Days = 'Days',
  Weeks = 'Weeks',
  BiWeekly = 'BiWeekly',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  Yearly = 'Yearly',
  Wave = 'Wave',
}

export type DropdownButton = {
  StartDate: string;
  EndDate: string;
  DisplayName: string;
};

export type QuickDateButton = {
  ClientGuid: string;
  DisplayName: string;
  EndDate: string;
  Id: number;
  StartDate: string;
  StartPoint: string;
  Type: string;
  WorkspaceGuid: string;
};

export enum FilteringSolutionEnum {
  RelationshipLayout = 'RelationshipLayout',
  Default = '',
}
