import { FC, useEffect, useState } from 'react';
import { BaseWidget } from 'models/WidgetModel';
import './ChatSegmentation.scss';
import { appAssets } from 'constants/assets';
import { secondDuration } from 'helpers/dateFormatters';
import GaugeChart from '../GaugeChart/GaugeChart';

interface ChatSegmentationProps extends BaseWidget {
  colors: string[];
  width?: any;
  height?: any;
}

interface chatSegmentMetric {
  emitter_role: string;
  messages_count: number;
  msg_duration: number;
}

interface chatSegment {
  segment: string;
  metrics: Array<chatSegmentMetric>;
  average_customer_sentiment: number;
  avg_cumulative_effort: number;
}

const ChatSegmentation: FC<ChatSegmentationProps> = ({ response }) => {
  const [segments, setSegments] = useState<Array<chatSegment>>([]);

  useEffect(() => {
    if (response) {
      if (response?.data?.data?.length) {
        setSegments(response.data.data);
      }
    }
  }, [response]);

  const AgentIcon = appAssets.icons.HEADSET;
  const CustomerIcon = appAssets.icons.USER_ICON;
  const DotCircle = appAssets.icons.DOT_CIRCLE;
  const ChevronCircleRight = appAssets.icons.CHEVRON_CIRCLE_R;

  enum SentimentColors {
    Positive = '#00876C',
    Neutral = '#CFC1A8',
    Negative = '#DE425B',
  }

  enum EffortColors {
    Low = '#FFA39E',
    Moderate = '#FF5950',
    High = '#BE170E',
  }

  const fillColor = '#9a9a9a';
  const gaugeDimensions = { width: '100', height: '100' };

  const setColor = (type: string, value: number) => {
    if (type === 'sentiment') {
      return value < -1
        ? SentimentColors.Negative
        : value >= -1 && value <= 1
        ? SentimentColors.Neutral
        : SentimentColors.Positive;
    }

    if (type === 'effort') {
      return value <= 1.67
        ? EffortColors.Low
        : value > 1.67 && value <= 3.33
        ? EffortColors.Moderate
        : EffortColors.High;
    }
  };

  return (
    <div className="chatSegments">
      <div className="steps mt-5 mx-auto" style={{ maxWidth: 155 * segments?.length + 'px' }}>
        <div className="flex-it justify-content-around segment-container">
          {segments.map((segment, index) => (
            <div className="flex-fill w-150 pl-2 text-center" key={index}>
              {segments.length !== index + 1 ? (
                <ChevronCircleRight fill="#b7b7b7" className="icon-steps"></ChevronCircleRight>
              ) : null}
              {segments.length === index + 1 ? (
                <DotCircle fill={fillColor} className="icon-steps"></DotCircle>
              ) : null}
            </div>
          ))}
        </div>
        <div className="line-steps"></div>
      </div>
      <div
        className="px-0 pt-0 flex-it justify-content-around segment-container"
        style={{ maxWidth: 155 * segments?.length + 'px' }}
      >
        {segments.map((segment, index) => (
          <div className="segment-box flex-fill w-150 pl-3 d-flex" key={index}>
            <div className="sep">
              <h3 className="text-center mb-4 segment-title">{segment.segment}</h3>
              <div className="roles-box">
                <div className="d-flex mx-auto roles pb-2 justify-content-center">
                  <AgentIcon fill={fillColor} title="Agent" className="icon-roles mr-3"></AgentIcon>
                  <CustomerIcon
                    fill={fillColor}
                    title="Customer"
                    className="icons-roles ml-3"
                  ></CustomerIcon>
                </div>
                <div className="d-flex mx-auto pb-2 justify-content-center">
                  <div className="roles-values left p-3 pb-2 text-right">
                    {segment.metrics.map((metric, j) => {
                      if (metric?.emitter_role === 'Agent')
                        return (
                          <div key={`a-${j}`}>
                            <div title="Total Messages" className="pb-3 count-messages">
                              {metric?.emitter_role === 'Agent' ? metric.messages_count : ''}
                            </div>
                            <div title="Total Duration" className="total-duration">
                              {metric?.emitter_role === 'Agent'
                                ? secondDuration(Number(metric.msg_duration))
                                : ''}
                            </div>
                          </div>
                        );
                    })}
                  </div>
                  <div className="roles-values rigth p-3 pb-2 text-left">
                    {segment.metrics.map((metric, k) => {
                      if (metric?.emitter_role === 'Customer')
                        return (
                          <div key={`b-${k}`}>
                            <div title="Total Messages" className="pb-3 count-messages">
                              {metric?.emitter_role === 'Customer' ? metric.messages_count : ''}
                            </div>
                            <div title="Total Duration" className="total-duration">
                              {metric?.emitter_role === 'Customer'
                                ? secondDuration(Number(metric.msg_duration))
                                : ''}
                            </div>
                          </div>
                        );
                    })}
                  </div>
                </div>

                <div className="gauge-charts mt-5 pt3">
                  {segment?.average_customer_sentiment != null ? (
                    <div title="Average Sentiment" className="sentiment-gauge">
                      <div
                        className="text-center mb-1 gauge-value"
                        style={{ color: setColor('sentiment', segment.average_customer_sentiment) }}
                      >
                        {segment.average_customer_sentiment.toFixed(2)}
                      </div>
                      <div className="d-flex justify-content-center mb-3">
                        <GaugeChart
                          colors={Object.values(SentimentColors)}
                          response={{
                            data: {
                              data: [{ value: (segment?.average_customer_sentiment + 5) / 10 }],
                              isPercentage: true,
                            },
                          }}
                          {...gaugeDimensions}
                        ></GaugeChart>
                      </div>
                    </div>
                  ) : null}

                  {segment?.avg_cumulative_effort != null ? (
                    <div title="Cumulative Effort" className="effort-gauge">
                      <div
                        className="text-center mb-1 gauge-value"
                        style={{ color: setColor('effort', segment.avg_cumulative_effort) }}
                      >
                        {segment?.avg_cumulative_effort.toFixed(2)}
                      </div>
                      <div className="d-flex justify-content-center">
                        <GaugeChart
                          colors={Object.values(EffortColors)}
                          response={{
                            data: {
                              data: [{ value: segment?.avg_cumulative_effort / 5 }],
                              isPercentage: true,
                            },
                          }}
                          {...gaugeDimensions}
                        ></GaugeChart>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChatSegmentation;
