import './NumberInput.scss';
import React, { useRef } from 'react';
import { appAssets } from 'constants/assets';
import { TrendOptions } from 'core-ui/SelectTrendInput/SelectTrendInput';
export interface NumberInputProps {
  min: number;
  max: number;
  selectCount: any;
  selectedChart: TrendOptions | undefined;
  label: string;
}

const NumberInput: React.FC<NumberInputProps> = ({
  min,
  max,
  selectCount,
  selectedChart,
  label,
}) => {
  const UP = appAssets.icons.UP_ARROW;
  const numberRef = useRef<any>(null);
  const setCountValueLocal = () => {
    if (selectedChart?.Trend.SelectedValue && selectedChart.Trend.SelectedValue > max) return max;
    if (selectedChart?.Trend.SelectedValue && selectedChart.Trend.SelectedValue < min) return min;
    return selectedChart?.Trend.SelectedValue;
  };

  const valueHandler = (up = false) => {
    let numberValue = Number(numberRef.current.value);
    if (up && numberValue < max) {
      numberValue += 1;
      selectCount({ target: { value: numberValue } });
    }
    if (!up && numberValue > min) {
      numberValue -= 1;
      selectCount({ target: { value: numberValue } });
    }
  };

  return (
    <div className="diy-input-container">
      <div className="diy-material-numberfield" style={{ borderLeft: 0 }}>
        <input
          ref={numberRef}
          value={setCountValueLocal()}
          className="diy-input diy-input-number"
          type="number"
          min={min}
          max={max}
          onChange={selectCount}
        />
        <div className="diy-ctrl-container">
          <UP
            className="diy-up-icon"
            onClick={() => {
              valueHandler(true);
            }}
            color="#666666"
          />
          <UP
            className="diy-down-icon"
            onClick={() => {
              valueHandler(false);
            }}
            color="#666666"
          />
        </div>
      </div>

      <label className="diy-label select">{label}</label>
    </div>
  );
};

export default NumberInput;
