import React from 'react';
import Tippy from '@tippyjs/react';
import { appAssets } from 'constants/assets';

interface DataValidationProps {
  showWarning: boolean;
  warningValidData: boolean;
}

const DataValidation: React.FC<DataValidationProps> = ({ showWarning, warningValidData }) => {
  const SystemAlertIcon = appAssets.icons.SYSTEM_ALERT;
  const warningText = 'Inconsistencies found in data. See Console for details.';
  const isMockText = 'Modified widgets will show real data when dashboard is saved.';

  return (
    <>
      {showWarning || warningValidData ? (
        <Tippy
          className="tooltip-container"
          content={
            <div>
              <div className="tooltip-text">{warningValidData ? warningText : isMockText}</div>
            </div>
          }
        >
          <SystemAlertIcon />
        </Tippy>
      ) : null}
    </>
  );
};

export default DataValidation;
