import { DIYChartOption } from 'constants/chart-selector';
import { Widget } from 'models/WidgetModel';
import { DropDownValue } from './model';

export const defaultConfig = {
  i: '',
  x: 0,
  y: 0,
  w: 0,
  h: 0,
  static: false,
};

export const dropDownValue: DropDownValue = {
  value: '',
  label: '',
};

export const defaultOptions = {
  showTitle: true,
  showSubtitle: false,
  showActions: false,
  showCard: true,
  actions: [],
};

// eslint-disable-next-line prettier/prettier
export const defaultSelectedChartValues: DIYChartOption | null = null;

export const defaultWidget: Widget = {
  alias: '',
  description: '',
  displayName: '',
  dataSourceTypes: [],
  url: '',
  config: defaultConfig,
  payload: {},
  image: '',
  options: defaultOptions,
  viewName: '',
  isCustom: true,
  viewId: '',
  colors: [],
};

export const virtualWidget = {
  w: 2,
  h: 2,
  x: 0,
  y: 0,
};
