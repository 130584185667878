import React from 'react';
import './CardTitle.scss';
import Tippy from '@tippyjs/react';
import DataValidation from 'components/DataValidation/DataValidation';

interface CardTitleProps {
  displayName: string;
  viewName: string;
  showWarning: boolean;
  isMetricWidget: boolean;
  warningValidData: boolean;
  isActions: boolean;
}

const BoxTitle: React.FC<CardTitleProps> = ({
  displayName,
  viewName,
  showWarning,
  isMetricWidget,
  warningValidData,
  isActions,
}) => {
  const tooltipTitle = displayName + (viewName?.length > 0 ? ` (${viewName})` : '');
  return (
    <div className={`pck-card-title-container ${isActions ? 'pck-actions' : null}`}>
      <Tippy
        content={
          <div>
            <div className="tooltip-title">{tooltipTitle}</div>
          </div>
        }
      >
        <div className={`pck-card-title ${isMetricWidget ? 'metric-headers' : null}`}>
          <span>{displayName}</span>
        </div>
      </Tippy>
      <DataValidation showWarning={showWarning} warningValidData={warningValidData} />
    </div>
  );
};

export default BoxTitle;
