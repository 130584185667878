import React from 'react';
import { HierarchyFiltersWithProviders } from '@cx/ui-components';
import { useStore as useGlobalStore } from 'store/useGlobalStore';
import { getFilteringObject } from 'helpers/utilities';
import { getWidgetFromSessionStorage } from 'helpers/sessionStorageHandler';
import { Widget } from 'models/WidgetModel';

const HierarchyFilters: React.FC<any> = ({
  onSetHierarchyFilters,
  selectedHierarchyValues,
  id,
}) => {
  const viewId = useGlobalStore((state) => state.configDashboard.viewId);
  const workspace = useGlobalStore((state) => state.configDashboard.workspace);

  const cx2Conf = window.cx2?.env || {};

  const widget: Widget = getWidgetFromSessionStorage(id);

  const selectedFilters = getFilteringObject(
    widget.customWidgetConfig?.Filters?.CurrentSelection?.HierarchyFilters
  );

  const stateValues = getFilteringObject(selectedHierarchyValues);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <HierarchyFiltersWithProviders
      apiUrl={cx2Conf.cxApiUrl || ''}
      //Pass the workspace id and view id
      WorkspaceId={workspace ? workspace.replace(/"/g, "'") : ''}
      ViewId={viewId}
      onHierarchyFilterChange={(filter: any) => {
        onSetHierarchyFilters(filter);
      }}
      initialState={{
        hierarchy: {
          data: {
            SelectedFilters: selectedFilters || stateValues,
          },
        },
      }}
    />
  );
};

export default HierarchyFilters;
