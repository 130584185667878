import React from 'react';
import { Icon } from '@cx/ui';
import { ControllerName } from 'types';

export const ICON_NAME_BY_CONTROLLERNAME = {
  [ControllerName.AdHocExports]: 'Resources',
  [ControllerName.ClientIssueExperienced]: 'Dashboard',
  [ControllerName.ClosedLoopTool]: 'Ideas',
  [ControllerName.CoachingCards]: 'Resources',
  [ControllerName.CoachingHistory]: 'arrow-trend-up',
  [ControllerName.CoachingTool]: 'arrow-trend-up',
  [ControllerName.CorporatePulse]: 'Dashboard',
  [ControllerName.DIYDashboard]: 'Dashboard',
  [ControllerName.ExploreWithELLIE]: 'Dashboard',
  [ControllerName.FrontlineDashboard]: 'Dashboard',
  [ControllerName.IndividualCoaching]: 'arrow-trend-up',
  [ControllerName.IndividualSurvey]: 'Dashboard',
  [ControllerName.IndividualSurveyTable]: 'Dashboard',
  [ControllerName.KeywordSummary]: 'TextAnalytics',
  [ControllerName.LoyaltyEffortDashboard]: 'Dashboard',
  [ControllerName.ManagementScorecard]: 'Dashboard',
  [ControllerName.OuterLoopCreateRecord]: 'Dashboard',
  [ControllerName.OuterLoopSnapshot]: 'ClosedLoop',
  [ControllerName.OverallRep]: 'Performance',
  [ControllerName.ProjectCustomerSearch]: 'Dashboard',
  [ControllerName.QuestionLevelAggregate]: 'Dashboard',
  [ControllerName.QuestionLevelAggregateOther]: 'Dashboard',
  [ControllerName.ReportUsage]: 'Dashboard',
  [ControllerName.SOSWorkflow]: 'ClosedLoop',
  [ControllerName.TextMiningEmergingTrends]: 'TextAnalytics',
  [ControllerName.TextMiningFrequency]: 'TextAnalytics',
  [ControllerName.TextMiningWordCloud]: 'TextAnalytics',
  [ControllerName.TierPerformance]: 'Survey',
  [ControllerName.TierPerformanceChart]: 'Dashboard',
  [ControllerName.UDTopFocusAreas]: 'Dashboard',
  [ControllerName.UnstructuredData]: 'Dashboard',
  [ControllerName.ScoreCard]: 'Dashboard',
  [ControllerName.TextMiningBubbleChart]: 'TextAnalytics',
  [ControllerName.PDFViewer]: 'archive',
};

export function IconByControllerName({
  controllerName,
  size = 16,
}: {
  controllerName: string;
  size?: number;
}) {
  return ICON_NAME_BY_CONTROLLERNAME[
    controllerName as keyof typeof ICON_NAME_BY_CONTROLLERNAME
  ] ? (
    <Icon
      name={
        ICON_NAME_BY_CONTROLLERNAME[
          controllerName as keyof typeof ICON_NAME_BY_CONTROLLERNAME
        ]
      }
      size={size}
    />
  ) : null;
}
