import React from 'react';

export interface OptionCheckboxProps {
  option: any;
  onChangeHandler: any;
}

const optionCheckbox: React.FC<OptionCheckboxProps> = ({ option, onChangeHandler }) => {
  return (
    <>
      <div className="option-item">
        <span className="toggler-label">{option.displayName}</span>
        <label className="toggler-wrapper">
          <input
            type="checkbox"
            name={option.actionName}
            defaultChecked={option.isChecked}
            onChange={onChangeHandler}
          />
          <div className="toggler-slider">
            <div className="toggler-knob"></div>
          </div>
        </label>
      </div>
    </>
  );
};

export default optionCheckbox;
