import HorizontalBarChart from './Charts/HorizontalBarChart/HorizontalBarChartBase';
import HorizontalSingleStackedBarChart from './Charts/HorizontalSingleStackedBarChart/HorizontalSingleStackedBarChart';
import PieChart from './Charts/PieChart/PieChart';
import StackedBarChart from './Charts/StackedBarChart/StackedBarChart';
import VerticalBarChart from './Charts/VerticalBarChart/VerticalBarChart';
import CorporatePulse from './CorporatePulse/CorporatePulse';
import DynamicTable from './DynamicTable/DynamicTable';
import DataGridMetrics from './DataGridMetrics/DataGridMetrics';
import Geomap from './Charts/GeoMap/GeoMap';
import MetricSummary from './MetricSummary/MetricSummary';
import TopBottomFive from './TopBottomFive/TopBottomFive';
import CustomWidget from './CustomWidget/CustomWidget';
import GaugeChart from './Charts/GaugeChart/GaugeChart';
import ChatSegmentation from './Charts/ChatSegmentation/ChatSegmentation';
import IndividualMetric from './IndividualMetric/IndividualMetric';
import TopFive from './TopFive/TopFive';
import TextBlock from './TextBlock/TextBlock';
import TopCategories from './Charts/TopCategories/TopCategories';
import BulletChart from './Charts/BulletChart/BulletChart';
import BiggestMovers from './Charts/BiggestMovers/BiggestMovers';
import MultipleSummaryMetrics from './MultipleSummaryMetrics/MultipleSummaryMetrics';
import CometChart from './Charts/CometChart/CometChart';
import TextAnalytics from './TextAnalytics/TextAnalytics';

export const library = {
  VERTICAL_BAR_CHART: 'VerticalBarChart',
  HORIZONTAL_BAR_CHART: 'HorizontalBarChart',
  HORIZONTAL_SINGLE_STACKED_BAR_CHART: 'HorizontalSingleStackedBarChart',
  PIE_CHART: 'PieChart',
  STACKED_BAR_CHART: 'StackedBarChart',
  CORPORATE_PULSE: 'CorporatePulse',
  DYNAMIC_TABLE: 'DynamicTable',
  DATA_GRID_METRICS: 'DataGridMetrics',
  GEOMAP: 'Geomap',
  METRIC_SUMMARY: 'MetricSummary',
  TOP_BOTTOM_FIVE: 'TopBottomFive',
  CUSTOM_WIDGET: 'CustomWidget',
  QUERY_WIDGET: 'QueryWidget',
  GAUGE_CHART: 'GaugeChart',
  CHAT_SEGMENTATION: 'ChatSegmentation',
  TOP_CATEGORIES: 'TopCategories',
  INDIVIDUAL_METRIC: 'IndividualMetric',
  TOP_FIVE: 'TopFive',
  TEXT_BLOCK: 'TextBlock',
  BULLET_CHART: 'BulletChart',
  BIGGEST_MOVERS: 'BiggestMovers',
  MULTIPLE_SUMMARY_METRICS: 'MultipleSummaryMetrics',
  COMET_CHART: 'CometChart',
  TEXT_ANALYTICS: 'TextAnalytics',
};

const libraryComponents: { [key: string]: React.FC<any> } = {};
libraryComponents[library.VERTICAL_BAR_CHART] = VerticalBarChart;
libraryComponents[library.STACKED_BAR_CHART] = StackedBarChart;
libraryComponents[library.PIE_CHART] = PieChart;
libraryComponents[library.HORIZONTAL_BAR_CHART] = HorizontalBarChart;
libraryComponents[library.DYNAMIC_TABLE] = DynamicTable;
libraryComponents[library.DATA_GRID_METRICS] = DataGridMetrics;
libraryComponents[library.CORPORATE_PULSE] = CorporatePulse;
libraryComponents[library.HORIZONTAL_SINGLE_STACKED_BAR_CHART] = HorizontalSingleStackedBarChart;
libraryComponents[library.GEOMAP] = Geomap;
libraryComponents[library.METRIC_SUMMARY] = MetricSummary;
libraryComponents[library.TOP_BOTTOM_FIVE] = TopBottomFive;
libraryComponents[library.CUSTOM_WIDGET] = CustomWidget;
libraryComponents[library.GAUGE_CHART] = GaugeChart;
libraryComponents[library.CHAT_SEGMENTATION] = ChatSegmentation;
libraryComponents[library.TOP_CATEGORIES] = TopCategories;
libraryComponents[library.INDIVIDUAL_METRIC] = IndividualMetric;
libraryComponents[library.TOP_FIVE] = TopFive;
libraryComponents[library.TEXT_BLOCK] = TextBlock;
libraryComponents[library.BULLET_CHART] = BulletChart;
libraryComponents[library.BIGGEST_MOVERS] = BiggestMovers;
libraryComponents[library.MULTIPLE_SUMMARY_METRICS] = MultipleSummaryMetrics;
libraryComponents[library.COMET_CHART] = CometChart;
libraryComponents[library.TEXT_ANALYTICS] = TextAnalytics;

export default libraryComponents;
