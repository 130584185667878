/**
 * Utilities for setting a state portion in the store.
 * An state portion is a property of the state object that is
 * composed by data and ui properties.
 *
 * Theses utilities make it easier to set the data or ui properties in sagas.
 */
import { setState } from '@cx/zustand-saga';

type CallBackOrState<T> = T extends () => any
  ? T | (() => T)
  : T extends () => T
    ? never
    : T | (() => T);

/**
 * @description Set the data or ui property of a state portion.
 * @param key Key of the state portion to set.
 * @returns Setter for the whole state portion.
 * @example
 * type State = {
 *  dashboards: {
 *    data: any,
 *    ui: {
 *      loading: boolean, error: any
 *    }
 *
 * }}
 * const setFullStatePortion = setFullState<state>('dashboards')
 * setFullStatePortion({ data: { value: 'test' }, ui: { loading: true } })
 */

export function setFullState<S>(key: keyof S) {
  return function* <T>(callBackOrState: CallBackOrState<T>) {
    yield setState((state: S) => ({
      ...state,
      ...(state?.[key]
        ? {
            [key]: {
              ...state?.[key],
              ...(typeof callBackOrState === 'function'
                ? callBackOrState(state?.[key] || {})
                : callBackOrState),
            },
          }
        : {}),
    }));
  };
}

/**
 * @description Set the data or ui property of a state portion.
 * @param key Key of the state portion to set.
 * @returns Setter for the data property of a state portion.
 * @example
 * type State = {
 *  dashboards: {
 *    data: any,
 *    ui: {
 *      loading: boolean, error: any
 *    }
 *
 * }}
 * const setDataStatePortion = setData<State>('dashboards')
 * setDataStatePortion({ value: 'test' })
 */
export function setData<S>(key: keyof S) {
  return function* <T>(callBackOrState: CallBackOrState<T>) {
    yield setState((state: S) => ({
      ...state,
      [key]: {
        ...(state?.[key] || {}),
        data: {
          ...((state?.[key] as any)?.data || {}),
          ...(typeof callBackOrState === 'function'
            ? callBackOrState((state?.[key] as any)?.data || {})
            : callBackOrState),
        },
      },
    }));
  };
}

/**
 * @description Set the ui property of a state portion.
 * @param key Key of the state portion to set.
 * @returns Setter for the ui property of a state portion.
 * @example
 * type State = {
 *  dashboards: {
 *    data: any,
 *    ui: {
 *      loading: boolean, error: any
 *    }
 *
 * }}
 * const setUiStatePortion = setUi<State>('dashboards')
 * setUiStatePortion({ loading: true })
 */
export function setUi<S>(key: keyof S) {
  return function* <T>(callBackOrState: CallBackOrState<T>) {
    yield setState((state: S) => ({
      ...state,
      [key]: {
        ...(state?.[key] || {}),
        ui: {
          ...((state?.[key] as any)?.ui || {}),
          ...(typeof callBackOrState === 'function'
            ? callBackOrState((state?.[key] as any)?.ui || {})
            : callBackOrState),
        },
      },
    }));
  };
}

/**
 * @description Given a state portion key, returns the setUi, setData and setFullState methods.
 * @param key Key of the state portion to set.
 * @returns setUi, setData and setFullState methods.
 * @example
 * type State = {
 *  dashboards: {
 *    data: any,
 *    ui: {
 *      loading: boolean, error: any
 *    }
 *
 * }}
 * const methods = getSetStateMethods<State>('dashboards')
 * methods.setFullStatePortion({ data: { value: 'test' }, ui: { loading: true } })
 */

const getSetStateMethods = <S>(key: keyof S) => ({
  setDataState: setData(key),
  setUiState: setUi(key),
  setFullState: setFullState(key),
});

export default getSetStateMethods;
