(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@cx/login"), require("@cx/ui"), require("@cx/zustand-saga"), require("@emotion/react"), require("@emotion/styled"), require("react"), require("react-dom"), require("redux-saga-combine-watchers"), require("zustand"));
	else if(typeof define === 'function' && define.amd)
		define(["@cx/login", "@cx/ui", "@cx/zustand-saga", "@emotion/react", "@emotion/styled", "react", "react-dom", "redux-saga-combine-watchers", "zustand"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("@cx/login"), require("@cx/ui"), require("@cx/zustand-saga"), require("@emotion/react"), require("@emotion/styled"), require("react"), require("react-dom"), require("redux-saga-combine-watchers"), require("zustand")) : factory(root["@cx/login"], root["@cx/ui"], root["@cx/zustand-saga"], root["@emotion/react"], root["@emotion/styled"], root["react"], root["react-dom"], root["redux-saga-combine-watchers"], root["zustand"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, (__WEBPACK_EXTERNAL_MODULE__9040__, __WEBPACK_EXTERNAL_MODULE__1762__, __WEBPACK_EXTERNAL_MODULE__8814__, __WEBPACK_EXTERNAL_MODULE__1472__, __WEBPACK_EXTERNAL_MODULE__7464__, __WEBPACK_EXTERNAL_MODULE__5932__, __WEBPACK_EXTERNAL_MODULE__94__, __WEBPACK_EXTERNAL_MODULE__5792__, __WEBPACK_EXTERNAL_MODULE__4596__) => {
return 