import React from 'react';
import { styled } from 'theme';

export type AddAdvancedFilterButtonProps = {
  onClick?: (event: any) => void;
  disabled?: boolean;
  selectedFilters?: any[];
  label?: string;
} & React.HTMLAttributes<HTMLButtonElement>;

function AddAdvancedFilterButton({
  className = '',
  onClick = () => null,
  disabled = false,
  selectedFilters,
  label,
}: AddAdvancedFilterButtonProps) {
  return (
    <button
      data-testid="add-advanced-filter-button"
      disabled={disabled}
      type="button"
      className={className}
      onClick={onClick}
    >
      <span className="add-icon">+</span>
      <div>
        <span className="cx2-lang">{label || 'Add Advanced Filter'} </span>
        <span>
          {selectedFilters?.length > 0 ? `(${selectedFilters?.length})` : ''}
        </span>
      </div>
    </button>
  );
}

export default styled(AddAdvancedFilterButton)((props) => ({
  display: 'flex',
  alignItems: 'center',
  background: 'transparent',
  ...props.theme.typography.smallMedium,
  opacity: props.disabled ? 0.5 : 1,
  border: 'none',
  padding: 0,
  cursor: props.disabled ? 'not-allowed' : 'pointer',
  '.add-icon': {
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: props.theme.spacing(1),
    height: props.theme.spacing(1),
    background: props.theme.palette.primary.main,
    color: props.theme.palette.onPrimary.main,
    borderRadius: '50%',
    marginRight: props.theme.spacing(0.5),
  },
}));
