export interface ColorInputValue {
  index: number;
  color: string;
  textValue: string;
}
export interface ThemeInputValue {
  theme: string[];
  textValue: string;
}

export const colorInitialState: ColorInputValue = {
  index: 0,
  color: '#666666',
  textValue: '',
};

export const themeInitialState: ThemeInputValue = {
  theme: ['#E5E6E5', '#CCCCCC', '#999999', '#666666'],
  textValue: '',
};

export const colorFamilyInitialState: ThemeInputValue = {
  theme: [
    '#D30030',
    '#FFC000',
    '#000565',
    '#242C33',
    '#E64564',
    '#FFDC4D',
    '#2E3399',
    '#485159',
    '#FA7D9A',
    '#FFE780',
    '#B8BAE6',
    '#798896',
    '#FFCCD8',
    '#FFF6CD',
    '#666BCC',
    '#C0C6CC',
  ],
  textValue: '',
};
