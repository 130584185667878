import React from 'react';
import { BaseWidget } from 'models/WidgetModel';
import HorizontalBarChart from './HorizontalBar';
import HorizontalBarChartSentiment from './HorizontalBarSentiment';

interface HorizontalChartProps extends BaseWidget {
  colors: string[];
  hasSentiment?: boolean;
  options: any;
}

const HorizontalBarChartBase: React.FC<HorizontalChartProps> = ({
  colors,
  response,
  options,
  ...props
}) => {
  const { hasSentiment } = props;
  return (
    <>
      {hasSentiment ? (
        <HorizontalBarChartSentiment colors={colors} response={response} {...props} />
      ) : (
        <HorizontalBarChart colors={colors} response={response} options={options} {...props} />
      )}
    </>
  );
};

export default HorizontalBarChartBase;
