import { useState, useEffect } from 'react';
import { TOROGOZ_API } from 'constants/urls';
import { AxiosParams } from 'models/AxiosModel';

export const useAxios = (): any => {
  const [response, setResponse] = useState<any>();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [controller, setController] = useState<AbortController>();

  const axiosFetch = async (params: AxiosParams) => {
    const { axiosInstance, method, url, requestConfig = {} } = params;

    axiosInstance.defaults.baseURL =
      requestConfig?.data?.external && !(url.indexOf('data/single-endpoint') >= 0)
        ? ''
        : TOROGOZ_API;
    setResponse(undefined);
    try {
      setLoading(true);
      const ctrl: AbortController = new AbortController();
      setController(ctrl);
      const res = await axiosInstance[method](url, {
        ...requestConfig,
        signal: ctrl.signal,
      });
      if (res?.data) {
        setResponse(() => res?.data);
      }
    } catch (err: any) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (response) setLoading(false);
  }, [response]);

  useEffect(() => {
    return () => controller && controller.abort();
  }, [controller]);
  return { response, axiosFetch, error, loading };
};
