import React, { FC, useEffect } from 'react';
import './BiggestMovers.scss';
import { BaseWidget } from '../../../models/WidgetModel';
import { appAssets } from '../../../constants/assets';

interface BiggestMoversProps extends BaseWidget {
  colors: string[];
}

interface CategoryItem {
  l1: string;
  l2: string;
  value: number;
}

interface BiggestMoversResponse {
  volume: CategoryItem;
  sentiment: CategoryItem;
}

const BiggestMovers: FC<BiggestMoversProps> = ({ response }) => {
  const [data, setData] = React.useState<BiggestMoversResponse>();
  useEffect(() => {
    if (response && response?.data?.data?.length) {
      setData(response.data.data[0]);
    }
  }, [response]);

  const showIcon = (delta: number) => {
    const TrendingFlat = appAssets.icons.TRENDING_FLAT;
    const TrendingUp = appAssets.icons.TRENDING_UP;
    const TrendingDown = appAssets.icons.TRENDING_DOWN;

    if (delta == 0 || delta == null) {
      return <TrendingFlat className="biggest-yellow-arrow"></TrendingFlat>;
    } else if (delta > 0) {
      return <TrendingUp className="biggest-green-arrow"></TrendingUp>;
    } else if (delta < 0) {
      return <TrendingDown className="biggest-red-arrow"></TrendingDown>;
    }
  };

  const showPositive = (delta: number) => {
    if (delta > 0) {
      return '+';
    }
  };

  const getColorText = (delta: number) => {
    if (delta > 0) {
      return 'biggest-greenText';
    } else if (delta < 0) {
      return 'biggest-redText';
    } else {
      return 'biggest-yellowText';
    }
  };

  return (
    <>
      <div className="biggest-movers">
        {data ? (
          <>
            <div className="top-cat">
              <div className="cat-text">
                <div className="cx-lang-translate cat-group">VOLUME</div>
                <div className="biggest-labels">
                  <div className="biggest-l2label cx-lang-translate">{data?.volume?.l2}</div>
                  <div className="biggest-labels cx-lang-translate">{data?.volume?.l1}</div>
                </div>
              </div>
              <div className="cat-text">
                <div className="biggest-dashbd-exec-delta-text">
                  <span className={getColorText(data?.volume?.value)}>
                    {showPositive(data?.volume?.value)}
                    {data?.volume?.value}%{showIcon(data?.volume?.value)}
                  </span>
                </div>
              </div>
            </div>
            <div className="top-cat">
              <div className="cat-text">
                <div className="cx-lang-translate cat-group">SENTIMENT SCORE</div>
                <div className="biggest-labels">
                  <div className="biggest-l2label cx-lang-translate">{data?.sentiment?.l2}</div>
                  <div className="biggest-labels cx-lang-translate">{data?.sentiment?.l1}</div>
                </div>
              </div>
              <div className="cat-text">
                <div className="biggest-dashbd-exec-delta-text">
                  <span className={getColorText(data?.sentiment?.value)}>
                    {showPositive(data?.sentiment?.value)}
                    {data?.sentiment?.value} {showIcon(data?.sentiment?.value)}
                  </span>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default BiggestMovers;
