import { boldNumbersInString } from 'core-ui/BoldNumberInString/boldNumbersInString';
import React from 'react';
import './SummaryStats.scss';

interface SummaryStatsProps {
  stats: any;
  baseSize: string;
}

const SummaryStats: React.FC<SummaryStatsProps> = (props) => {
  const { stats, baseSize } = props;
  const statsValues: Array<any> = stats?.value ? stats?.value : stats?.Value;
  return (
    <div className="statsHeader">
      <div className="summarySection">
        <div className="QLABaseSize">
          <span className="base-size-span">
            <span dangerouslySetInnerHTML={{ __html: boldNumbersInString(baseSize) }}></span>
          </span>
        </div>
      </div>
      <div className="summaryStats">
        {statsValues?.map((item, index) => (
          <div key={index} className="cardboxBorder">
            <div className="smallTextFormatTiles">{item.Label}</div>
            <div className="largeValueTextBlue">{item.x}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SummaryStats;
