import React, { FC } from 'react';
import { appAssets } from 'constants/assets';
import './FootersChart.scss';

interface FootersChartProps {
  baseSize: number;
}

const FootersChart: FC<FootersChartProps> = ({ baseSize }) => {
  const UsersIcon = appAssets.icons.USERS;

  return (
    <>
      {baseSize ? (
        <div className="group-pie-footer">
          <div className="group-icon">
            <UsersIcon />
          </div>
          <div className="group-icon-text">{baseSize.toLocaleString()}</div>
        </div>
      ) : null}
    </>
  );
};

export default FootersChart;
