import { parse, format } from 'date-fns';
// return month name from string date with format YYYY-mm-dd HH:MM:SS
export const getMonthName = (date: string | number, showYear: boolean) => {
  // This regex expression is used to verify if the date comes with the format 'MM yy'
  // Example: 'May 22' or 'April 22'
  const reg =
    /(\b\d{1,2}\D{0,3})?\b(?:Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|(Nov|Dec)(?:ember)?)\D?(\d{1,2}\D?)?\D?((19[7-9]\d|20\d{2})|\d{2})$/;
  const matches = reg.exec(date.toString());

  if (matches && typeof date === 'string') {
    const dateParts = date.split(' ');
    if (showYear && dateParts.length > 1) {
      return date;
    }

    return dateParts[0];
  }

  // Check if the date is a valid date or return the value as it is

  const dateObj = new Date(date);
  if (isValidDate(dateObj)) {
    const dt = dateObj.toLocaleString('en-us', {
      month: 'short',
      ...(showYear ? { year: '2-digit' } : {}),
    });
    return dt;
  } else {
    return date;
  }
};

export const isValidDate = (date: Date) => {
  return date instanceof Date && !isNaN(date.getTime());
};

export const secondDuration = (value: number) => {
  const durationInSeconds = Math.floor(value);
  const durationInMinutes = Math.floor(value / 60);
  const durationInHours = Math.floor(value / 3600);

  const hours = durationInHours % 24;
  const minutes = durationInMinutes % 60;
  const seconds = durationInSeconds % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;
};

// format item : yyyy-mm-ddThh:mm:ss
// output: mm/dd - mm/dd
export const getWeekRange = (item: string | number) => {
  const date = new Date(item);
  const day = date.getDay();
  const firstDay = new Date(date.getTime() - (day - 1) * 86400000);
  const lastDay = new Date(date.getTime() + (7 - day) * 86400000);
  const firstDayMonth = firstDay.getMonth() + 1;
  const firstDayDate = firstDay.getDate();
  const lastDayMonth = lastDay.getMonth() + 1;
  const lastDayDate = lastDay.getDate();
  return `${firstDayMonth}/${firstDayDate} - ${lastDayMonth}/${lastDayDate}`;
};

// format item : yyyy-mm-ddThh:mm:ss
// output: MM-yyyy
export const getMonthlyFormat = (item: string | number, showYear: boolean) => {
  const date = new Date(item);
  return `${date.toLocaleDateString([], {
    month: 'short',
    ...(showYear ? { year: '2-digit' } : {}),
  })}`;
};

// format item : yyyy-mm-ddThh:mm:ss
// output: mm/dd
export const getDailyFormat = (item: string | number) => {
  const date = new Date(item);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${month}/${day}`;
};

// format item : yyyy-mm-ddThh:mm:ss
// output: yyyy
export const getYearlyFormat = (item: string | number) => {
  const date = new Date(item);
  const year = date.getFullYear();
  return `${year}`;
};

// format item : yyyy-mm-ddThh:mm:ss
// output: mm/dd/yyyy
export const getFullFormat = (item: string | number) => {
  const date = new Date(item);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

const getQuarterly = (item: string | number) => {
  return item;
};

interface DateFormatter {
  [key: string]: any;
}

export const formatterList: DateFormatter = {
  Days: getDailyFormat,
  Weekly: getWeekRange,
  Weeks: getWeekRange,
  Monthly: getMonthlyFormat,
  Yearly: getYearlyFormat,
  Year: getYearlyFormat,
  Quarterly: getQuarterly,
};

export const getFormattedDate = (item: string | number, type: string, showYear = false) => {
  // if type is yearly will return value as it is
  if (type === 'Yearly') {
    return item;
  } else if (type === 'Monthly' && showYear && isShortMonthShortYearDate(item.toString())) {
    return item;
  } else {
    const date = new Date(item);

    if (isValidDate(date) && type in formatterList) {
      return type === 'Monthly' ? formatterList[type](item, showYear) : formatterList[type](item);
    }
    return item;
  }
};

export const isShortMonthShortYearDate = (inputDate: string) => {
  try {
    // Parse the input date string into a Date object
    const parsedDate = parse(inputDate, 'MMM dd', new Date());

    // Format the parsed date to match the desired format
    const formattedDate = format(parsedDate, 'MMM dd');

    // Compare the formatted date with the input string
    return formattedDate === inputDate;
  } catch (error) {
    // Handle parsing errors, e.g., invalid date format
    return false;
  }
};
