import React from 'react';
import Tippy from '@tippyjs/react';
import { appAssets } from 'constants/assets';
import './DataTimeframe.scss';
import { library } from 'components';

export const chartsWithTimeframe = [
  library.PIE_CHART,
  library.HORIZONTAL_BAR_CHART,
  library.HORIZONTAL_SINGLE_STACKED_BAR_CHART,
  library.DYNAMIC_TABLE,
  library.BULLET_CHART,
];

interface Props {
  timePeriodType: string;
  hierarchyFiltersValues: any;
  advancedFiltersValues: any;
  hasTrend: boolean;
  hasQuery: boolean;
}

// TODO - Add Props when actual timeframe value is passed from filters

const DataTimeframe: React.FC<Props> = ({
  timePeriodType,
  hierarchyFiltersValues,
  advancedFiltersValues,
  hasTrend = false,
  hasQuery = false,
}) => {
  let DataTimeframeIcon = appAssets.icons.FILTERNOTAPPLIED;
  let timeFrameText = '';
  let showTimeFrame = true;
  const tooltipLabel = [];

  if (hasTrend || hasQuery) {
    showTimeFrame = false;
  } else if (timePeriodType == '') {
    timeFrameText = 'Month to Date';
  } else {
    timeFrameText = timePeriodType;
    DataTimeframeIcon = appAssets.icons.FILTERAPPLIED;
  }

  if (showTimeFrame) tooltipLabel.push(timeFrameText);

  // Add selected hierarchy filters into tooltipLabel
  if (hierarchyFiltersValues) {
    hierarchyFiltersValues.map((item: any) => tooltipLabel.push(item));
    DataTimeframeIcon = appAssets.icons.FILTERAPPLIED;
  }

  if (advancedFiltersValues) {
    advancedFiltersValues.map((item: any) => tooltipLabel.push(item));
    DataTimeframeIcon = appAssets.icons.FILTERAPPLIED;
  }

  return (
    <>
      {tooltipLabel !== undefined && tooltipLabel.length > 0 ? (
        <Tippy
          className="tooltip-container"
          content={
            <div>
              <div className="tooltip-text">
                {tooltipLabel.map((str: any, index: any) => (
                  <p key={index}>{str}</p>
                ))}
              </div>
            </div>
          }
        >
          <div className="calendar">
            <DataTimeframeIcon className="calendar-icon" />
          </div>
        </Tippy>
      ) : (
        <></>
      )}
    </>
  );
};

export default DataTimeframe;
