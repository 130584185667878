import { MultipleMetrics } from 'models/MultipleSummaryMetrics';
import { BaseWidget } from 'models/WidgetModel';
import React, { useEffect, useRef, useState } from 'react';
import './MultipleSummaryMetrics.scss';

const MultipleSummaryMetrics: React.FC<BaseWidget> = ({ response }) => {
  const [metrics, setMetrics] = useState<MultipleMetrics[]>([]);
  const mounted = useRef(true);

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (response) {
      mounted.current = true;
      if (response?.data?.data?.length && mounted.current) {
        setMetrics(response.data.data);
      }
    }
  }, [response]);

  return (
    <>
      {metrics?.length ? (
        <div className="multiple-summary-metrics">
          {metrics.map((metric: MultipleMetrics, index) => {
            if (index === 0) {
              // Design for the Consolidated metric
              return (
                <div className="metric-child" key={index}>
                  <div className="metric-header">
                    <span className="metric-title">{metric.title}</span>
                  </div>
                  <div className="metric-body">
                    <span className="metric-value">{metric.value}</span>
                    <span className="metric-unit">{metric.unit}</span>
                  </div>
                </div>
              );
            } else {
              // Design for the Breakdown metrics
              return (
                <div className="metric-child" key={index}>
                  <span className="metric-value">{metric.value}</span>
                  <span className="metric-breakdown">{metric.title}</span>
                </div>
              );
            }
          })}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default MultipleSummaryMetrics;
