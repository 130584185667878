import { HierarchyFilter, AppAction, HierarchyCallBack } from 'types';

export const INIT_HIERARCHY_FILTER = 'filter-bar/init-hierarchy-filter';
export const SELECT_HIERARCHY_FILTER = 'filter-bar/select-hierarchy-filter';
export const UPDATE_HIERARCHY_FILTER = 'filter-bar/update-hierarchy-filter';
export const UPDATE_HIERARCHY_FILTER_SUCCESS =
  'filter-bar/update-hierarchy-filter/success';
export const UPDATE_HIERARCHY_FILTER_ERROR =
  'filter-bar/update-hierarchy-filter/success';

export const UPDATE_FILTERS = 'filter-bar/update-filters';
export const UPDATE_FILTERS_SUCCESS = 'filter-bar/update-filters/success';
export const UPDATE_FILTERS_ERROR = 'filter-bar/update-filters/error';

export const REMOVE_HIERARCHY_FILTER_VALUE =
  'filter-bar/remove-hierarchy-filter-value';

export type SelectHierarchyFilterAction = AppAction<{
  filter: HierarchyFilter;
  values: any[];
  callback?: HierarchyCallBack;
}>;

export const selectHierarchyFilter = (
  filter: HierarchyFilter,
  values: any[],
  callback?: HierarchyCallBack
): SelectHierarchyFilterAction => ({
  type: SELECT_HIERARCHY_FILTER,
  filter,
  values,
  callback,
});

export type UpdateFiltersAction = AppAction<{ callback?: HierarchyCallBack }>;

export const updateFilters = (
  callback?: HierarchyCallBack
): UpdateFiltersAction => ({
  type: UPDATE_FILTERS,
  callback,
});

export type UpdateFiltersSuccessAction = AppAction<{
  data: any;
  callback?: HierarchyCallBack;
}>;

export const updateFiltersSuccess = (
  data: any,
  callback?: HierarchyCallBack
): UpdateFiltersSuccessAction => ({
  type: UPDATE_FILTERS_SUCCESS,
  data,
  callback,
});

export type UpdateFiltersErrorAction = AppAction<{
  error: string;
  callback?: HierarchyCallBack;
}>;

export const updateFiltersError = (
  error: string,
  callback?: HierarchyCallBack
): UpdateFiltersErrorAction => ({
  type: UPDATE_FILTERS_ERROR,
  error,
  callback,
});

export type UpdateHierarchyFiltersAction = AppAction<{
  filter: HierarchyFilter;
  callback?: HierarchyCallBack;
}>;

export const updateHierarchyFilter = (
  filter: HierarchyFilter,
  callback?: HierarchyCallBack
) => ({
  type: UPDATE_HIERARCHY_FILTER,
  filter,
  callback,
});

export type InitHierarchyFilterAction = AppAction<{
  callback?: HierarchyCallBack;
}>;

export const initHierarchyFilters = (
  callback?: HierarchyCallBack
): InitHierarchyFilterAction => ({
  type: INIT_HIERARCHY_FILTER,
  callback,
});

type RemoveHierarchyFilterValueActionParams = {
  filter: HierarchyFilter;
  value: any;
  callback?: HierarchyCallBack;
};

export type RemoveHierarchyFilterValueAction =
  AppAction<RemoveHierarchyFilterValueActionParams>;

export const removeHierarchyFilterValue = ({
  filter,
  value,
  callback,
}: RemoveHierarchyFilterValueActionParams): RemoveHierarchyFilterValueAction => ({
  type: REMOVE_HIERARCHY_FILTER_VALUE,
  filter,
  value,
  callback,
});

export const updateHierarchyFilterSuccess = (callback?: HierarchyCallBack) => ({
  type: UPDATE_HIERARCHY_FILTER_SUCCESS,
  callback,
});

export const updateHierarchyFilterError = (
  error: string,
  callback?: HierarchyCallBack
) => ({
  type: UPDATE_HIERARCHY_FILTER_ERROR,
  error,
  callback,
});
