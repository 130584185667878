import React, { useEffect, useState } from 'react';
import './QueryBuilder.scss';
import { Icon } from '@cx/ui';
import { SelectOptions } from 'core-ui/MultipleSelect/MultipleSelect';
import QueryBuilderItem from './QueryItem';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-github';
import Accordion from 'core-ui/accordionDrawer/accordionDrawer';

export interface IQueryData {
  dataCollectionGuid: string;
  query: string;
  customWidgetConfig?: any;
}

export interface IQueryDefinition {
  [key: string]: IQueryData[];
}

interface IQueryBuilderProps {
  customWidget: any;
  availableOptions: SelectOptions[];
  onChange: (IQueryData: IQueryData[]) => void;
}

const QueryBuilder: React.FC<IQueryBuilderProps> = (props) => {
  const { customWidget, availableOptions, onChange } = props;
  const [queryDefinitions, setQueryDefinitions] = useState<IQueryDefinition>({ '0': [] });
  const [canAddQuery, setCanAddQuery] = useState<boolean>(false);
  const [queryConfig, setQueryConfig] = useState<string>('');

  const handleChange = (value: IQueryDefinition) => {
    const newValue = { ...queryDefinitions, ...value };

    setQueryDefinitions(newValue);
  };

  useEffect(() => {
    const newValue = { ...queryDefinitions };

    let isParsed = false;
    let parsedObj = {};
    try {
      parsedObj = JSON.parse(queryConfig);
      isParsed = true;
    } catch (e) {
      return;
    }
    if (isParsed) {
      Object.keys(newValue).forEach((key) => {
        newValue[key].forEach((item) => {
          item.customWidgetConfig = parsedObj;
        });
      });
    }

    onChange(Object.values(newValue).flat());
  }, [queryDefinitions, queryConfig]);

  useEffect(() => {
    handleAddQueryDisabled();
  }, [queryDefinitions]);

  const handleAddQueryDisabled = () => {
    // all queryDefinitions has to have data
    const keys = Object.keys(queryDefinitions);
    let disabled = false;
    keys.forEach((key) => {
      if (
        queryDefinitions[key].length == 0 ||
        queryDefinitions[key].some((x) => x.query === '' || x.dataCollectionGuid === '')
      ) {
        disabled = true;
      }
    });
    setCanAddQuery(!disabled);
  };

  const removeItem = (key: string) => {
    return () => {
      const newQueryDefinitions = { ...queryDefinitions };
      delete newQueryDefinitions[key];
      setQueryDefinitions(newQueryDefinitions);
    };
  };

  const addQueryItem = () => {
    return () => {
      const keys = Object.keys(queryDefinitions);
      const lastKey = keys.length > 0 ? parseInt(keys[keys.length - 1]) : 0;
      setQueryDefinitions((prev) => {
        return { ...prev, [`${lastKey + 1}`]: [] };
      });
    };
  };

  const renderQueryItems = () => {
    const keys = Object.keys(queryDefinitions);
    return keys.map((key) => {
      return (
        <div key={key} className="diy-query-editor">
          <QueryBuilderItem
            onDelete={parseInt(key) > 0 ? removeItem(key) : undefined}
            position={parseInt(key)}
            customWidget={customWidget}
            availableOptions={availableOptions}
            onChange={handleChange}
          />
        </div>
      );
    });
  };

  /**
   * Handle query config change
   * Do nothing if the value is not a valid JSON object
   * @param value
   * @returns
   */
  const handleQueryConfigChange = (value: string) => {
    setQueryConfig(value);
  };

  return (
    <div className="diy-drawer-section">
      <Accordion title="Query config">
        <AceEditor
          mode="javascript"
          theme="github"
          className="diy-query-editor"
          onChange={handleQueryConfigChange}
          value={queryConfig}
          name="query-config"
          setOptions={{
            useWorker: false,
          }}
          height="100px"
          editorProps={{ $blockScrolling: true }}
        />
      </Accordion>
      {renderQueryItems()}
      {canAddQuery && (
        <div className="diy-drawer-icon">
          <Icon name="plus" onClick={addQueryItem()} />
        </div>
      )}
    </div>
  );
};

export default QueryBuilder;
