import React, { useState, useEffect } from 'react';
import { Select, OptionType, type SelectProps } from '@cx/ui';
import TextBox from '@cx/ui/components/TextBox';
import { styled } from 'theme';
import { SelectedAdvancedFilter, SubAttribute } from 'types';
import { changeLabelToFriendlyName } from 'utils';
import { useAppActions } from 'store';
import AttributeChoices, { AttributeChoicesProps } from './AttributeChoices';

export interface QuestionDetailsProps {
  filter: SelectedAdvancedFilter;
  index: number;
  getAttributeChoices: (filter: SelectedAdvancedFilter, index: number) => void;
  onOperationOptionSelected?: (option: OptionType) => void;
  onAttributeChoicesSelected?: (option: OptionType | OptionType[]) => void;
  areAttributesSearchable?: boolean;
  onTextBoxChange?: (value: string) => void;
  selectedFilters: any[];
  className?: string;
  subAttributeOptionSelectProps?: Partial<
    Omit<
      SelectProps,
      'options' | 'value' | 'isClearable' | 'isSearchable' | 'defaultOptions'
    >
  >;
  supportedOperatorsSelectProps?: Partial<
    Omit<
      SelectProps,
      | 'name'
      | 'options'
      | 'value'
      | 'isClearable'
      | 'isSearchable'
      | 'defaultOptions'
    >
  >;
  attributeChoicesSelectProps?: AttributeChoicesProps['selectProps'];
}

function QuestionDetails({
  className = '',
  filter,
  selectedFilters,
  index,
  getAttributeChoices,
  onOperationOptionSelected,
  onAttributeChoicesSelected,
  onTextBoxChange,
  areAttributesSearchable = false,
  subAttributeOptionSelectProps = {},
  supportedOperatorsSelectProps = {},
  attributeChoicesSelectProps = {},
}: QuestionDetailsProps) {
  const { SubAttributes } = filter?.questionDetails || {};
  const [operatiorOptionSelected, setOperatorOptionSelected] = useState(
    filter?.OperatorOption || null
  );
  const { updateAdvancedFilter } = useAppActions();

  const {
    SupportedOperators,
    RequestChoicesFromTheServer,
    SubAttributeLabel,
    SubAttributeName,
    DataType,
  }: SubAttribute = SubAttributes?.[0] || {};

  const subattributeOption = {
    label: SubAttributeLabel,
    name: SubAttributeName,
  };

  const supportedOperatorsOptions = SupportedOperators?.map(
    (value: string) => ({
      value,
      label: changeLabelToFriendlyName({
        label: value,
        DataType,
        SubAttributeName,
      }),
    })
  );

  const handleTextBoxChange = ({ value }: { value: string }) => {
    updateAdvancedFilter(
      {
        ...filter,
        Values: [value],
      },
      index
    );
    onTextBoxChange?.(value);
  };

  const selectAttributeChoices = (params: OptionType | OptionType[]) => {
    updateAdvancedFilter(
      {
        ...filter,
        Values: Array.isArray(params)
          ? params.map(({ value }) => value)
          : [params?.value],
      },
      index
    );
  };

  const handleSelectDefaultAttributeChoices = () => {
    if (!filter?.Values && filter?.questionDetails?.attributeChoices?.Choices) {
      const isMulti = filter?.OperatorOption?.value === 'IN';
      const defaultOption =
        filter?.questionDetails?.attributeChoices?.Choices[0];
      const optionMulti = [
        {
          value: defaultOption?.Value as string,
          label: defaultOption?.Label as string,
        },
      ];
      const value = {
        value: defaultOption?.Value as string,
        label: defaultOption?.Label as string,
      };
      selectAttributeChoices(isMulti ? optionMulti : value);
    }
  };

  /**
   * This action will be called on user interaction with the attribute choices.
   */
  const handleSelectAttributeChoices = (params: OptionType | OptionType[]) => {
    selectAttributeChoices(params);
    onAttributeChoicesSelected?.(params);
  };

  const selectOperationOption = (option: OptionType) => {
    setOperatorOptionSelected(option);
    updateAdvancedFilter(
      {
        ...filter,
        OperatorOption: option,
      },
      index
    );
  };
  const handleSelectDefaultOperationOption = () => {
    const option = supportedOperatorsOptions?.[0];
    if (!option) return;
    selectOperationOption(option);
  };

  /**
   * This action will be called on user interaction with the operator options.
   */
  const handleSelectOperationOption = (option: OptionType) => {
    selectOperationOption(option);
    onOperationOptionSelected?.(option);
  };

  useEffect(() => {
    // Select default operator if there is no operator selected.
    if (!filter?.OperatorOption?.value && supportedOperatorsOptions?.[0]) {
      handleSelectDefaultOperationOption();
    }

    // Select default attribute choice if there is no value selected.
    if (!filter?.Values && filter?.questionDetails?.attributeChoices?.Choices)
      handleSelectDefaultAttributeChoices();
  }, [
    filter?.questionDetails?.attributeChoices?.Choices,
    filter?.OperatorOption?.value,
    supportedOperatorsOptions,
  ]);

  return (
    <div className={className}>
      {filter?.questionDetails ? (
        <>
          <Select
            {...(subAttributeOptionSelectProps || {})}
            className="question-selector"
            name={SubAttributeName}
            options={[subattributeOption]}
            value={subattributeOption}
            isClearable={false}
            isSearchable={false}
            defaultOptions={[]}
          />
          <Select
            {...(supportedOperatorsSelectProps || {})}
            className="question-selector"
            name="supportedOperators"
            value={operatiorOptionSelected}
            onChange={handleSelectOperationOption}
            options={supportedOperatorsOptions}
            isClearable={false}
            isSearchable={false}
            defaultOptions={[]}
          />
          {RequestChoicesFromTheServer ? (
            <AttributeChoices
              className="question-selector attribute-choices"
              onChange={handleSelectAttributeChoices}
              filter={filter}
              index={index}
              getAttributeChoices={getAttributeChoices}
              selectedFilters={selectedFilters}
              isSearchable={areAttributesSearchable}
              selectProps={attributeChoicesSelectProps}
            />
          ) : (
            <TextBox
              className="question-selector attribute-choices"
              onChange={handleTextBoxChange}
              value={filter?.Values?.[0] || ''}
            />
          )}
        </>
      ) : null}
    </div>
  );
}

export default styled(QuestionDetails)((props) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: props.theme.spacing(0.5),
  alignItems: 'center',
  justifyContent: 'space-between',
  background: 'transparent',
  marginBottom: props.theme.spacing(2),
  marginTop: props.theme.spacing(0.5),
  '.question-selector': {
    width: `calc(50% - ${props.theme.spacing(0.25)})`,
  },
  '.attribute-choices': {
    width: '100%',
  },
}));
