export const percentageCalculation = (series: any) => {
  const total = series.reduce((sum: any, item: any) => sum + parseFloat(item.value), 0);

  const result = series.map((item: any) => ({
    ...item,
    percentage: ((parseFloat(item.value) / total) * 100).toFixed(2),
  }));

  // Adjust the last item if the sum is not exactly 100
  const discrepancy =
    100 - result.reduce((sum: any, item: any) => sum + parseFloat(item.percentage || '0'), 0);
  const lastItem = result[result.length - 1];
  lastItem.percentage = (parseFloat(lastItem.percentage || '0') + discrepancy).toFixed(2);

  return result;
};
