import GaugeChart from 'components/Charts/GaugeChart/GaugeChart';
import { BaseWidget } from 'models/WidgetModel';
import React, { useEffect, useState } from 'react';
import './IndividualMetric.scss';

const IndividualMetric: React.FC<BaseWidget> = ({ response, colors }) => {
  const [metric, setMetric] = useState<string>();
  const [showGauge, setShowGauge] = useState<boolean>(false);

  useEffect(() => {
    if (response) {
      if (response?.data?.data?.length) {
        setMetric(response.data.data[0].value);
      }

      if (response?.data?.showGauge) {
        setShowGauge(response.data.showGauge);
      }
    }
  }, [response]);

  return (
    <div className="individual-metric">
      <div className="metric-value">{metric}</div>
      {showGauge && (
        <div className="metric-gauge">
          <GaugeChart response={response} colors={colors} width={125} height={125}></GaugeChart>
        </div>
      )}
    </div>
  );
};

export default IndividualMetric;
