import './CategoryDataGrid.scss';
import { BaseWidget, WidgetAction } from 'models/WidgetModel';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { smileyIcon, neutralIcon, frownIcon, appAssets } from 'constants/assets';
import HorizontalSingleSentimentStackedBarChart from 'components/Charts/HorizontalSingleSentimentStackedBarChart/HorizontalSingleSentimentStackedBarChart';
import Tippy from '@tippyjs/react';
import { useWidgetContext } from 'contexts/WidgetProvider';
import { customStyles } from './CategoryDataGrid.style';

interface CategoryDataGridProps extends BaseWidget {
  dataOnClick?: any;
}
const Circle = appAssets.icons.CIRCLE;
const TrendingFlat = appAssets.icons.TRENDING_FLAT;
const TrendingUp = appAssets.icons.TRENDING_UP;
const TrendingDown = appAssets.icons.TRENDING_DOWN;

const CategoryDataGrid: React.FC<CategoryDataGridProps> = ({
  response: defaultResponse,
  options,
  dataOnClick,
  ...props
}) => {
  const [data, setData] = useState<any>([]);
  const [columns, setColumns] = useState<any>([]);
  const [serieColor, setSerieColor] = useState<any>([]);
  const [showAll, setShowAll] = useState<boolean>(false);
  const { loading } = props;

  const { action, setState } = useWidgetContext();

  useEffect(() => {
    if (action === WidgetAction.EXPANDABLE) {
      setShowAll(!showAll);
      setState({ action: WidgetAction.NONE });
    }
  }, [action]);

  useEffect(() => {
    const tableData = defaultResponse?.data;
    if (tableData) {
      const displayedData = showAll ? tableData?.data : tableData?.data?.slice(0, 5);
      setData(displayedData);
      setColumns(tableData?.columns);
    }
  }, [defaultResponse, showAll]);

  useEffect(() => {
    if (data) {
      const colorsArray = props?.colors?.map((item: any, index: any) => {
        return { id: data[index]?.id, color: item };
      });
      setSerieColor(colorsArray);
    }
  }, [data]);

  const handleRowClick = (row: any) => {
    try {
      dataOnClick(row);
    } catch (error: any) {
      console.error(`${error.message}, be sure you are running from CX application`);
    }
  };

  const showTrend = (delta: number) => {
    if (delta == 0 || delta == null) {
      return (
        <div className="pck-child-div">
          <TrendingFlat className="biggest-yellow-arrow"></TrendingFlat>
        </div>
      );
    } else if (delta > 0) {
      return (
        <div className="pck-child-div">
          <TrendingUp className="biggest-green-arrow"></TrendingUp>
        </div>
      );
    } else if (delta < 0) {
      return (
        <div className="pck-child-div">
          <TrendingDown className="biggest-red-arrow"></TrendingDown>
        </div>
      );
    }
  };

  const showFace = (delta: number) => {
    if (delta == 0 || delta == null) {
      return (
        <div className="pck-child-div face-container">
          <img src={neutralIcon} className="yellow-face" />
        </div>
      );
    } else if (delta > 0) {
      return (
        <div className="pck-child-div face-container">
          <img src={smileyIcon} className="green-face" />
        </div>
      );
    } else if (delta < 0) {
      return (
        <div className="pck-child-div face-container">
          <img src={frownIcon} className="red-face" />
        </div>
      );
    }
  };
  const getRowContent = (column: any, row: any, l1: any) => {
    if (column?.ColType === 'Trend') {
      return (
        <div className="pck-parent">
          <div className="pck-value-width">
            <span>
              {row[column.Field]} {column.IsPercentage ? '%' : ''}
            </span>
          </div>
          {row[column.Field] && row[column.Field] !== '' ? showTrend(row[column.Field]) : ''}
        </div>
      );
    }
    if (column?.ColType === 'Sentiment') {
      return (
        <div className="pck-parent">
          <div className="pck-value-width">
            <span>{row[column.Field]}</span>
          </div>
          {row[column.Field] && row[column.Field] !== '' ? showFace(row[column.Field]) : ''}
        </div>
      );
    }
    if (column?.ColType === 'HSB') {
      const sentiments = ['Negative', 'Neutral', 'Positive'];
      const values = row[column.Field].split(';').map((item: any, index: number) => {
        return {
          label: sentiments[index],
          value: item,
        };
      });
      const series = { data: { series: values, isPercentage: true } };
      return (
        <HorizontalSingleSentimentStackedBarChart
          colors={[]}
          options={options}
          {...props}
          response={series}
        />
      );
    }
    if (
      column.ColType === 'UDL1CategoryLink' ||
      column.ColType === 'UDCategoryLink' ||
      column.ColType === 'UDL2CategoryLink'
    ) {
      const circleColor = serieColor.find((item: any) => item.id === row.id);
      return (
        <div className="pck-parent">
          <div className="pck-child-div">
            {circleColor?.color ? (
              <Circle fill={circleColor?.color} className="circle" />
            ) : (
              <div className="circle"></div>
            )}
          </div>
          <div className="pck-child-div pck-first-colum-width">
            <Tippy
              content={
                <div title={row[column?.Field]}>
                  <div className="tooltip-title">{row[column?.Field]}</div>
                </div>
              }
            >
              <div className={`${column?.IsLink ? 'pck-table-link' : ''}  pck-data-grid-ellipsis`}>
                <span data-tag={column?.IsLink ? 'allowRowEvents' : ''}>{row[column?.Field]}</span>
              </div>
            </Tippy>
            <Tippy
              content={
                <div title={row[l1?.Field]}>
                  <div className="tooltip-title">{row[l1?.Field]}</div>
                </div>
              }
            >
              <div data-tag="allowRowEvents pck-first-colum-width pck-data-grid-ellipsis">
                <span className="base-size-span">{row[l1?.Field]}</span>
              </div>
            </Tippy>
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className="tableLink">
          <span>{row[column?.Field]}</span>
        </div>
      </div>
    );
  };

  const getColumns = () => {
    const l1 = columns?.find((item: any) => item?.ColName === 'l1categoryname');
    const filterColumn = columns?.filter((item: any) => item?.ColType !== 'Hidden');
    if (!filterColumn) return [];
    return filterColumn?.map((column: any) => {
      return {
        id: column?.Field,
        name: column?.DisplayName,
        selector: (row: { [x: string]: any }) => {
          return row[`${column?.Field}`];
        },
        cell: (row: { [x: string]: string | any }) => getRowContent(column, row, l1),
        maxWidth: '30%',
        sortable: true,
        ignoreRowClick: !column.IsLink,
      };
    });
  };

  const dataTableProps = {
    striped: true,
    theme: 'solarized',
    customStyles,
    defaultSortFieldId: 1,
  };

  return (
    <div className={`table-container`}>
      <div className={`data-grid ${showAll && 'sc-container'} fixed-header`}>
        <DataTable
          {...dataTableProps}
          columns={getColumns() ?? []}
          data={data ?? []}
          onRowClicked={handleRowClick}
          progressPending={loading}
          responsive={true}
        />
      </div>
    </div>
  );
};

export default CategoryDataGrid;
