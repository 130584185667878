import { BaseWidget, TextBlockInterface } from 'models/WidgetModel';
import React, { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';

const TextBlock: React.FC<BaseWidget> = ({ options }) => {
  // eslint-disable-next-line prettier/prettier
  const [data, setData] = useState<TextBlockInterface>({} as TextBlockInterface);

  useEffect(() => {
    if (options) {
      setData(options.textBlock);
    }
  }, [options]);
  return data ? (
    <div>
      <div
        className="view ql-editor"
        style={{ ...data?.styles }}
        dangerouslySetInnerHTML={{ __html: data?.textContent }}
      />
    </div>
  ) : null;
};

export default TextBlock;
