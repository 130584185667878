import { appAssets } from 'constants/assets';
import { Metric } from 'models/MetricSummary';
import styles from './MetricSummary.module.scss';
import React from 'react';

interface MetrixBoxProps {
  metric: Metric;
  colors: string[];
}

const TrendingFlat = appAssets.icons.TRENDING_FLAT;
const TrendingUp = appAssets.icons.TRENDING_UP;
const TrendingDown = appAssets.icons.TRENDING_DOWN;

const MetrixBox: React.FC<MetrixBoxProps> = ({ metric, colors }) => {
  const { title, value, delta, period } = metric;
  const [
    BACKGROUND_COLOR = '#49505A',
    FONT_COLOR = '#FFFFFF',
    TITLE_COLOR = '#D8D8D8',
    DOWN_COLOR = '#4CAF50',
    UP_COLOR = '#F44336',
    NEUTRAL_COLOR = '#FFC107',
  ] = colors;

  const ShowIcon = (deltaValue: string | number = 0) => {
    const trend = parseFloat(deltaValue.toString());

    if (trend < 0) return <TrendingDown style={{ fill: UP_COLOR }}></TrendingDown>;
    if (trend > 0) return <TrendingUp style={{ fill: DOWN_COLOR }}></TrendingUp>;

    return <TrendingFlat style={{ fill: NEUTRAL_COLOR }}></TrendingFlat>;
  };

  const ShowDelta = (deltaValue: string | number = 0) => {
    const trend = parseFloat(deltaValue.toString());
    let deltaColor = NEUTRAL_COLOR;
    if (trend < 0) deltaColor = UP_COLOR;
    if (trend > 0) deltaColor = DOWN_COLOR;

    return (
      <span className={styles[`dashbd-delta-numb`]} style={{ color: deltaColor }}>
        {delta}
      </span>
    );
  };

  return (
    <div className={styles[`metric-box`]} style={{ backgroundColor: BACKGROUND_COLOR }}>
      <div className={styles[`take-full-bswidth`]}>
        <div className={styles[`dashboard-metric-bg`]}>
          <div className={styles[`row dashboard-mtc-pd`]}>
            <span
              className={`${styles[`dashbd-metric-title`]} ${styles[`cx-lang-translate`]}`}
              style={{ color: TITLE_COLOR }}
            >
              {title}
            </span>
          </div>
          <div className={styles[`row dashbd-mtc-numb-div dashboard-mtc-pd`]}>
            <span className={styles[`dashbd-metric-numb`]} style={{ color: FONT_COLOR }}>
              {value}
            </span>
          </div>
          <div className={styles[`row dashboard-mtc-pd`]}>
            <div style={{ color: FONT_COLOR }} className={styles[`delta-txt`]}>
              {ShowDelta(delta)}
              {ShowIcon(delta)}
              <span style={{ color: FONT_COLOR }}> from last {period} </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MetrixBox;
