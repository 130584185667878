//eslint-disable-next-line
import { createSignalRContext } from 'react-signalr';
import { useStore as useGlobalStore } from 'store/useGlobalStore';

const { Provider, useSignalREffect, invoke } = createSignalRContext({
  shareConnectionBetweenTab: false,
});

/**
 *  The SignalRProvider component is a wrapper for the SignalR context provider, it will enable the connection only when the endpoint is available
 * @param props
 * @returns
 */
export const SignalRProvider = (props: React.PropsWithChildren) => {
  const endpoints = useGlobalStore((state) => state.endpoints);
  // When the endpoint is not available, the connection is not enabled, this facilitates this functionality to be backward compatible
  const url = endpoints.getWidgetDataHub ?? '';
  const { children } = props;
  return (
    <Provider connectEnabled={!!url} url={url}>
      {children}
    </Provider>
  );
};

export { useSignalREffect, invoke as SendSignalRMessage };
