import React from 'react';
import { AdvancedFiltersWithProviders } from '@cx/ui-components';
import { useStore as useGlobalStore } from 'store/useGlobalStore';
import { transformToAdvancedFilterAttributes } from 'helpers/utilities';
import { getWidgetFromSessionStorage } from 'helpers/sessionStorageHandler';
import { Widget } from 'models/WidgetModel';

const AdvancedFiltersComponent: React.FC<any> = ({
  onSetAdvancedFilters,
  selectedAdvancedFiltersValues,
  id,
}) => {
  const viewId = useGlobalStore((state) => state.configDashboard.viewId);
  const workspace = useGlobalStore((state) => state.configDashboard.workspace);

  // Get values coming from cx2 object
  const cx2Conf = window.cx2?.env || {};

  const advancedFiltersGuid = window.advancedFiltersGUID || '';

  const widget: Widget = getWidgetFromSessionStorage(id);

  // Create model to set initial state for Advanced filters
  const selectedFilters = transformToAdvancedFilterAttributes(
    widget.customWidgetConfig?.Filters?.CurrentSelection?.AdvancedFilters
  );

  const stateValues = transformToAdvancedFilterAttributes(selectedAdvancedFiltersValues);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <AdvancedFiltersWithProviders
      apiUrl={cx2Conf.cxApiUrl || ''}
      WorkspaceId={workspace ? workspace.replace(/"/g, "'") : ''}
      ViewId={viewId}
      onAdvancedFiltersChange={(filter) => onSetAdvancedFilters(filter)}
      AdvancedFiltersGuid={advancedFiltersGuid}
      initialState={{
        advanced: {
          data: {
            AdvancedFiltersGUID: advancedFiltersGuid,
            SelectedFilters: stateValues.length > 0 ? stateValues : selectedFilters,
          },
        },
      }}
    />
  );
};

export default AdvancedFiltersComponent;
