import { Trend } from 'core-ui/SelectTrendInput/SelectTrendInput';

export const WidgetAction = {
  SORT: 'SORT',
  SWITCH_BAR_LINE: 'SWITCH_BAR_LINE',
  SWITCH_CHART_TABLE: 'SWITCH_CHART_TABLE',
  NONE: 'NONE',
  METRICS_DROPDOWN: 'METRICS_DROPDOWN',
  SEARCH_FILTER: 'SEARCH_FILTER',
  SHOW_LINE_CHART: 'SHOW_LINE_CHART',
  EXPORT_TO_CSV: 'EXPORT_TO_CSV',
  EXPORT_TO_XLSX: 'EXPORT_TO_XLSX',
  EXPANDABLE: 'EXPANDABLE',
  EXPORT_TO_HTML: 'EXPORT_TO_HTML',
  // Add here every future actions.
  // eslint-disable-next-line prettier/prettier
} as const;
export type WidgetAction = (typeof WidgetAction)[keyof typeof WidgetAction];

export interface BaseWidget {
  width?: number;
  height?: number;
  response: any;
  [x: string]: any;
}
interface TableOptions {
  striped?: boolean;
  pagination?: boolean;
  paginationServer?: boolean;
  sortServer?: boolean;
  fixedHeader?: boolean;
  showTotals?: boolean;
}

interface ChartOptions {
  colors?: string[];
}

interface WidgetConfig {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
  static: boolean;
  isDraggable?: boolean;
  moved?: boolean;
  maxH?: number;
  maxW?: number;
  minH?: number;
  minW?: number;
}

interface TextBlockStyle {
  color: string;
  textAlign: 'left' | 'right' | 'center';
  fontSize: number;
}

export interface TextBlockInterface {
  textContent: string;
  styles: TextBlockStyle;
}

interface CardOptions {
  options: {
    showTitle: boolean;
    showSubtitle: boolean;
    showActions: boolean;
    showCard: boolean;
    actions: string[];
    textBlock?: TextBlockInterface;
    defaults?: any;
    table?: TableOptions;
    decimalPlaces?: number;
  };
}

export interface TWidgetsConfig {
  w: number;
  h: number;
  minW: number;
  minH: number;
}

export interface WidgetCollectionAttributes extends CardOptions {
  config: TWidgetsConfig;
  image: string;
  actions: string[];
  colors: string[];
}

export interface WidgetAlias {
  controllerName: string;
  attributes: WidgetCollectionAttributes;
}

export interface WidgetCollection {
  component: string;
  description: string;
  defaults: WidgetCollectionAttributes;
  alias: WidgetAlias[];
  dataSourceTypes: string[];
}

export interface CustomConfig {
  Calculations: any[];
  Chart: string;
  Questions: any[];
  Trend?: Trend[];
  Rules: any[];
  Metadata?: any;
  DataSource?: string;
  Filters: Filters | null;
  DataCollectionsList?: any[];
  QueryCollections?: any[];
}

export interface Filters {
  CurrentSelection?: CurrentSelection | null | undefined;
}

export interface CurrentSelection {
  DateFilter?: DateFilter | null;
  HierarchyFilters?: HierarchyFilter[] | null;
  AdvancedFilters?: AdvancedFilter[] | null;
}

export interface DateFilter {
  DisplayName: string;
  EndDate?: string;
  IsEditable: boolean;
  StartDate?: string;
  Type: string;
}

export interface HierarchyFilter {
  AttributeName: string;
  Values: string[];
}

export interface AdvancedFilter {
  AttributeName: string;
  Values: string[];
}

export interface Widget extends TableOptions, ChartOptions, CardOptions {
  id?: string;
  alias: string;
  controllerName?: string;
  description: string;
  dataSourceTypes: string[];
  displayName: string;
  config: WidgetConfig;
  payload: any;
  actions?: string[];
  subText?: string;
  image: string;
  viewName: string;
  isCustom?: boolean;
  isMockData?: boolean;
  hasQuery?: boolean;
  viewId: string;
  colors: string[];
  url?: string;
  customWidgetConfig?: CustomConfig;
}

export const initWidget = (
  wCollection: WidgetCollection,
  alias: WidgetAlias,
  WidgetConfig: any
) => {
  // Combine the default attributes with the alias attributes.
  const config = {
    ...wCollection?.defaults?.config,
    ...alias?.attributes?.config,
  } as unknown as WidgetConfig;

  const image = alias?.attributes?.image ? alias.attributes.image : wCollection.defaults?.image;
  const actions = alias?.attributes?.actions.length
    ? alias?.attributes.actions
    : wCollection.defaults?.actions;
  const options = { ...wCollection.defaults?.options, ...alias?.attributes?.options, actions };
  const colors =
    WidgetConfig.colors || alias?.attributes.colors
      ? WidgetConfig.colors || alias?.attributes.colors
      : wCollection?.defaults?.colors;

  let viewId = '';
  if (WidgetConfig.viewId) {
    viewId = WidgetConfig.viewId;
  } else if (WidgetConfig.payload?.viewId) {
    viewId = WidgetConfig.payload?.viewId.toString();
  } else if (WidgetConfig.payload?.ViewId) {
    viewId = WidgetConfig.payload?.ViewId.toString();
  }

  const widget: Widget = {
    alias: wCollection.component,
    controllerName: WidgetConfig.controllerName,
    displayName: WidgetConfig.displayName,
    description: wCollection.description,
    dataSourceTypes: wCollection.dataSourceTypes,
    config,
    image,
    options,
    actions,
    ...(WidgetConfig.url ? { url: WidgetConfig.url } : {}),
    payload: WidgetConfig?.payload,
    colors: colors,
    viewName: WidgetConfig.viewName,
    viewId: viewId,
    isCustom: false,
  };

  return widget;
};

export const ChartMapping = {
  0: 'Unknown',
  1: 'PieChart',
  2: 'HorizontalBarChart',
  3: 'VerticalBarChart',
  4: 'HorizontalSingleStackedBarChart',
  5: 'BulletChart',
  6: 'DataGrid',
  7: 'GeoMap',
  8: 'StackedBarChart',
  9: 'GaugeChart',
  10: 'IndividualMetric',
  11: 'ChatSegmentation',
  12: 'TopFive',
  13: 'TopCategories',
  14: 'BulletChart',
  15: 'BiggestMovers',
  16: 'MultipleSummaryMetrics',
};
