import React, { MouseEventHandler } from 'react';
import { Select, OptionType, type SelectProps } from '@cx/ui';
import { styled } from 'theme';
import { AdvancedFilterType, SelectedAdvancedFilter } from 'types';
import QuestionDetails, { QuestionDetailsProps } from './QuestionDetails';

export interface AdvancedFilterProps
  extends Pick<
    QuestionDetailsProps,
    | 'onOperationOptionSelected'
    | 'onAttributeChoicesSelected'
    | 'onTextBoxChange'
  > {
  options?: OptionType[];
  index?: number;
  onRemoveAdvancedFilter: (
    filter: SelectedAdvancedFilter,
    index: number
  ) => any;
  onSelectFilter: (filter: SelectedAdvancedFilter, index: number) => any;
  getAttributeChoices: (filter: SelectedAdvancedFilter, index: number) => any;
  selectedFilters: any[];
  areAttributesSearchable?: boolean;
  selectProps?: Partial<
    Omit<
      SelectProps,
      | 'name'
      | 'defaultOptions'
      | 'placeholder'
      | 'className'
      | 'options'
      | 'onChange'
      | 'maxMenuHeight'
      | 'value'
    >
  >;
  className?: string;
  filter: AdvancedFilterType;
  subAttributeOptionSelectProps?: QuestionDetailsProps['subAttributeOptionSelectProps'];
  supportedOperatorsSelectProps?: QuestionDetailsProps['supportedOperatorsSelectProps'];
  attributeChoicesSelectProps?: QuestionDetailsProps['attributeChoicesSelectProps'];
}

/**
 * @name AdvancedFilter
 * @descirption Component that renders an advanced filter
 * @prop {number} index Position of the component
 * @prop {OptionType[]} options Array for options
 * @prop {Function} onRemoveAdvancedFilter Function that is call when the remove icon is pressed
 * @prop {Function} onSelectFilter Function that is call when the option changes
 * @prop {Function} getAttributeChoices Function that is call when the option changes
 * @prop {any[]} selectedFilters Array of selected filters
 * @prop {SelectedAdvancedFilter} filter Current advanced filter selected
 * @prop {areAttributesSearchable} areAttributesSearchable makes the attribute dropdown searchable
 * @returns {Component}
 */

function AdvancedFilter({
  className = '',
  index,
  options,
  selectedFilters,
  filter,
  onRemoveAdvancedFilter,
  getAttributeChoices,
  areAttributesSearchable = false,
  onSelectFilter,
  onOperationOptionSelected,
  onAttributeChoicesSelected,
  onTextBoxChange,
  selectProps,
  subAttributeOptionSelectProps,
  supportedOperatorsSelectProps,
  attributeChoicesSelectProps,
}: AdvancedFilterProps) {
  const handleRemovePress: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    onRemoveAdvancedFilter?.(filter, index);
  };

  const handleSelectOption = (option: any) => {
    onSelectFilter?.(option.filter, index);
  };

  const getSelectedFilter = (filterProp?: AdvancedFilterType): OptionType => {
    if (!filterProp) return undefined;

    return Object.keys(filterProp).length !== 0
      ? {
          label: filterProp.Label,
          value: filterProp.AttributeGUID,
        }
      : undefined;
  };

  return (
    <div data-testid="advanced-filter" className={className}>
      <div className="selector-container">
        <Select
          {...(selectProps || {})}
          name="advanced-filter-selector"
          defaultOptions={null}
          placeholder="Select a question"
          className="select"
          options={options}
          onChange={handleSelectOption}
          maxMenuHeight={250}
          value={getSelectedFilter(filter)}
        />
        <button
          data-testid="remove-advanced-filter"
          type="button"
          onClick={handleRemovePress}
          className="remove-advanced-filter"
        >
          -
        </button>
      </div>

      <div className="question-detail-container">
        <QuestionDetails
          filter={filter}
          index={index}
          getAttributeChoices={getAttributeChoices}
          selectedFilters={selectedFilters}
          onOperationOptionSelected={onOperationOptionSelected}
          onAttributeChoicesSelected={onAttributeChoicesSelected}
          onTextBoxChange={onTextBoxChange}
          areAttributesSearchable={areAttributesSearchable}
          subAttributeOptionSelectProps={subAttributeOptionSelectProps}
          supportedOperatorsSelectProps={supportedOperatorsSelectProps}
          attributeChoicesSelectProps={attributeChoicesSelectProps}
        />
      </div>
    </div>
  );
}

export default styled(AdvancedFilter)((props) => ({
  '.selector-container': {
    marginBottom: props.theme.spacing(0.25),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: 'transparent',
    '.select': {
      width: `calc(100% - ${props.theme.spacing(1.5)})`,
    },
  },
  '.remove-advanced-filter': {
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: props.theme.spacing(1),
    height: props.theme.spacing(1),
    background: props.theme.palette.primary.main,
    ...props.theme.typography.smallMedium,
    color: props.theme.palette.onPrimary.main,
    borderRadius: '50%',
    cursor: 'pointer',
  },
  '.question-detail-container': {
    width: `calc(100% - ${props.theme.spacing(1.5)})`,
  },
}));
