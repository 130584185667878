export const SortOptions = {
  ASC: 'asc',
  DESC: 'desc',
  NONE: 'none',
  // eslint-disable-next-line prettier/prettier
} as const;

export type SortOptions = (typeof SortOptions)[keyof typeof SortOptions];

export const sortByNumber = (data: any, key: string, order: SortOptions) => {
  if (data.length && key && order) {
    if (order === SortOptions.ASC) {
      return data.sort((a: any, b: any) => a[key] - b[key]);
    }
    if (order === SortOptions.DESC) {
      return data.sort((a: any, b: any) => b[key] - a[key]);
    }
    return data;
  }
};

export const removeZeroValues = (data: any) => {
  const arr = data || [];
  const dataTemp = arr.filter(function (seriesData: any) {
    return parseFloat(seriesData.yValue) !== 0;
  });
  return dataTemp;
};

export const sortByString = (data: any, key: string, order: SortOptions) => {
  if (data.length && key && order) {
    if (order === SortOptions.ASC) {
      return data.sort((a: any, b: any) => a[key]?.localeCompare(b[key]));
    }
    if (order === SortOptions.DESC) {
      return data.sort((a: any, b: any) => b[key]?.localeCompare(a[key]));
    }
    return data;
  }
};

export function arrayMove<T>(array: readonly T[], from: number, to: number) {
  const slicedArray = array.slice();
  slicedArray.splice(to < 0 ? array.length + to : to, 0, slicedArray.splice(from, 1)[0]);
  return slicedArray;
}

export const extractValue = (value: any) => {
  if (value === '0') return 0; // Explicitly handle "0" as numeric 0
  if (isDate(value)) {
    return new Date(value).getTime();
  }
  if (!isNaN(value) && isFinite(value)) {
    return parseFloat(value);
  }
  return value;
};

const isDate = (value: any) => {
  if (typeof value === 'string' && isNaN(Number(value))) {
    // Check if it's a valid date
    return !isNaN(Date.parse(value));
  }
  return false;
};
