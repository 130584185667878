import React from 'react';
import './DrawerCard.scss';
import { WidgetImages, appAssets } from 'constants/assets';
import { DrawerCardModel } from 'models/Drawer';
import { useStore } from 'store/useGlobalStore';

export interface DrawerCardProps {
  card: DrawerCardModel;
  setShowDetail: React.Dispatch<React.SetStateAction<boolean>>;
}

const DrawerCard: React.FC<DrawerCardProps> = ({ card, setShowDetail }) => {
  const { displayName, description, image, viewName } = card;
  const setState = useStore((state) => state.setState);

  const widgetImage = (image: WidgetImages) => {
    if (appAssets.widgets[image]) {
      return React.createElement(appAssets.widgets[image], {});
    }
  };

  const onDragStart = (e: any) => {
    e.dataTransfer.setData('text/plain', JSON.stringify(card));
    setState({ virtualWidget: card.config });
    setShowDetail(false);
  };

  return (
    <div
      className="toolbox-card droppable-element"
      draggable={true}
      onDragStart={onDragStart}
      onDragEnd={() => setShowDetail(true)}
      data-testid="toolbox"
    >
      <div className="toolbox-card-image">{widgetImage(image)}</div>
      <div className="toolbox-card-content">
        <h3 className="title">
          {displayName} {viewName ? `(${viewName})` : null}
        </h3>
        <p className="description-text">{description}</p>
      </div>
    </div>
  );
};

export default DrawerCard;
