import './SessionExplorer.scss';
import { BaseWidget, WidgetAction } from 'models/WidgetModel';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { customStyles } from './SessionExplorer.style';
import { useWidgetContext } from 'contexts/WidgetProvider';
import { useAxios } from 'hooks/useAxios';
import { getDataFromSingleEndpoint } from 'services/Torogoz/TogorozApi';
import { useStore as useGlobalStore } from 'store/useGlobalStore';

type SessionExplorerProps = BaseWidget;

const SessionExplorer: React.FC<SessionExplorerProps> = ({
  response,
  sessionClick,
  hideTranscript,
}) => {
  const [data, setData] = useState<any>([]);
  const [columns, setColumns] = useState<any>([]);
  const endpoints = useGlobalStore((state) => state.endpoints);

  const { action, setState } = useWidgetContext();

  const { response: sessionExplorerResponse, axiosFetch, loading } = useAxios();

  useEffect(() => {
    if (!response) return;
    const getData = async () => {
      await getDataFromSingleEndpoint(
        endpoints.getWidgetData,
        {
          filters: {
            category_id: response.response.field_0,
          },
          source: 'chat',
          actionName: 'sessions',
          external: true,
        },
        axiosFetch
      );
    };
    getData();
  }, [response]);

  useEffect(() => {
    if (action === WidgetAction.EXPANDABLE) {
      setState({ action: WidgetAction.NONE });
    }
  }, [action]);

  useEffect(() => {
    const tableData = sessionExplorerResponse?.data;
    if (tableData) {
      const displayedData = tableData?.data;
      setData(displayedData);
      setColumns(tableData?.columns);
    }
  }, [sessionExplorerResponse]);

  const handleRowClick = (row: any) => {
    try {
      sessionClick(row);
    } catch (error: any) {
      console.error(`${error.message}, be sure you are running from CX application`);
    }
  };

  const getColumns = () => {
    if (!columns) return [];
    const columList = hideTranscript
      ? columns?.filter((item: any) => item?.ColName !== 'Summary')
      : columns;
    return columList?.map((column: any) => {
      return {
        id: column?.Field,
        name: column?.DisplayName,
        selector: (row: { [x: string]: any }) => {
          return row[`${column?.Field}`];
        },
        cell: (row: { [x: string]: string | any }) => {
          return (
            <div
              data-tag="allowRowEvents"
              className={`pck-data-grid-ellipsis
              ${column?.Field === 'field_1' ? 'pck-value-width' : ''}`}
            >
              {row[column?.Field]}
            </div>
          );
        },
      };
    });
  };
  const dataTableProps = {
    striped: false,
    theme: 'solarized',
    customStyles,
    defaultSortFieldId: 1,
  };

  return (
    <div className={`session-explorer-dg-container`}>
      <div className={`data-grid sc-container`}>
        <DataTable
          {...dataTableProps}
          columns={getColumns() ?? []}
          data={data ?? []}
          onRowClicked={handleRowClick}
          progressPending={loading}
        />
      </div>
    </div>
  );
};

export default SessionExplorer;
