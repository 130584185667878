import {
  FilterActions,
  FilterState,
  HierarchyFilter,
  AdvancedFilterType,
  SelectedAdvancedFilter,
} from 'types';
import * as actions from './index';

/**
 * Helper function to create actions. This is used to create actions in the store.
 * @param {set: any, get: any, store: any, initialState: any} param0
 * set: Set a state value,
 * get: Get a state value,
 * store: Store object
 * initialState: Initial state of the store
 * @returns {FilterState}
 */
export const createActions = ({
  store,
}: {
  set: any;
  get: any;
  store: any;
  initialState?: Partial<FilterState>;
}): Partial<FilterActions> => ({
  initAdvancedFilters: (callback) =>
    store.putActionToSaga(actions.initAdvancedFilters(callback)),
  // Hierarchy
  updatefilters: (_: any, callback) =>
    store.putActionToSaga(actions.updateFilters(callback)),
  updatefiltersSuccess: (data: any, callback) =>
    store.putActionToSaga(actions.updateFiltersSuccess(data, callback)),
  updatefiltersError: (error: string, callback) =>
    store.putActionToSaga(actions.updateFiltersError(error, callback)),
  initHierarchyFilters: (callback) =>
    store.putActionToSaga(actions.initHierarchyFilters(callback)),
  selectHierarchyFilter: (filter: HierarchyFilter, value: any, callback) =>
    store.putActionToSaga(
      actions.selectHierarchyFilter(filter, value, callback)
    ),
  updateHierarchyFilter: (filter: HierarchyFilter, callback) =>
    store.putActionToSaga(actions.updateHierarchyFilter(filter, callback)),
  getAdvancedFiltersQuestionList: () =>
    store.putActionToSaga(actions.fetchAdvancedFiltersQuestionList()),
  getAdvancedFiltersQuestionListSuccess: (data: AdvancedFilterType[]) =>
    store.putActionToSaga(
      actions.fetchAdvancedFiltersQuestionListSuccess(data)
    ),
  getAdvancedFiltersQuestionListError: (error: string) =>
    store.putActionToSaga(actions.fetchAdvancedFiltersQuestionListError(error)),
  getQuestionDetails: (params) =>
    store.putActionToSaga(actions.fetchQuestionDetails(params)),
  getQuestionDetailsSuccess: () =>
    store.putActionToSaga(actions.fetchQuestionDetailsSuccess()),
  getQuestionDetailsError: (error: string) =>
    store.putActionToSaga(actions.fetchQuestionDetailsError(error)),
  getAttributeChoices: (filter: SelectedAdvancedFilter, index?: number) =>
    store.putActionToSaga(actions.fetchAttributeChoices(filter, index)),
  getAttributeChoicesSuccess: () =>
    store.putActionToSaga(actions.fetchAttributeChoicesSuccess()),
  getAttributeChoicesError: (error: string) =>
    store.putActionToSaga(actions.fetchAttributeChoicesError(error)),
  addAdvancedFilter: (
    filter: SelectedAdvancedFilter,
    index?: number,
    callback?
  ) =>
    store.putActionToSaga(actions.addAdvancedFilter(filter, index, callback)),
  removeAdvancedFilter: (
    filter: SelectedAdvancedFilter,
    index?: number,
    callback?
  ) =>
    store.putActionToSaga(
      actions.removeAdvancedFilter(filter, index, callback)
    ),
  updateAdvancedFilter: (
    filter: SelectedAdvancedFilter,
    index?: number,
    callback?
  ) =>
    store.putActionToSaga(
      actions.updateAdvancedFilter(filter, index, callback)
    ),
  removeHierarchyFilterValue: (params) =>
    store.putActionToSaga(actions.removeHierarchyFilterValue(params)),
  updateHierarchyFiltersSuccess: (_: any, callback) =>
    store.putActionToSaga(actions.updateHierarchyFilterSuccess(callback)),
  updateHierarchyFiltersError: (error: string, callback) =>
    store.putActionToSaga(actions.updateHierarchyFilterError(error, callback)),
});
