/* eslint-disable react/require-default-props */
import React, { createContext, useContext, useRef } from 'react';
import { create, useStore } from 'zustand';
import { PersistStorage, createJSONStorage, persist } from 'zustand/middleware';
import { UserType } from '../types';
import { request, signOut } from '../services';

const StoreContext = createContext(null);

export type GetUserInfoType = () => Promise<UserType>;

export type SelectorType<U> = (state: any) => U;

export type LoginStoreType = {
  apiUrl: string;
  user?: UserType;
  getUser: () => Promise<void>;
  resetStore: () => void;
  singOut?: () => void;
};

export interface CreateLoginStoreType {
  /**
   * Function to get the user info. If you provide this function you don't need to provide the apiUrl
   */
  getUserInfo?: GetUserInfoType;
  /**
   * Api url to get the user info. If you provide this you don't need to provide the getUserInfo function
   */
  apiUrl: string;
  /**
   * Initial state of the store
   */
  initialState?: Partial<LoginStoreType>;
  /**
   * Options to persist the store.
   * More info in [zustand documentation](https://docs.pmnd.rs/zustand/integrations/persisting-store-data)
   */
  persistedOptions?: {
    name: string;
    storage?: PersistStorage<any>;
    migrate?: (state: any) => any;
    version?: number;
    partialize?: (state: any) => any;
  };
}
export const defaultPersistedOptions = {
  name: 'cx-login-store',
  storage: createJSONStorage(() => sessionStorage),
  migrate: (state: LoginStoreType) => state,
  version: 1,
  partialize: ({ user }: LoginStoreType) => ({
    user,
  }),
};
const createLoginStore = ({
  getUserInfo,
  apiUrl,
  persistedOptions,
  initialState,
}: CreateLoginStoreType) =>
  create<LoginStoreType>()(
    persist(
      (set, get) => ({
        apiUrl,
        user: null,
        ...(initialState || {}),
        getUser: async () => {
          const state = get();
          let { user } = state;
          const { apiUrl: localApiUrl } = state;
          if (!user) {
            try {
              if (getUserInfo) {
                user = await getUserInfo();
              } else if (localApiUrl) {
                const response = await request(
                  `${localApiUrl}/api/Sessions/GetUserProfile`,
                  {
                    method: 'POST',
                    body: {},
                  }
                );
                if (response.ok) {
                  user = await response.json();
                }
              }
            } catch (error) {
              console.error('Error fetching user info', error);
            } finally {
              set({ user });
            }
          }
        },
        resetStore: () => {
          set({ user: null });
        },
        singOut: () => {
          const { resetStore } = get();
          resetStore();
          signOut();
        },
      }),
      {
        ...defaultPersistedOptions,
        ...(persistedOptions || {}),
      }
    )
  );

export function LoginStoreProvider({
  children,
  ...props
}: React.PropsWithChildren & CreateLoginStoreType) {
  const storeRef = useRef(null);
  if (!storeRef.current) {
    storeRef.current = createLoginStore(props);
  }
  return (
    <StoreContext.Provider value={storeRef.current}>
      {children}
    </StoreContext.Provider>
  );
}

export const useLoginStore = (
  selector: SelectorType<LoginStoreType> = (state) => state
) => {
  const store = useContext(StoreContext);
  if (!store) {
    throw new Error('Missing LoginStoreProvider');
  }
  return useStore(store, selector);
};
