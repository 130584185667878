import React, { useEffect, useState } from 'react';
import { BaseWidget } from 'models/WidgetModel';
import PerformanceTable from 'components/PerformanceTable/PerformanceTable';
import RadialGaugeSimple from 'components/Charts/RadialGaugeSimple/RadialGaugeSimple';
import './TopBottomFive.scss';

interface TopBottomFiveProps extends BaseWidget {
  colors: string[];
}

const TopBottomFive: React.FC<TopBottomFiveProps> = ({ response, colors }) => {
  const [data, setData] = useState<any>(null);
  const [selectedTab, setSelectedTab] = useState<string>(''); //change selection dinamicly
  const [selectedRowDataTopFive, setSelectedRowDataTopFive] = useState<any>({});
  const [selectedRowDataBottomFive, setSelectedRowDataBottomFive] = useState<any>({});
  const [isPercentage, setIsPercentage] = React.useState<boolean>(false);

  useEffect(() => {
    if (response) {
      setData(response.data);
      setIsPercentage(response.data?.isPercentage);
      setSelectedRowDataTopFive({ i: 0, data: response?.data?.topFive?.[0] || [] });
      setSelectedRowDataBottomFive({ i: 0, data: response?.data?.bottomFive?.[0] || [] });
      response?.data?.topFive ? setSelectedTab('tab1') : setSelectedTab('tab2');
    }
  }, [response]);

  return data ? (
    <div className="tabs-container">
      <div className="tabs-header">
        {data?.topFive ? (
          <label
            htmlFor="tab1"
            onClick={() => setSelectedTab('tab1')}
            className={selectedTab === 'tab1' ? 'checked' : ''}
          >
            Top Performers
          </label>
        ) : null}
        {data?.bottomFive ? (
          <label
            htmlFor="tab2"
            onClick={() => setSelectedTab('tab2')}
            className={selectedTab === 'tab2' ? 'checked' : ''}
          >
            Under Performers
          </label>
        ) : null}
      </div>
      <div className="tabs-content">
        {selectedTab === 'tab1' ? (
          <div className="tab-item" key={1}>
            <div className="performer-content">
              <PerformanceTable
                data={data.topFive}
                isPercentage={isPercentage}
                selectedRowData={selectedRowDataTopFive}
                setSelectedRowData={setSelectedRowDataTopFive}
                labels={data?.topLabeling}
              />
              <RadialGaugeSimple
                data={selectedRowDataTopFive.data}
                isPercentage={isPercentage}
                colors={colors}
                labels={data?.topLabeling}
              />
            </div>
          </div>
        ) : (
          <div className="tab-item" key={2}>
            <div className="performer-content">
              <PerformanceTable
                data={data.bottomFive}
                isPercentage={isPercentage}
                selectedRowData={selectedRowDataBottomFive}
                setSelectedRowData={setSelectedRowDataBottomFive}
                labels={data?.bottomLabeling}
              />
              <RadialGaugeSimple
                data={selectedRowDataBottomFive.data}
                isPercentage={isPercentage}
                colors={colors}
                labels={data?.bottomLabeling}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default TopBottomFive;
