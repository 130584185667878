/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import {
  MsalAuthenticationProps,
  MsalAuthenticationTemplate,
} from '@azure/msal-react';
import { getDomainHint, isHosted } from '../utils';
import LoginProvider from './LoginProvider';
import { LoginStoreProvider, CreateLoginStoreType } from '../store';
import UserProvider from './UserProvider';
import { getLoginHint } from '../utils/getDomainHint';

export interface AuthMethodProviderProps extends CreateLoginStoreType {
  /**
   * Component to render when autthentication success.
   */
  children?: React.ReactNode;
  /**
   * Redirect uri to use in the msal instance.
   * more info in [msal documentation](https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md#initialization-of-msal)
   */
  redirectUri?: string;
  /**
   * Client id to use in the msal instance.
   * more info in [msal documentation](https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md#initialization-of-msal)
   */
  clientId?: string;
  /**
   * Authority url to use in the msal instance.
   * more info in [msal documentation](https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md#initialization-of-msal)
   */
  authorityUrl?: string;
  /**
   * Known authorities to use in the msal instance.
   * more info in [msal documentation](https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md#initialization-of-msal)
   */
  knownAuthorities?: Array<string>;
  /**
   * Scopes to use in the msal instance.
   * more info in [msal documentation](https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md#initialization-of-msal)
   */
  scopes?: Array<string>;
  /**
   * Component to render when an error occurs.
   */
  errorComponent?: MsalAuthenticationProps['errorComponent'];
  /**
   * Component to render when loading.
   */
  loadingComponent?: MsalAuthenticationProps['loadingComponent'];
}

/** *
 * Component used to set the Authentication provider to use in the app
 * when the app is hosted in CX it will get the token from an endpoint in CX
 * if we are using local development we are able to use msaljs to get a token
 * to make request to the cx-api
 * @param children
 * @param clientId
 * @param redirectUri
 * @param authorityUrl
 * @param knownAuthorities
 * @param scopes
 * @constructor
 */
function AuthMethodProvider({
  children,
  redirectUri = '',
  clientId = null,
  authorityUrl = null,
  knownAuthorities = null,
  scopes = null,
  apiUrl = null,
  getUserInfo = null,
  errorComponent = null,
  loadingComponent = null,
}: AuthMethodProviderProps) {
  const isHostedInCx = isHosted();

  const [isMsalInitialized, setIsMsalInitialized] = useState<boolean>(false);
  const loginHint = getLoginHint();

  const msalInstance = new PublicClientApplication({
    auth: {
      clientId,
      authority: authorityUrl,
      knownAuthorities,
      redirectUri,
    },
  });

  useEffect(() => {
    msalInstance.initialize().then(() => {
      setIsMsalInitialized(true);
      if (msalInstance !== null)
        window.msalInstance = { current: msalInstance };
    });
  }, [msalInstance]);

  useEffect(() => {
    if (scopes !== null) window.scopesRef = { current: scopes };
  }, [scopes]);

  if (isHostedInCx)
    return (
      <LoginStoreProvider apiUrl={apiUrl} getUserInfo={getUserInfo}>
        <UserProvider>{children}</UserProvider>
      </LoginStoreProvider>
    );

  if (isMsalInitialized) {
    return (
      <LoginProvider msalInstance={msalInstance}>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={{
            domainHint: getDomainHint(),
            loginHint,
            scopes,
            extraQueryParameters: {
              prompt: loginHint ? 'none' : 'login',
            },
          }}
          errorComponent={errorComponent}
          loadingComponent={loadingComponent}
        >
          <LoginStoreProvider apiUrl={apiUrl} getUserInfo={getUserInfo}>
            <UserProvider>{children}</UserProvider>
          </LoginStoreProvider>
        </MsalAuthenticationTemplate>
      </LoginProvider>
    );
  }

  return null;
}

export default AuthMethodProvider;
