import { library } from 'components';
import { createArraySeriesValidator } from 'utils/validators/ArraySeriesValidator';

interface DataValidatorList {
  [key: string]: any;
}

const noWarningWidgets = [library.TEXT_BLOCK];

const validateStackedBarChart = (res: any) => {
  const { series } = res.data;
  const ArraySeriesValidator = createArraySeriesValidator(series, [
    { type: 'notEqualArrayLenght' },
  ]);
  const validator = ArraySeriesValidator.validate();
  return validator;
};

const validatePieChart = (res: any) => {
  const { series } = res.data;
  const ArraySeriesValidator = createArraySeriesValidator(series);
  const validator = ArraySeriesValidator.validate();
  return validator;
};

export const validatorsList: DataValidatorList = {
  StackedBarChart: validateStackedBarChart,
  PieChart: validatePieChart,
};

export const warningValidData = (res: any, widgetName: string) => {
  if (!res?.data) return false;
  if (validatorsList[widgetName]) {
    const validator = validatorsList[widgetName](res);
    if (!validator.ok) {
      console.warn(validator);
      return true;
    }
  }
  return false;
};

export const showWarningValidation = (
  previewMode: boolean,
  isMockData: boolean,
  widgetName: string
) => {
  if (noWarningWidgets.includes(widgetName)) return false;
  return previewMode || isMockData;
};
