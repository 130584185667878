import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
/**
 * Method to aget the active account.
 * @returns {AccountInfo | null}
 */
export const getCurrentAccount = (): AccountInfo | null => {
  if (window.msalInstance.current) {
    const msalInstance: IPublicClientApplication = window.msalInstance.current;
    const activeAccount = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();
    return activeAccount || accounts[0] || null;
  }

  return null;
};
