import React, { useEffect, useRef, useState } from 'react';
import ReactECharts, { EChartsOption } from 'echarts-for-react';
import { axisLabel, axisLine, axisTick, tooltip } from 'constants/chartOption';
import { BaseWidget, WidgetAction } from 'models/WidgetModel';
import { sortByNumber, removeZeroValues, SortOptions } from 'helpers/sorting';
import { lightenDarkenColor } from 'helpers/darkenLightenColor';
import { useWidgetContext } from 'contexts/WidgetProvider';
interface HorizontalChartProps extends BaseWidget {
  colors: string[];
  options: any;
}

const HorizontalBarChart: React.FC<HorizontalChartProps> = ({
  colors,
  response,
  options,
  ...props
}) => {
  const [series, setSeries] = useState<any[]>([]);
  const [xData, setXData] = useState<any[]>([]);
  const [yData, setYData] = useState<any[]>([]);
  const [optionsChart, setOptionsChart] = useState<any>();
  const [showRowsWithNoData, setShowNoData] = useState<boolean>(false);
  const [drawGoalLine, setdrawGoalLine] = React.useState<boolean>(false);
  const [goalValues, setGoalValues] = useState<any>({});
  const [colorPalette, setColorPallete] = useState<string[]>([]);
  const [isPercentage, setIsPercentage] = useState<boolean>(true);
  const [sortBy, setSortBy] = useState<SortOptions>(SortOptions.ASC);

  const { action, chartView, setState } = useWidgetContext();

  const { width, height } = props;

  const optionsRef = useRef<any>();
  const colorsRef = useRef<string[]>();
  const divRef = useRef<any>();

  const chart = useRef<any>(null);

  optionsRef.current = optionsChart;
  colorsRef.current = colorPalette;

  const {
    goal,
    drawGoalLine: optDrawGoalLine,
    showRowsWithNoData: optShowRowsWithNoData,
  } = options || {};

  useEffect(() => {
    if (goal) {
      setGoalValues(goal);
    }
  }, [goal]);

  useEffect(() => {
    if (options && 'drawGoalLine' in options) {
      optDrawGoalLine ? setdrawGoalLine(optDrawGoalLine) : setGoalValues({});
    }
  }, [optDrawGoalLine]);

  useEffect(() => {
    setShowNoData(optShowRowsWithNoData);
  }, [optShowRowsWithNoData]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      adjustLabel();
    });

    const divElement = divRef.current;
    resizeObserver.observe(divElement);

    return () => {
      resizeObserver.unobserve(divElement);
    };
  }, [divRef.current]);

  useEffect(() => {
    if (action === WidgetAction.SORT) {
      const sort: SortOptions = sortBy === SortOptions.ASC ? SortOptions.DESC : SortOptions.ASC;
      setSortBy(sort);
      setState({ action: WidgetAction.NONE });
    }
  }, [action]);

  useEffect(() => {
    if (chartView) {
      setSortBy(SortOptions.ASC);
    }
  }, [chartView]);

  useEffect(() => {
    if (optionsChart) {
      optionsRef.current = optionsChart;
    }
  }, [optionsChart]);

  useEffect(() => {
    if (response?.data?.seriesData?.length && sortBy) {
      let sortedData = [...sortByNumber(response.data.seriesData, 'yValue', sortBy)];
      sortedData = showRowsWithNoData ? sortedData : removeZeroValues(sortedData);
      setSeries(sortedData);
      setColorPallete(colorValidation());
      setIsPercentage(response.data.isPercentage);
    }
  }, [response, sortBy, showRowsWithNoData]);

  useEffect(() => {
    if (colors) {
      setColorPallete(colorValidation());
    }
  }, [colors]);

  useEffect(() => {
    if (series?.length) {
      setXData(series.map((item: any) => item.xValue));
      setYData(series.map((item: any) => item.yValue));
    }
  }, [series]);

  useEffect(() => {
    const optionsObj: EChartsOption = initOptions();
    if (Object.keys(optionsObj).length) {
      setOptionsChart(optionsObj);
    }
  }, [xData, yData, colorPalette, isPercentage, colors, goalValues, options?.drawGoalLine]);

  const colorValidation = () => {
    if (Array.isArray(colors)) {
      const [color1] = colors;
      return [color1];
    }
    return [colors];
  };

  const initOptions = () => {
    const { lineStyle, lineWidth, goalLineColor, goalValue } = goalValues;
    if (!colorsRef.current?.length) return {};
    return {
      firstRender: true,
      yAxis: {
        type: 'category',
        data: xData,
        show: true,
        axisLine: {
          show: false,
        },
        ...axisTick,
        axisLabel: {
          ...axisLabel,
        },
      },
      xAxis: {
        type: 'value',
        show: false,
        splitLine: {
          show: false,
        },
        ...axisTick,
        ...axisLabel,
        ...axisLine,
      },
      grid: {
        show: false,
        width: 'auto',
        left: '18%',
        //right: '1%',
        top: '20',
        bottom: '5',
      },

      tooltip: {
        ...tooltip,
        formatter: `<strong>{b}:</strong> {c}${isPercentage ? '%' : ''}`,
      },
      series: {
        markLine: {
          symbol: 'none',
          lineStyle: {
            type: lineStyle ? lineStyle : 'solid',
            width: lineWidth && drawGoalLine ? lineWidth : 0,
            color: goalLineColor ? goalLineColor : '#000',
          },
          data: [
            {
              label: { show: false },
              xAxis: goalValue ? goalValue : 0,
              tooltip: {
                formatter: `<strong>Goal{b}:</strong> {c}${isPercentage ? '%' : ''}`,
              },
            },
          ],
        },
        data: yData,
        type: 'bar',
        itemStyle: {
          borderRadius: 5,
          color: colorsRef.current,
        },
        emphasis: {
          itemStyle: {
            ...(colorsRef.current?.length
              ? { color: lightenDarkenColor(colorsRef.current[0], -40) }
              : {}),
          },
        },
        barWidth: '94%',
        label: {
          show: true,
          position: 'right',
          distance: 5,
          color: '#383838',
          fontWeight: 'bold',
          overflow: 'break',
          formatter: `{c}${isPercentage ? '%' : ''}`,
        },
      },
    };
  };

  const adjustLabel = () => {
    if (chart.current) {
      if (optionsChart && !Object.keys(optionsChart).length) return;

      const { width: chartWidth } = chart.current
        .getEchartsInstance()
        .getDom()
        .getBoundingClientRect();

      const overflow = chartWidth >= 1000 ? 'none' : 'truncate';
      const savedOptions = optionsRef.current;
      if (savedOptions.yAxis) {
        const newOptions = {
          ...savedOptions,
          firstRender: false,
          yAxis: {
            ...savedOptions.yAxis,
            axisLabel: {
              ...savedOptions.yAxis.axisLabel,
              overflow,
            },
          },
        };

        setOptionsChart(() => newOptions);
      }
    }
  };

  const eventsMap = {
    finished: function () {
      if (optionsChart.firstRender) adjustLabel();
    },
  };

  return (
    <div className="responsive-chart" ref={divRef} data-testid="HorizontalBarChart">
      {optionsChart ? (
        <ReactECharts
          ref={chart}
          opts={{ renderer: 'svg', width, height }}
          option={optionsChart}
          onEvents={eventsMap}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default HorizontalBarChart;
