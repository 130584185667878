import React, { useState } from 'react';
import './groupSelectSortable.scss';
import chipotleStyles from 'core-ui/styles/chipotletSelect';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { appAssets } from 'constants/assets';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, useSortable, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Select, { components, MultiValueGenericProps, MultiValueProps } from 'react-select';

export interface GroupSelectProps {
  loading: boolean;
  handleDragEnd: any;
  options: any;
  selectedChange: any;
  selectedValues?: Array<Calculation>;
  label: string;
  onBlurEvent: any;
  helperText: string;
}

export interface Calculation {
  CalculationFormula: string;
  CalculationLabel: string;
  CoreCalcTag: string;
  DataType: string;
  DecimalFormat: number;
  DisplayFormat: string;
  DisplayName: string;
  Id: number;
  IsUnscaledPercent: boolean;
  QuestionName: string;
  label: string;
  value: number;
}

const SortableMultiValueLabel = (props: MultiValueGenericProps<Calculation>) => {
  const { attributes, listeners } = useSortable({ id: props.data.value });
  return (
    <div {...attributes} {...listeners}>
      <components.MultiValueLabel {...props} />
    </div>
  );
};
const SortableMultiValue = (props: MultiValueProps<Calculation>) => {
  const { setNodeRef, transform, transition } = useSortable({
    id: props.data.value,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <div ref={setNodeRef} style={style}>
      <components.MultiValue {...props} />
    </div>
  );
};

const GroupSelectSortable: React.FC<GroupSelectProps> = ({
  loading,
  handleDragEnd,
  options,
  selectedChange,
  label,
  selectedValues,
  onBlurEvent,
  helperText,
}) => {
  /*component code*/
  const SearchIcon = appAssets.icons.SEARCH;
  const RIGHT = appAssets.icons.RIGHT_ARROW;
  const [filtering, setFiltering] = useState<boolean>(false);

  const getDefaultQuestions = () => {
    return [];
  };
  const handleHeaderClick = (id: any) => {
    if (document.querySelector(`#${id}`) !== null) {
      const obj = document.querySelector(`#${id}`);
      if (obj?.parentElement !== null && obj !== null) {
        const nodeObj = obj.parentElement;
        const node = nodeObj.nextElementSibling;
        if (node !== null) {
          expandedOptions(node, nodeObj);
        }
      }
    }
  };
  const expandedOptions = (element: any, nodeObj: HTMLElement) => {
    const optionsNodes = element.getElementsByClassName('diy-option');
    for (element of optionsNodes) {
      const classes = element.classList;
      if (classes.contains('collapsed')) {
        element.classList.remove('collapsed');
        handleIcon(nodeObj, 'groups-arrow-right', 'groups-arrow-up');
      } else {
        element.classList.add('collapsed');
        handleIcon(nodeObj, 'groups-arrow-up', 'groups-arrow-right');
      }
    }
  };

  const handleIcon = (element: HTMLElement, classNameRemove: string, classNameAdd: string) => {
    const icon = element.getElementsByTagName('svg');
    if (icon !== null && icon?.length > 0) {
      if (icon[0].classList.contains(classNameRemove)) {
        icon[0].classList.remove(classNameRemove);
        icon[0].classList.add(classNameAdd);
      }
    }
  };

  const CustomGroupHeading = (props: any) => {
    const collapseClass = filtering ? 'groups-arrow-up' : 'groups-arrow-right';
    const selectElements = (
      <div className="group-heading-wrapper" onClick={() => handleHeaderClick(props.id)}>
        <components.GroupHeading {...props}>
          <div className="group-heading">
            <div className="diy-option label-container">
              <Tippy
                className="tooltip-container"
                content={
                  <div>
                    <div className="tooltip-title"> {props?.data?.label} </div>
                    <div className="tooltip-text"> {props?.data?.longText} </div>
                  </div>
                }
              >
                <span className="diy-select-label">{props?.data?.label}</span>
              </Tippy>
            </div>
            <div className="icon-container">
              <RIGHT className={collapseClass} />
            </div>
          </div>
        </components.GroupHeading>
      </div>
    );
    return selectElements;
  };

  // eslint-disable-next-line react/prop-types
  const CustomOption = (props: any) => {
    const collapseClass = filtering ? '' : 'collapsed';
    return !props?.isDissabled ? (
      <div className={`${collapseClass} diy-option custom-option`} {...props?.innerProps}>
        <span className="diy-select-label option-groups">{props.label}</span>
      </div>
    ) : null;
  };

  const getParent = (optionId: number) => {
    return options?.find((opt: any) => opt?.options.find((o: Calculation) => o.Id == optionId));
  };

  const optionFormat = (option: Calculation) => {
    const parent = getParent(option?.Id);
    return (
      <Tippy
        className="tooltip-container"
        content={
          <div>
            <div className="tooltip-title">{parent?.label}</div>
            <div className="tooltip-text">{parent?.longText}</div>
          </div>
        }
      >
        <div className="diy-option">
          <span className="diy-select-label">{option?.label}</span>
        </div>
      </Tippy>
    );
  };

  const filterOption = (option: any, inputValue: string) => {
    setTimeout(() => setFiltering(inputValue.length > 0), 0);
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const MenuList = (props: any) => (
    <components.MenuList {...props}>
      <div className="helper-text">{helperText}</div>
      {props.children}
    </components.MenuList>
  );

  const placeholderComponent = (
    <div className="placeholder-container">
      <SearchIcon />
      <span className="placeholder-text">Search</span>
    </div>
  );

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={selectedValues ? selectedValues.map((s) => s.value) : []}>
        <div className="diy-input-container">
          <div className="diy-material-textfield" data-testid="group-select">
            <Select
              isLoading={loading}
              isMulti
              className="diy-select"
              options={options}
              defaultValue={getDefaultQuestions()}
              value={selectedValues}
              onChange={selectedChange}
              blurInputOnSelect={false}
              components={{
                MultiValue: SortableMultiValue,
                MultiValueLabel: SortableMultiValueLabel,
                MenuList: MenuList,
                GroupHeading: CustomGroupHeading,
                Option: CustomOption,
              }}
              closeMenuOnSelect={false}
              placeholder={placeholderComponent}
              isClearable={false}
              styles={chipotleStyles}
              filterOption={filterOption}
              onBlur={onBlurEvent}
              formatOptionLabel={(opt: Calculation) => optionFormat(opt)}
            />
            <label className="diy-label select">{label}</label>
          </div>
        </div>
      </SortableContext>
    </DndContext>
  );
};

export default GroupSelectSortable;
