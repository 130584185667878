import {
  FilterState,
  FilteringSolutionEnum,
  SelectedAdvancedFilter,
} from 'types';

const getState = (state: FilterState) => state?.advanced;
const getUI = (state: FilterState) => getState(state).ui;
const getData = (state: FilterState) => getState(state)?.data;
const getFilters = (state: FilterState) => getData(state)?.Filters;
const getAdvanceFiltersGuid = (state: FilterState) =>
  getData(state)?.AdvancedFiltersGUID;
const getSelectedFilters = (state: FilterState): SelectedAdvancedFilter[] =>
  getData(state)?.SelectedFilters;
const getIsLoading = (state: FilterState): boolean => getUI(state)?.loading;
const getError = (state: FilterState): string => getUI(state)?.error;

const geFilteringSolution = (state: FilterState) =>
  getData(state)?.FilteringSolution || FilteringSolutionEnum.Default;

export default {
  getState,
  getUI,
  getData,
  getFilters,
  getAdvanceFiltersGuid,
  getSelectedFilters,
  getIsLoading,
  getError,
  geFilteringSolution,
};
