import React, { useEffect, useState } from 'react';
import { BaseWidget } from 'models/WidgetModel';
import CategoryDataGrid from 'components/CategoryDataGrid/CategoryDataGrid';
import CategoryMetricsModal from 'components/CategoryMetricsModal/CategoryMetricsModal';

type DataGridMetricsProps = BaseWidget;

const DataGridMetrics: React.FC<DataGridMetricsProps> = ({ response, options, ...props }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [secondaryCategoryData, setSecondaryCategoryData] = useState<any>();

  const closeModal = () => {
    setShowModal(false);
    setSecondaryCategoryData(null);
  };

  useEffect(() => {
    if (secondaryCategoryData) {
      setShowModal(true);
    }
  }, [secondaryCategoryData]);

  return (
    <>
      <CategoryDataGrid
        response={response}
        options={options}
        dataOnClick={setSecondaryCategoryData}
        {...props}
      />
      <CategoryMetricsModal
        response={secondaryCategoryData}
        options={options}
        modalOpen={showModal}
        onRequestClose={closeModal}
        {...props}
      />
    </>
  );
};

export default DataGridMetrics;
