import React, { useEffect, useRef, useState } from 'react';
import ReactECharts, { EChartsOption } from 'echarts-for-react';
import { BaseWidget } from 'models/WidgetModel';
import { horizontalStackedSeriesOption, legendTextStyle, tooltip } from 'constants/chartOption';
import './HorizontalSingleStackedBarChart.scss';

interface horizontalSingleStackedBarProps extends BaseWidget {
  colors: string[];
}

const HorizontalSingleStackedBarChart: React.FC<horizontalSingleStackedBarProps> = ({
  response,
  colors,
  ...props
}) => {
  const [series, setSeries] = useState<any>([]);
  const [options, setOptions] = useState<any>();
  const [colorPalette, setColorPallete] = useState<string[]>();
  const [isPercentage, setIsPercentage] = useState<boolean>(false);
  const { width, height } = props;

  const colorsRef = useRef<string[]>();
  colorsRef.current = colorPalette;

  useEffect(() => {
    if (response) {
      setSeries(response.data.series);
      setColorPallete(colors);
      setIsPercentage(response.data.isPercentage);
    }
  }, [response]);

  useEffect(() => {
    if (colors) {
      setColorPallete(colors);
    }
  }, [colors]);

  const getSeries = () => {
    if (series && series?.length) {
      const x = series.map((s: any) => {
        const noLabel = Number(s.value) >= 22 ? true : false;
        return {
          ...horizontalStackedSeriesOption(s.value),
          label: {
            show: noLabel,
            color: noLabel ? '#FFFFFF' : 'transparent',
            fontSize: 18,
            fontFamily: 'Roboto',
            formatter: `${isPercentage ? s.value + '%' : s.value}`,
          },
          name: s.label,
          data: [s.value],
        };
      });
      return x;
    }
  };

  useEffect(() => {
    const optionsObj: EChartsOption = initOptions();
    setOptions(optionsObj);
  }, [series, colorPalette]);

  const initOptions = () => {
    if (!colorsRef.current?.length) return {};
    return {
      tooltip: {
        ...tooltip,
        formatter: `<strong>{a}:</strong> {c}${isPercentage ? '%' : ''}`,
      },
      legend: {
        bottom: 10,
        selectedMode: false,
        itemHeight: 16,
        itemWidth: 16,
        itemGap: 20,
        textStyle: {
          ...legendTextStyle,
        },
      },
      grid: {
        show: false,
        left: '15',
        right: '15',
        top: '5',
        bottom: '40',
      },
      xAxis: {
        type: 'value',
        show: false,
      },
      yAxis: {
        type: 'category',
        show: false,
      },
      color: colorsRef.current,
      series: getSeries(),
    };
  };

  return options && series?.length ? (
    <>
      <span className="highlightValue" data-testid="HorizontalSingleStackedChart">
        {series[0].value}
        {isPercentage ? '%' : ''}
      </span>
      <div className="responsive-chart horizontal-stacked-bar">
        <ReactECharts opts={{ renderer: 'svg', width, height }} option={options} />
      </div>
    </>
  ) : (
    <></>
  );
};

export default HorizontalSingleStackedBarChart;
