import { ReactComponent as MetricSummaryThumbnail } from '../assets/images/widgets/metric-summary.svg';
import { ReactComponent as IndividualMetricThumbnail } from '../assets/images/widgets/individual-metric.svg';
import { ReactComponent as MultipleMetricsThumbnail } from '../assets/images/widgets/multiple-metric.svg';
import { ReactComponent as TopBottomFiveThumbnail } from '../assets/images/widgets/top-bottom-five.svg';
import { ReactComponent as SurveyDetailsThumbnail } from '../assets/images/widgets/datagrid-individual-surveys.svg';
import { ReactComponent as TierPerformanceThumbnail } from '../assets/images/widgets/datagrid-tier-performance.svg';
import { ReactComponent as HorizontalBarThumbnail } from '../assets/images/widgets/horizontal-bar-chart.svg';
import { ReactComponent as QuestionLevelAggThumbnail } from '../assets/images/widgets/datagrid-question-level-aggregate.svg';
import { ReactComponent as CorporatePulseThumbnail } from '../assets/images/widgets/corporate-pulse.svg';
import { ReactComponent as PieChartThumbnail } from '../assets/images/widgets/pie-chart.svg';
import { ReactComponent as GeoMapThumbnail } from '../assets/images/widgets/geomap-simplified-2x.svg';
import { ReactComponent as StackedBarThumbnail } from '../assets/images/widgets/stacked-bar-chart.svg';
import { ReactComponent as VerticalBarThumbnail } from '../assets/images/widgets/vertical-bar-chart.svg';
import { ReactComponent as HorizontalStackThumbnail } from '../assets/images/widgets/horizontal-stacked-bar.svg';
import { ReactComponent as ChatSegmentationThumbnail } from '../assets/images/widgets/chat-segmentation.svg';
import { ReactComponent as TopFiveThumbnail } from '../assets/images/widgets/top-five.svg';
import { ReactComponent as TopFiveOverallCategoriesThumbnail } from '../assets/images/widgets/top-five-category.svg';
import { ReactComponent as TopFivePositiveThumbnail } from '../assets/images/widgets/top-five-positive-sentiment.svg';
import { ReactComponent as TopFiveNegativeThumbnail } from '../assets/images/widgets/top-five-negative-sentiment.svg';
import { ReactComponent as TopCategoryBySentimentThumbnail } from '../assets/images/widgets/top-cat-by-sentiment.svg';
import { ReactComponent as BiggestMovers } from '../assets/images/widgets/biggest-movers.svg';
import { ReactComponent as DecreasingMoversBySentiment } from '../assets/images/widgets/top-decreasing-movers-by-sentiment.svg';
import { ReactComponent as IncreasingMoversBySentiment } from '../assets/images/widgets/top-increasing-movers-by-sentiment.svg';
import { ReactComponent as DecreasingMoversByVolume } from '../assets/images/widgets/top-decreasing-movers-by-volume.svg';
import { ReactComponent as IncreasingMoversByVolume } from '../assets/images/widgets/top-increasing-movers-by-volume.svg';
import { ReactComponent as TopOverallByVolume } from '../assets/images/widgets/top-increasing-decreasing-movers-by-volume.svg';
import { ReactComponent as TopPositiveBySentiment } from '../assets/images/widgets/top-increasing-decreasing-movers-by-sentiment-positive.svg';
import { ReactComponent as TopNegativeBySentiment } from '../assets/images/widgets/top-increasing-decreasing-movers-by-sentiment-negative.svg';
import { ReactComponent as BiggestMoversVolume } from '../assets/images/widgets/biggest-movers-volume.svg';
import { ReactComponent as BiggestMoversSentiment } from '../assets/images/widgets/biggest-movers-sentiment.svg';

import { ReactComponent as ArrowUp } from '../assets/images/arrow-up.svg';
import { ReactComponent as UpArrow } from '../assets/images/icon/up-arrow.svg';
import { ReactComponent as RightArrow } from '../assets/images/icon/right-arrow.svg';
import { ReactComponent as ArrowDown } from '../assets/images/arrow-down.svg';
import { ReactComponent as ArrowBoth } from '../assets/images/arrow-both.svg';
import { ReactComponent as trendingDown } from '../assets/images/icon/trending-down.svg';
import { ReactComponent as trendingUp } from '../assets/images/icon/trending-up.svg';
import { ReactComponent as trendingFlat } from '../assets/images/icon/trending-flat.svg';
import { ReactComponent as Headset } from '../assets/images/icon/headset.svg';
import { ReactComponent as chevronCircleR } from '../assets/images/icon/chevron-circle-right-solid.svg';
import { ReactComponent as dotCircle } from '../assets/images/icon/dot-circle-regular.svg';

import { ReactComponent as textAlignmentLeft } from '../assets/images/icon/text-alignment-left.svg';
import { ReactComponent as textAlignmentCenter } from '../assets/images/icon/text-alignment-center.svg';
import { ReactComponent as textAlignmentRight } from '../assets/images/icon/text-alignment-right.svg';

import { ReactComponent as CustomerIcon } from '../assets/images/icon/user.svg';
import { ReactComponent as UsersIcon } from '../assets/images/usersIcon.svg';
import { ReactComponent as BarChartIcon } from '../assets/images/Bar.svg';
import { ReactComponent as LineChartIcon } from '../assets/images/Line.svg';
import { ReactComponent as ChartIcon } from '../assets/images/Chart.svg';
import { ReactComponent as TableIcon } from '../assets/images/Table.svg';
import { ReactComponent as CloseSquareIcon } from '../assets/images/icon/close-square.svg';
import { REACT_APP_PEACOCK_URL } from './urls';
import { ReactComponent as SearchIcon } from '../assets/images/icon/search.svg';
import { ReactComponent as CloseRound } from '../assets/images/icon/round-close.svg';
import { ReactComponent as Close } from '../assets/images/icon/close.svg';
import { ReactComponent as BuildCustomWidget } from '../assets/images/icon/build-custom-widget.svg';
import { ReactComponent as DeleteIcon } from '../assets/images/icon/icon_delete.svg';
import { ReactComponent as EditIcon } from '../assets/images/icon/icon_edit.svg';
import { ReactComponent as InfoIcon } from '../assets/images/icon/icon_info.svg';
import { ReactComponent as TopCategoryPositive } from '../assets/images/widgets/top-category-positive.svg';
import { ReactComponent as TopCategoryNegative } from '../assets/images/widgets/top-category-negative.svg';
import { ReactComponent as SystemAlertIcon } from '../assets/images/icon/system-alert.svg';
import { ReactComponent as CalendarIcon } from '../assets/images/icon/icon_calendar.svg';
import { ReactComponent as CalendarFilterIcon } from '../assets/images/icon/Icon_System_Calendar_20px.svg';
import { ReactComponent as DownloadIcon } from '../assets/images/icon/Download_Filled_True.svg';

import { ReactComponent as FilterAplied } from '../assets/images/icon/Filter_Applied.svg';
import { ReactComponent as FilterNotAplied } from '../assets/images/icon/Filter_NotApplied.svg';

import { ReactComponent as Circle } from '../assets/images/icon/circle.svg';

export const smileyIcon = REACT_APP_PEACOCK_URL + '/assets/images/Smiley.svg';
export const neutralIcon = REACT_APP_PEACOCK_URL + '/assets/images/Neutral.svg';
export const frownIcon = REACT_APP_PEACOCK_URL + '/assets/images/Frown.svg';
export const eyeIcon = REACT_APP_PEACOCK_URL + '/assets/images/eyeIconDark.svg';

export const appAssets = {
  widgets: {
    'metric-summary.svg': MetricSummaryThumbnail,
    'top-bottom-five.svg': TopBottomFiveThumbnail,
    'datagrid-individual-surveys.svg': SurveyDetailsThumbnail,
    'datagrid-tier-performance.svg': TierPerformanceThumbnail,
    'datagrid-question-level-aggregate.svg': QuestionLevelAggThumbnail,
    'horizontal-bar-chart.svg': HorizontalBarThumbnail,
    'corporate-pulse.svg': CorporatePulseThumbnail,
    'stacked-bar-chart.svg': StackedBarThumbnail,
    'vertical-bar-chart.svg': VerticalBarThumbnail,
    'horizontal-stacked-bar.svg': HorizontalStackThumbnail,
    'pie-chart.svg': PieChartThumbnail,
    'geomap-simplified-2x.svg': GeoMapThumbnail,
    'individual-metric.svg': IndividualMetricThumbnail,
    'multiple-metric.svg': MultipleMetricsThumbnail,
    'chat-segmentation.svg': ChatSegmentationThumbnail,
    'top-five.svg': TopFiveThumbnail,
    'top-five-category.svg': TopFiveOverallCategoriesThumbnail,
    'top-five-positive-sentiment.svg': TopFivePositiveThumbnail,
    'top-five-negative-sentiment.svg': TopFiveNegativeThumbnail,
    'top-cat-by-sentiment.svg': TopCategoryBySentimentThumbnail,
    'biggest-movers.svg': BiggestMovers,
    'top-decreasing-movers-by-sentiment.svg': DecreasingMoversBySentiment,
    'top-increasing-movers-by-sentiment.svg': IncreasingMoversBySentiment,
    'top-decreasing-movers-by-volume.svg': DecreasingMoversByVolume,
    'top-increasing-movers-by-volume.svg': IncreasingMoversByVolume,
    'top-increasing-decreasing-movers-by-volume.svg': TopOverallByVolume,
    'top-increasing-decreasing-movers-by-sentiment-positive.svg': TopPositiveBySentiment,
    'top-increasing-decreasing-movers-by-sentiment-negative.svg': TopNegativeBySentiment,
    'biggest-movers-volume.svg': BiggestMoversVolume,
    'biggest-movers-sentiment.svg': BiggestMoversSentiment,
  },
  icons: {
    ARROW_UP: ArrowUp,
    UP_ARROW: UpArrow,
    RIGHT_ARROW: RightArrow,
    ARROW_DOWN: ArrowDown,
    ARROW_BOTH: ArrowBoth,
    TRENDING_DOWN: trendingDown,
    TRENDING_UP: trendingUp,
    TRENDING_FLAT: trendingFlat,
    USERS: UsersIcon,
    BAR_CHART: BarChartIcon,
    LINE_CHART: LineChartIcon,
    CHART: ChartIcon,
    TABLE: TableIcon,
    CLOSE_SQUARE: CloseSquareIcon,
    CLOSE_ROUND: CloseRound,
    CLOSE: Close,
    SEARCH: SearchIcon,
    BUILD_CUSTOM_WIDGET: BuildCustomWidget,
    DELETE_ICON: DeleteIcon,
    EDIT_ICON: EditIcon,
    INFO_ICON: InfoIcon,
    HEADSET: Headset,
    USER_ICON: CustomerIcon,
    CHEVRON_CIRCLE_R: chevronCircleR,
    DOT_CIRCLE: dotCircle,
    ALIGNMENT_LEFT: textAlignmentLeft,
    ALIGNMENT_CENTER: textAlignmentCenter,
    ALIGNMENT_RIGHT: textAlignmentRight,
    TOP_CATEGORY_POSTIVE: TopCategoryPositive,
    TOP_CATEGORY_NEGATIVE: TopCategoryNegative,
    SYSTEM_ALERT: SystemAlertIcon,
    CALENDAR: CalendarIcon,
    CALENDAR_FILTER_DRAWER: CalendarFilterIcon,
    DOWNLOAD_ICON: DownloadIcon,
    FILTERAPPLIED: FilterAplied,
    FILTERNOTAPPLIED: FilterNotAplied,
    CIRCLE: Circle,
  },
  // eslint-disable-next-line prettier/prettier
} as const;

export type WidgetImages = keyof typeof appAssets.widgets;
