import { useEffect, useState } from 'react';
import { useAxios } from './useAxios';
import { useStore as useGlobalStore } from 'store/useGlobalStore';

import { useSignalREffect, SendSignalRMessage } from '../contexts/SignalRProvider';
import { getDataFromSingleEndpoint } from 'services/Torogoz/TogorozApi';

export const useGetWidgetData = () => {
  const endpoints = useGlobalStore((state) => state.endpoints);

  const [data, setData] = useState<any>();
  const [payload, setPayload] = useState<any>();
  const [messageId, setMessageId] = useState<string>();
  const [waitingForSignalR, setWaitingForSignalR] = useState<boolean>(false);

  const { response, axiosFetch, error, loading } = useAxios();

  /**
   * This effect will listen to the OnStatusUpdateAsync event from the SignalR hub
   */
  useSignalREffect(
    'OnDataReadyAsync',
    (mId) => {
      if (mId.messageId === messageId) {
        if (mId.status !== 'Success') {
          setWaitingForSignalR(false);
          return;
        }
        //If the message id is the same as the current message id, it means that the data is ready
        SendSignalRMessage('UnSuscribe', mId.messageId);
        // Since the axios request is cached for the same input, we need to trigger the request again to get the data
        getData({ ...payload, messageId: mId.messageId });
      }
    },
    []
  );

  useEffect(() => {
    if (response?.messageId) {
      setWaitingForSignalR(true);
      // If the response has a messageId, it means that the data is not ready then wait for the data via the Suscribe method
      setMessageId(response.messageId);
      SendSignalRMessage('Suscribe', response.messageId);
    } else if (response) {
      setWaitingForSignalR(false);
      // If the response does not have a messageId, it means that the data is ready
      setData(response);
    }
  }, [response]);

  useEffect(() => {
    if (payload) {
      //This request triggers the axios response
      getDataFromSingleEndpoint(endpoints.getWidgetData, payload, axiosFetch);
    }
  }, [payload]);

  const getData = async (payload: any) => {
    setPayload(payload);
  };

  return { data, loading, error, getData, waitingForSignalR };
};
