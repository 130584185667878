import { Metric } from 'models/MetricSummary';
import { BaseWidget } from 'models/WidgetModel';
import React, { useEffect, useRef, useState } from 'react';
import MetricBox from './MetricBox';
import styles from './MetricSummary.module.scss';

interface MetricSummaryProps extends BaseWidget {
  colors: string[];
}

const MetricSummary: React.FC<MetricSummaryProps> = ({ response, colors }) => {
  const [metrics, setMetrics] = useState<Metric[]>([]);
  const mounted = useRef(true);
  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (response) {
      mounted.current = true;
      if (response?.data?.data?.length && mounted.current) {
        setMetrics(response.data.data);
      }
    }
  }, [response]);
  return (
    <>
      {metrics?.length && colors?.length ? (
        <>
          <div className={styles[`metric-collection`]}>
            {metrics.map((metric: Metric, index) => {
              return <MetricBox key={index} metric={metric} colors={colors} />;
            })}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default MetricSummary;
