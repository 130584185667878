import React, { useEffect, useState } from 'react';
import './ColorPicker.scss';

export interface ColorPickerProps {
  colorList: string[][];
  selectedColor: string;
  setSelectedColor: React.Dispatch<string>;
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  colorList,
  selectedColor,
  setSelectedColor,
}) => {
  const [colors, setColors] = useState<string[]>([]);
  useEffect(() => {
    if (colorList) {
      setColors(colorList.flat());
    }
  }, []);

  const colorChange = (event: any) => {
    setSelectedColor(event.target.value);
  };

  return (
    <div className="pck-color-picker">
      <div className="color-section">
        <form className="colorForm" onChange={colorChange}>
          {colors.map((colorItem: string, i: number) => {
            return (
              <div className="form-check" key={i}>
                <input
                  id={`color-${i}`}
                  type="radio"
                  name="color"
                  value={colorItem}
                  className="form-check-input"
                  defaultChecked={colorItem === selectedColor}
                />
                <label htmlFor={`color-${i}`}>
                  <span className="color-container" style={{ backgroundColor: colorItem }}></span>
                </label>
              </div>
            );
          })}
        </form>
      </div>
    </div>
  );
};

export default ColorPicker;
