import ReactECharts from 'echarts-for-react';
import React, { useEffect, useState } from 'react';
import './CometChart.scss';
import { BaseWidget } from 'models/WidgetModel';
import { commetChartOptions } from './CometChartOptions';
import { hideSeries, showAllSeries } from 'helpers/cometLegend';

const CometChart: React.FC<BaseWidget> = ({ response, ...props }) => {
  const { width, height, colors } = props;
  const [data, setData] = useState<any>();
  const [rawData, setRawData] = useState<any[]>();
  const [pictorialData, setPictorialData] = useState<any[][]>([]);
  const [xAxisData, setXAxisData] = useState<any[]>([]);
  const [options, setOptions] = useState<any>();

  useEffect(() => {
    if (response) {
      setData(response.data.data);
    }
  }, [response]);

  useEffect(() => {
    if (data && data.length > 0) {
      handleData(data);
    }
  }, [data]);

  const handleData = (data: any) => {
    setRawData(data);
    setPictorialData(data.slice(1));
    setXAxisData(data.slice(1).map((item: any) => item[0]));
  };

  const generatePictorialBarData = (condition: any) => {
    return pictorialData
      .map((item: any, index: number) => {
        const previousValue = item[2];
        const netChange = item[3];
        const value = [index, netChange];
        const symbolOffset = [0, (previousValue / -Math.abs(netChange)) * 100 + '%'];
        return condition(netChange) ? { value: value, symbolOffset: symbolOffset } : null;
      })
      .filter((item: any) => item !== null);
  };

  const positiveChangeData = generatePictorialBarData((netChange: any) => netChange >= 0);
  const negativeChangeData = generatePictorialBarData((netChange: any) => netChange < 0);

  function hasMixedValues(arr: any[]) {
    const values = arr.slice(1).map((item: any) => item[3]);
    const hasPositive = values.some((value: number) => value >= 0);
    const hasNegative = values.some((value: number) => value < 0);

    return hasPositive && hasNegative;
  }

  useEffect(() => {
    if (rawData) {
      const maxValue = hasMixedValues(rawData) ? 4 : null;
      setOptions(
        commetChartOptions(
          rawData,
          positiveChangeData,
          negativeChangeData,
          xAxisData,
          colors,
          maxValue
        )
      );
    }
  }, [rawData]);

  const echartOnEvents = {
    legendselectchanged: (params: any) => {
      hideSeries(params, data, handleData);
    },
    legendselectall: () => {
      showAllSeries(data, handleData);
    },
  };

  return (
    <div className="responsive-chart">
      {options ? (
        <ReactECharts
          opts={{ renderer: 'svg', width, height }}
          option={options}
          notMerge={true}
          onEvents={echartOnEvents}
        />
      ) : null}
    </div>
  );
};

export default CometChart;
