import { StylesConfig } from 'react-select';

const chipotleStyles: StylesConfig<any> = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  groupHeading: (base) => ({
    ...base,
    flex: '1 1',
    margin: 0,
    color: 'black',
    paddingTop: '1px',
  }),

  option: (styles, { isDisabled, isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#D2E3F9' : undefined,
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
      },
    };
  },

  group: (styles) => {
    return {
      ...styles,
      paddingTop: '2px',
      paddingBottom: '2px',
    };
  },
  menuList: (styles) => {
    return {
      ...styles,
      marginTop: '4px',
      marginBottom: '4px',
    };
  },

  valueContainer: (styles) => {
    return {
      ...styles,
      padding: '4px',
    };
  },
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: 'white',
      border: '1px solid gray',
      borderRadius: '20px',
      padding: '2px',
      paddingRight: '5px',
      alignItems: 'center',
      marginTop: '4px',
      marginBottom: '0px',
    };
  },

  multiValueLabel: (styles) => ({
    ...styles,
    color: '#333333',
    '.option-groups': {
      textIndent: 0,
    },
    '.diy-select-label': {
      maxWidth: '125px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: 'gray',
    backgroundColor: 'white',
    border: '1px solid gray',
    borderRadius: '50%',
    width: '15px',
    height: '15px',
    padding: '0px',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: 'white',
      color: '#c1c1c1',
    },
  }),
};

export default chipotleStyles;
