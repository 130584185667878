import React, { FC, useEffect } from 'react';
import './TopCategories.scss';
import { BaseWidget } from 'models/WidgetModel';
import { appAssets } from 'constants/assets';

interface TopCategoriesProps extends BaseWidget {
  colors: string[];
}

interface CategoryItem {
  l1: string;
  l2: string;
  value: number;
}

interface TopCategoriesResponse {
  topPositive: CategoryItem;
  topNegative: CategoryItem;
}

const TopCategories: FC<TopCategoriesProps> = ({ response }) => {
  const [data, setData] = React.useState<TopCategoriesResponse>();
  useEffect(() => {
    if (response && response?.data?.data?.length) {
      setData(response.data.data[0]);
    }
  }, [response]);
  const TopPositive = appAssets.icons.TOP_CATEGORY_POSTIVE;
  const TopNegative = appAssets.icons.TOP_CATEGORY_NEGATIVE;

  return (
    <>
      <div className="top-categories">
        <div className="top-cat">
          <div className="cat-text">
            <div className="cx-lang-translate cat-group">TOP POSITIVE</div>
            <div className="cat-labels">
              <div className="cat-l2label cx-lang-translate">{data?.topPositive?.l2}</div>
              <div className="cat-l1label cx-lang-translate">{data?.topPositive?.l1}</div>
            </div>
          </div>
          <div className="cat-icon">
            <TopPositive></TopPositive>
            <div className="cat-greenText">{data?.topPositive?.value}</div>
          </div>
        </div>
        <div className="top-cat">
          <div className="cat-text">
            <div className="cx-lang-translate cat-group">TOP NEGATIVE</div>
            <div className="cat-labels">
              <div className="cat-l2label cx-lang-translate">{data?.topNegative?.l2}</div>
              <div className="cat-l1label cx-lang-translate">{data?.topNegative?.l1}</div>
            </div>
          </div>
          <div className="cat-icon">
            <TopNegative></TopNegative>
            <div className="cat-redText">{data?.topNegative?.value}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopCategories;
