export const ColorList = [
  ['#E5E6E5', '#CCCCCC', '#999999', '#666666'],

  ['#E6B8CF', '#CC669B', '#992E65', '#661C42'],

  ['#FFCCD8', '#FA7D9A', '#E64564', '#D30030'],

  ['#FFDFCC', '#FFAE80', '#FF8E4D', '#F46814'],

  ['#FFF6CD', '#FFE780', '#FFDC4D', '#FFC000'],

  ['#DFE6B8', '#BDCC66', '#72992E', '#526630'],

  ['#B7E5C4', '#66CC83', '#2E994D', '#337D4C'],

  ['#B8E6DE', '#66CCBB', '#2E9987', '#007D68'],

  ['#B8E2E6', '#66C4CC', '#2E9099', '#00656E'],

  ['#B8D7E6', '#66ACCC', '#2E7899', '#154D66'],

  ['#B8BAE6', '#666BCC', '#2E3399', '#000565'],

  ['#CFB8E6', '#9B66CC', '#632E99', '#330066'],

  ['#aaaaaa', '#888888', '#444444', '#000000'],

  ['#2e1a47', '#ffffff', '#7c6992', '#b0e0e2'],

  ['#787878', '#afabc9', '#d2d2d2', '#a8a8a8'],
];

export const ColorFamilies = [
  [
    '#D30030',
    '#FFC000',
    '#000565',
    '#242C33',
    '#E64564',
    '#FFDC4D',
    '#2E3399',
    '#485159',
    '#FA7D9A',
    '#FFE780',
    '#B8BAE6',
    '#798896',
    '#FFCCD8',
    '#FFF6CD',
    '#666BCC',
    '#C0C6CC',
  ],
  [
    '#661C42',
    '#526630',
    '#154D66',
    '#F46814',
    '#992E65',
    '#72992E',
    '#2E7899',
    '#FF8E4D',
    '#CC669B',
    '#BDCC66',
    '#66ACCC',
    '#FFAE80',
    '#E6B8CF',
    '#DFE6B8',
    '#B8D7E6',
    '#FFDFCC',
  ],
  [
    '#F46814',
    '#007D68',
    '#330066',
    '#FFC000',
    '#FF8E4D',
    '#2E9987',
    '#632E99',
    '#FFDC4D',
    '#FFAE80',
    '#66CCBB',
    '#9B66CC',
    '#FFE780',
    '#FFDFCC',
    '#B8E6DE',
    '#CFB8E6',
    '#FFF6CD',
  ],
  [
    '#242C33',
    '#193766',
    '#F46814',
    '#661C42',
    '#485159',
    '#2E5799',
    '#FF8E4D',
    '#992E65',
    '#798896',
    '#668DCC',
    '#FFAE80',
    '#CC669B',
    '#C0C6CC',
    '#B8C9E6',
    '#FFDFCC',
    '#E6B8CF',
  ],
  [
    '#337D4C',
    '#00656E',
    '#193766',
    '#330066',
    '#2E994D',
    '#2E9099',
    '#2E5799',
    '#632E99',
    '#66CC83',
    '#66C4CC',
    '#668DCC',
    '#9B66CC',
    '#B7E5C4',
    '#B8E2E6',
    '#B8C9E6',
    '#CFB8E6',
  ],
  [
    '#D30030',
    '#F46814',
    '#FFC000',
    '#337D4C',
    '#FA7D9A',
    '#FF8E4D',
    '#FFDC4D',
    '#2E994D',
    '#E64564',
    '#FFAE80',
    '#FFE780',
    '#66CC83',
    '#FFCCD8',
    '#FFDFCC',
    '#FFF6CD',
    '#B7E5C4',
  ],
  [
    '#000565',
    '#330066',
    '#661C42',
    '#F46814',
    '#2E3399',
    '#632E99',
    '#992E65',
    '#FF8E4D',
    '#666BCC',
    '#9B66CC',
    '#CC669B',
    '#FFAE80',
    '#B8BAE6',
    '#CFB8E6',
    '#E6B8CF',
    '#FFDFCC',
  ],
];
