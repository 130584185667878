import React, { useEffect } from 'react';
import CXApi from 'api';
import { ThemeType, withTheme } from '@cx/ui';

const WEGLOT_CDN_URL = 'https://cdn.weglot.com/weglot.min.js';

const handleSwitchersReady = async (weglotList: string[]) => {
  // Note about timeout: Apparently, when the switchersReady is triggered the html is not ready yet just the language options inside of Weglot object
  setTimeout(function () {
    if ('Weglot' in window) {
      const Weglot = window?.Weglot as any;
      const elements = document.querySelectorAll('.weglot-container ul .wg-li');
      elements.forEach((element: HTMLElement) => {
        const el = element as HTMLElement;
        el.style.display = 'none';
      });

      let selector = `#${weglotList.join(',#')}`;
      if (Weglot.getCurrentLang() !== 'en') {
        selector += ',#wg-en';
      }

      const showElements = document.querySelectorAll(selector);
      showElements.forEach((element: HTMLElement) => {
        const el = element as HTMLElement;
        el.style.display = 'block';
      });
    }
  }, 1500);
};

const initializeWeglot = async ({
  apiKey,
  WorkspaceGUID,
  apiUrl,
  useLegacyApi,
}: {
  apiKey: string;
  WorkspaceGUID: string;
  apiUrl: string;
  useLegacyApi?: boolean;
}) => {
  if (!WorkspaceGUID) return;
  try {
    if ('Weglot' in window) {
      const Weglot = window?.Weglot as any;
      const weglotList = await new CXApi({
        apiUrl,
        useLegacyApi,
      }).getSavedLanguages({
        WorkspaceGUID,
      });
      Weglot?.initialize({
        api_key: apiKey,
        wait_transition: true,
      });
      Weglot?.on('switchersReady', () => handleSwitchersReady(weglotList));
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * This hook is used to load the Weglot script and initialize it
 * @param apiKey Weglot API key
 * @param WorkspaceGUID Workspace GUID
 * @param apiUrl API URL
 */
function useWeglot({
  apiKey,
  WorkspaceGUID,
  apiUrl,
}: {
  apiKey: string;
  WorkspaceGUID: string;
  apiUrl: string;
}) {
  // Script event listener callbacks for load and error
  const onScriptLoad = () => {
    if ('Weglot' in window) initializeWeglot({ apiKey, WorkspaceGUID, apiUrl });
  };
  const onScriptError = (script: HTMLScriptElement) => {
    // Remove from document to avoid memory leak
    script.remove();
  };

  useEffect(() => {
    // Create script
    const script = document.querySelector(`script[src="${WEGLOT_CDN_URL}"]`);

    if (!script) {
      const newScript = document.createElement('script');
      newScript.src = WEGLOT_CDN_URL;
      newScript.async = true;
      newScript.setAttribute('datat-testid', 'weglot-script-tag');

      newScript.addEventListener('load', onScriptLoad);
      newScript.addEventListener('error', () =>
        onScriptError(newScript as HTMLScriptElement)
      );

      // Add script to document body
      document.body.appendChild(newScript);
    }
    // Remove event listeners on cleanup
    return () => {
      script?.removeEventListener?.('load', onScriptLoad);
      script?.removeEventListener?.('error', () =>
        onScriptError(script as HTMLScriptElement)
      );
    };
  }, []);
}

/**
 * Component to load the Weglot language switcher
 * @param WorkspaceGUID Workspace GUID
 * @param apiKey Weglot API key
 * @param apiUrl API URL
 * @returns Weglot language switcher
 */

function SwitchTranslatorComponent({
  WorkspaceGUID,
  apiKey,
  apiUrl,
  theme,
}: {
  WorkspaceGUID: string;
  apiKey: string;
  apiUrl: string;
  theme: ThemeType;
}) {
  useWeglot({
    apiKey,
    WorkspaceGUID,
    apiUrl,
  });

  return (
    <style>
      {`
            .weglot-container {
                font-family: '${theme.fontFamily}', sans-serif;
            }
        `}
    </style>
  );
}

export const SwitchTranslator = withTheme(SwitchTranslatorComponent);
