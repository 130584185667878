import React from 'react';
import { WidgetAction } from 'models/WidgetModel';
import { appAssets } from 'constants/assets';
import './SearchFilter.scss';
import { useWidgetContext } from 'contexts/WidgetProvider';

const SearchFilter: React.FC<any> = () => {
  const SearchIcon = appAssets.icons.SEARCH;
  const CloseRound = appAssets.icons.CLOSE_ROUND;

  const { searchFilter, setState } = useWidgetContext();

  const setFilter = () => {
    setState({ action: WidgetAction.SEARCH_FILTER });
  };

  const resetFilter = () => {
    setState({
      action: WidgetAction.SEARCH_FILTER,
      searchFilter: '',
    });
  };

  const clearFilter = () => {
    setState({ searchFilter: '' });
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      setFilter();
    }
  };
  return (
    <>
      <div className="input-box">
        <SearchIcon className="search-icon" onClick={() => setFilter()} />
        <input
          className="search-input"
          value={searchFilter}
          onKeyDown={handleKeyDown}
          onChange={(e) => setState({ searchFilter: e.target.value })}
        />
        <CloseRound className="x-icon-search" onClick={() => clearFilter()} />
      </div>
      <div className="reset-label" onClick={() => resetFilter()}>
        Reset
      </div>
    </>
  );
};

export default SearchFilter;
