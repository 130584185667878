/**
 * Function to check if the app is hosted in CX 1.0 or CX 2.0.
 * @returns {boolean} true if the app is hosted in CX 1.0, false if it is hosted in CX 2.0
 */
export const isHosted = () => {
  /* cx2-config is only available in CX 1.0.
   * If it is not available, we are in CX 2.0.
   * cx2-config is a script tag with a json object as content with configuration for the app.
   */
  return !!document.getElementById('cx2-config');
};
