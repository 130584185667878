import React from 'react';
import './BuildCustomWidget.scss';

import { appAssets } from 'constants/assets';
import { DrawerCardModel } from 'models/Drawer';
import { useStore } from 'store/useGlobalStore';

export const CustomWidgetAlias = {
  legacy: 'CustomWidget',
  query: 'QueryWidget',
};
export interface BuildCustomWidgetProps {
  setShowDetail: React.Dispatch<React.SetStateAction<boolean>>;
  widget: DrawerCardModel;
  type: string;
}

const BuildCustomWidget: React.FC<BuildCustomWidgetProps> = ({ widget, setShowDetail, type }) => {
  const setState = useStore((state) => state.setState);

  const card: any = {
    alias: type,
    displayName: 'Widget Name',
    isCustom: true,
    isMockData: true,
    config: { w: 4, h: 13 },
    options: {
      actions: [],
      showCard: true,
      showTitle: true,
    },
    colors: [],
  };
  const { displayName, description } = widget;
  const BuildCustomWidgetIcon = appAssets.icons.BUILD_CUSTOM_WIDGET;

  const onDragStart = (e: any) => {
    e.dataTransfer.setData('text/plain', JSON.stringify(card));
    setState({ virtualWidget: card.config });
    setShowDetail(false);
  };

  return (
    <div className="custom-widget droppable-element" draggable={true} onDragStart={onDragStart}>
      <div className="rectangle-box">
        <div>
          <BuildCustomWidgetIcon className="icon-build-custom-widget" />
        </div>
        <div>
          <div>
            <div className="title">{displayName}</div>
            <div className="description-text">{description}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuildCustomWidget;
