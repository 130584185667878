import { FilterState } from 'types';
/**
 * @description Definition of the initial state of the app.
 */
export default {
  ViewId: null,
  WorkspaceId: null,
  ControllerName: null,
  hierarchy: {
    data: {
      HierarchyGuid: null,
      Filters: null,
      SelectedFilters: null,
      FilteringSolution: '',
    },
    ui: {
      loading: false,
      error: null,
    },
  },
  advanced: {
    data: {
      AdvancedFiltersGUID: null,
      Filters: null,
      SelectedFilters: null,
      FilteringSolution: '',
    },
    ui: {
      loading: false,
      error: null,
    },
  },
  FilterBarValues: [],
  shouldPersistFilters: false,
} as Partial<FilterState>;
