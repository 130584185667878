import { Result } from './ValidatorsModels';

interface SeriesRule {
  type: string;
}

export function createArraySeriesValidator(value: any[], rules: any[] = []) {
  return {
    value: value,
    rules: rules,
    checkRule(rule: SeriesRule): Result<any[]> {
      const err = (msg: any) => ({ ok: false, message: msg });
      const ok = () => ({ ok: true, value: value });

      switch (rule.type) {
        case 'notEqualArrayLenght': {
          const isAllSeriesEqualLength = value.every((s) => s.length === value[0].length);
          if (isAllSeriesEqualLength) {
            return ok();
          }
          return err('ArraySeriesValidator not equal length');
        }
        default:
          return err('ArraySeriesValidator not found rule');
      }
    },
    validate(): Result<any[]> {
      if (value === null) {
        return {
          ok: false,
          message: 'ArraySeriesValidator expected an array but received null.',
        };
      } else if (value === undefined) {
        return {
          ok: false,
          message: 'ArraySeriesValidator expected an array but received undefined.',
        };
      }

      // Iterate over all rules, return an error if any rule fails
      if (rules) {
        for (const rule of rules) {
          const result = this.checkRule(rule);

          if (result.ok === false) {
            return result;
          }
        }
      }

      // If none of the rules in the loop had an error, the value passed validation!
      return {
        ok: true,
        value: value,
      };
    },
  };
}
