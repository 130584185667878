import { ReactComponent as CorporatePulse } from '../assets/images/chart-selector/CorporatePulse.svg';
import { ReactComponent as DataGrid } from '../assets/images/chart-selector/DynamicTable.svg';
import { ReactComponent as HorizontalBarChart } from '../assets/images/chart-selector/HorizontalBarChart.svg';
import { ReactComponent as HorizontalSingleStackedBarChart } from '../assets/images/chart-selector/HorizontalSingleStackedBarChart.svg';
import { ReactComponent as MetricSummary } from '../assets/images/chart-selector/MetricSummary.svg';
import { ReactComponent as PieChart } from '../assets/images/chart-selector/PieChart.svg';
import { ReactComponent as StackedBarChart } from '../assets/images/chart-selector/StackedBarChart.svg';
import { ReactComponent as VerticalBarChart } from '../assets/images/chart-selector/VerticalBar.svg';
import { ReactComponent as ResponsesByQuestions } from '../assets/images/chart-selector/ResponsesByQuestions.svg';
import { ReactComponent as TextBlock } from '../assets/images/chart-selector/TextBlock.svg';
import { ReactComponent as BulletChart } from '../assets/images/chart-selector/BulletChart.svg';

export const IconSelectors = {
  icons: {
    CorporatePulse,
    DataGrid,
    HorizontalBarChart,
    HorizontalSingleStackedBarChart,
    MetricSummary,
    PieChart,
    StackedBarChart,
    VerticalBarChart,
    ResponsesByQuestions,
    IndividualMetric: MetricSummary,
    MultipleSummaryMetrics: MetricSummary,
    TextBlock,
    BulletChart,
  },
  // eslint-disable-next-line prettier/prettier
} as const;

export type ChartSelector = keyof typeof IconSelectors.icons;

export type DIYChartOption = {
  chartType: string;
  value: string;
  label: string;
  description: string;
  alias?: string;
  rules?: string[];
  children?: DIYChartOption[];
  icon?: React.FC;
  component?: string;
  isChild: boolean;
  isOpen: boolean;
  parent: string;
};
