import { CalendarState, FilterState } from 'types';

const getState = (state: FilterState): Partial<CalendarState> =>
  state?.calendar;
const getUI = (state: FilterState): FilterState['calendar']['ui'] =>
  getState(state)?.ui;
const getData = (state: FilterState) =>
  getState(state)?.data || ({} as FilterState['calendar']['data']);
const getDateOptions = (state: FilterState) => getData(state)?.DateOptions;
const getSelectedFilters = (state: FilterState) => {
  const { SelectedFilters } = getData(state);
  const { endDate, startDate } = SelectedFilters || {};
  return SelectedFilters
    ? {
        endDate: endDate ? new Date(endDate) : null,
        startDate: startDate ? new Date(startDate) : null,
        label: getData(state).SelectedFilters?.label,
      }
    : null;
};
const getIsLoading = (state: FilterState): boolean => getUI(state)?.loading;
const getIsDropdownsButtons = (state: FilterState): boolean =>
  getUI(state)?.isDropdownButtons;
const getError = (state: FilterState): string => getUI(state)?.error;
const getAttributeName = (state: FilterState): string =>
  state?.calendar?.data?.AttributeName;

export default {
  getState,
  getUI,
  getData,
  getIsLoading,
  getError,
  getDateOptions,
  getSelectedFilters,
  getAttributeName,
  getIsDropdownsButtons,
};
