import { isHosted } from '../utils';

/**
 * Method to sign out the user.
 * @returns {void}
 */
export const signOut = () => {
  try {
    /**
     * If we are in cx-web we can rely in the logout method from CX
     */
    if (isHosted() && typeof (<any>window).logOut === 'function') {
      (<any>window).logOut(true);
      return;
    }

    window.msalInstance.current?.logoutRedirect();
  } catch (error) {
    console.log('Error:signOut', error);
  }
};
