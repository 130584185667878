import axios from 'axios';
import { AxiosParams } from 'models/AxiosModel';
import { buildMemoryStorage, setupCache } from 'axios-cache-interceptor';

const headers = {
  'Content-Type': 'application/json',
};

const axiosInstance = setupCache(axios.create({ headers }), {
  ttl: 60 * 10 * 1000,
  storage: buildMemoryStorage(),
  methods: ['get', 'post'],
});

export const getDashboardById = async (external: boolean, id: string, axiosFetch: any) => {
  await axiosFetch({
    axiosInstance,
    method: 'get',
    url: external ? `/dashboard/${id}` : `/data/dashboard/${id}`,
  });
};

export const getDataFromSingleEndpoint = async (
  url: string,
  data: unknown,
  axiosFetch: (params: AxiosParams) => Promise<void>
) => {
  await axiosFetch({
    axiosInstance,
    method: 'post',
    url,
    requestConfig: {
      data,
    },
  });
};

export const getWidgets = async (axiosFetch: (params: AxiosParams) => Promise<void>) => {
  await axiosFetch({
    axiosInstance,
    method: 'get',
    url: `/widgets`,
  });
};

export const getChartTypes = async (url: string, axiosFetch: any, isConsumer = false) => {
  await axiosFetch({
    axiosInstance,
    method: 'get',
    url,
    requestConfig: {
      data: { external: isConsumer },
    },
  });
};

export const getChartTypesModel = async (url: string, axiosFetch: any, isConsumer = false) => {
  await axiosFetch({
    axiosInstance,
    method: 'get',
    url,
    requestConfig: {
      data: { external: isConsumer },
    },
  });
};

export const getChartTypeData = async (url: string, axiosFetch: any, isConsumer = false) => {
  await axiosFetch({
    axiosInstance,
    method: 'get',
    url,
    requestConfig: {
      data: { external: isConsumer },
    },
  });
};

export const getDataSourceTypes = async (url: string, axiosFetch: any, isConsumer = false) => {
  await axiosFetch({
    axiosInstance,
    method: 'get',
    url,
    requestConfig: {
      data: { external: isConsumer },
    },
  });
};
