import { ControllerName } from 'types';

export const DASHBOARD_DROPDOWN_BUTTONS_FILTER_WHITELIST = {
  [ControllerName.LoyaltyEffortDashboard as string]:
    ControllerName.LoyaltyEffortDashboard,
  [ControllerName.ClientIssueExperienced as string]:
    ControllerName.ClientIssueExperienced,
  [ControllerName.ManagementScorecard as string]:
    ControllerName.ManagementScorecard,
  [ControllerName.ScoreCard as string]: ControllerName.ScoreCard,
  [ControllerName.CorporatePulse as string]: ControllerName.CorporatePulse,
};

export const DASHBOARD_WITH_QUICKDATE_BUTTONS_DISABLED = {
  [ControllerName.IndividualCoaching as string]:
    ControllerName.IndividualCoaching,
  [ControllerName.OuterLoopCreateRecord as string]:
    ControllerName.OuterLoopCreateRecord,
  [ControllerName.FrontlineDashboard as string]:
    ControllerName.FrontlineDashboard,
  [ControllerName.CoachingCards as string]: ControllerName.CoachingCards,
  [ControllerName.TextMiningWordCloud as string]:
    ControllerName.TextMiningWordCloud,
};

export const DASHBOARDS_WITH_HIERARCHY_FILTERS_DISABLED = {
  [(ControllerName.CoachingHistory as string).toLowerCase()]: () => true,
  [(ControllerName.FrontlineDashboard as string).toLowerCase()]: () => true,
  [(ControllerName.UnstructuredData as string).toLowerCase()]: () => true,
  [(ControllerName.OuterLoopSnapshot as string).toLowerCase()]: () => true,
  [(ControllerName.CoachingHistory as string).toLowerCase()]: () => true,
  [(ControllerName.ReportUsage as string).toLowerCase()]: (state: any) => {
    return state?.HasFiltersButton !== undefined && !state?.HasFiltersButton;
  },
};

export const DASHBOARD_WITH_FAVORITE_FILTERS_DISABLED = {
  [ControllerName.ReportUsage as string]: ControllerName.ReportUsage,
};
export const MAX_DATE = new Date();
export const FILTER_BAR_DISABLED_VIEWS: Record<string, () => boolean> = {
  [(ControllerName.ExploreWithELLIE as string).toLowerCase()]: () => true,
  help: () => true,
  [(ControllerName.DIYDashboard as string).toLowerCase()]: () => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    if (params.get('editMode')?.toLowerCase() === 'true') return true;
    return false;
  },
};

export { FAVORITE_FILTER_ERRORS } from './errors';
export { STEPS } from './walkthoughConstants';

export const DASHBOARDS_WITH_CALENDAR_DISABLED = {
  [ControllerName.DIYDashboard as string]: ControllerName.DIYDashboard,
  [ControllerName.IndividualCoaching as string]:
    ControllerName.IndividualCoaching,
};

export const DASHBOARDS_WITH_ADVANCED_FILTERS_DISABLED = {
  [(ControllerName.CoachingHistory as string).toLowerCase()]: () => true,
  [(ControllerName.ReportUsage as string).toLowerCase()]: (state: any) => {
    return state?.HasFiltersButton !== undefined && !state?.HasFiltersButton;
  },
};

export const VIEWS_WITH_TIME_PERIOD_DISABLED: { [key: string]: string } = {
  managementscorecard: 'managementscorecard',
  scorecard: 'scorecard',
  individualcoaching: 'individualcoaching',
  loyaltyeffortdashboard: 'loyaltyeffortdashboard',
  unstructureddata: 'unstructureddata',
  udtopcategoriesbyvolume: 'udtopcategoriesbyvolume',
  udtopcategoriesbysent: 'udtopcategoriesbysent',
  udbiggestmovers: 'udbiggestmovers',
  clientissueexperienced: 'clientissueexperienced',
  diydashboard: 'diydashboard',
  reportusage: 'reportusage',
  coachinghistory: 'coachinghistory',
};

export const VIEWS_WITH_TIME_PERIOD_DISABLED_FOR_YTD: {
  [key: string]: string;
} = {
  frontlinedashboard: 'frontlinedashboard',
};
