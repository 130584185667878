import '../NumberInput/NumberInput.scss';
import React, { useRef } from 'react';
import { appAssets } from 'constants/assets';
export interface NumberInputProps {
  onChangeMethod: any;
  value: any;
  label: string;
}

const GenericNumberInput: React.FC<NumberInputProps> = ({ onChangeMethod, value, label }) => {
  const UP = appAssets.icons.UP_ARROW;
  const numberRef = useRef<any>(null);

  const valueHandler = (up = false) => {
    let numberValue = Number(numberRef.current.value);
    if (up) {
      numberValue += 1;
      onChangeMethod({ target: { value: numberValue } });
    }
    if (!up) {
      numberValue -= 1;
      onChangeMethod({ target: { value: numberValue } });
    }
  };

  return (
    <div>
      <div className="diy-material-numberfield" style={{ border: '1px solid gray' }}>
        <input
          ref={numberRef}
          value={value}
          className="diy-input diy-input-number"
          type="number"
          onChange={onChangeMethod}
        />
        <div className="diy-ctrl-container">
          <UP
            className="diy-up-icon"
            onClick={() => {
              valueHandler(true);
            }}
            color="#666666"
          />
          <UP
            className="diy-down-icon"
            onClick={() => {
              valueHandler(false);
            }}
            color="#666666"
          />
        </div>
      </div>

      <label className="diy-label select">{label}</label>
    </div>
  );
};

export default GenericNumberInput;
