const currentDate = new Date();
const priorDay = new Date(currentDate);
priorDay.setDate(currentDate.getDate() - 1);
const priorWeekStartDate = new Date(currentDate);
priorWeekStartDate.setDate(currentDate.getDate() - currentDate.getDay() - 7);
const priorWeekEndDate = new Date(currentDate);
priorWeekEndDate.setDate(currentDate.getDate() - currentDate.getDay() - 1);
const priorMonthStartDate = new Date(currentDate);
priorMonthStartDate.setMonth(currentDate.getMonth() - 1, 1);
const priorMonthEndDate = new Date(currentDate);
priorMonthEndDate.setDate(0);
const monthToDateStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
const yearToDateStartDate = new Date(currentDate.getFullYear(), 0, 1);

const priorQuarterStartDate = new Date(
  currentDate.getFullYear(),
  Math.floor((currentDate.getMonth() - 3) / 3) * 3,
  1
);

const priorQuarterEndDate = new Date(
  currentDate.getFullYear(),
  priorQuarterStartDate.getMonth() + 3,
  0
);

const quarterStartDate = new Date(
  currentDate.getFullYear(),
  Math.floor(currentDate.getMonth() / 3) * 3,
  1
);

const priorYearStartDate = new Date(currentDate.getFullYear() - 1, 0, 1);

const priorYearEndDate = new Date(currentDate.getFullYear() - 1, 11, 31);

const weekToDateStartDate = new Date(currentDate);
weekToDateStartDate.setDate(currentDate.getDate() - currentDate.getDay());

export const getDateByPeriodByType = (timePeriod: any) => {
  let startDate = '';
  let endDate = '';
  switch (timePeriod) {
    case 'PriorDay':
      startDate = priorDay.toLocaleDateString();
      endDate = priorDay.toLocaleDateString();
      break;
    case 'PriorWeek':
      startDate = priorWeekStartDate.toLocaleDateString();
      endDate = priorWeekEndDate.toLocaleDateString();
      break;
    case 'PriorMonth':
      startDate = priorMonthStartDate.toLocaleDateString();
      endDate = priorMonthEndDate.toLocaleDateString();
      break;
    case 'PriorQuarter':
      startDate = priorQuarterStartDate.toLocaleDateString();
      endDate = priorQuarterEndDate.toLocaleDateString();
      break;
    case 'PriorYear':
      startDate = priorYearStartDate.toLocaleDateString();
      endDate = priorYearEndDate.toLocaleDateString();
      break;
    case 'DayToDate':
      startDate = currentDate.toLocaleDateString();
      endDate = currentDate.toLocaleDateString();
      break;
    case 'WeekToDate':
      startDate = weekToDateStartDate.toLocaleDateString();
      endDate = currentDate.toLocaleDateString();
      break;
    case 'MonthToDate':
      startDate = monthToDateStart.toLocaleDateString();
      endDate = currentDate.toLocaleDateString();
      break;
    case 'QuarterToDate':
      startDate = quarterStartDate.toLocaleDateString();
      endDate = currentDate.toLocaleDateString();
      break;
    case 'YearToDate':
      startDate = yearToDateStartDate.toLocaleDateString();
      endDate = currentDate.toLocaleDateString();
      break;
    default:
      startDate = '';
      endDate = '';
  }
  return `${startDate} - ${endDate}`;
};

// Returns string with startDate and endDate based on localeDateString
export const getDateRangeLabel = (dateFilter: any) => {
  const startDate = new Date(dateFilter.StartDate).toLocaleDateString();
  const endDate = new Date(dateFilter.EndDate).toLocaleDateString();

  return `${startDate} - ${endDate}`;
};
