import Tippy from '@tippyjs/react';

const Tooltip = ({ text }: any) => {
  return (
    <Tippy
      content={
        <div title={text}>
          <div className="tooltip-title">{text}</div>
        </div>
      }
    >
      <div className={`pck-card-title`} title={text}>
        <span title={text}>{text}</span>
      </div>
    </Tippy>
  );
};

export default Tooltip;
