import { CustomStep, StepEvents } from '@cx/ui/components/Walkthrough';

export const STEPS: CustomStep[] = [
  {
    target: '.filter-button',
    content: 'Click here to access the Filter Menu',
    placement: 'auto',
    event: StepEvents.click,
    disableBeacon: true,
    spotlightClicks: true,
    checkIfShouldTriggerEvent: (target: HTMLElement) =>
      !target.classList.contains('is-active'),
    waitForCurrentTarget: async () => {
      await new Promise((resolve) => {
        setTimeout(resolve, 100);
      });
    },
    waitForNextTarget: async () => {
      await new Promise((resolve) => {
        setTimeout(resolve, 400);
      });
    },
  },
  {
    target: '.cx-hierarchy-filter',
    content: 'Apply robust filters to your data views.',
    placement: 'left',
    disableBeacon: true,
    spotlightClicks: true,
  },
  {
    target: '.calendar-picker',
    content: 'Customize the date range for your data.',
    placement: 'left',
    disableBeacon: true,
    spotlightClicks: true,
  },
  {
    target: '.cx-hierarchy-filter',
    content: 'Drill down to specific hierarchy data',
    placement: 'bottom',
    disableBeacon: true,
    spotlightClicks: true,
  },
  {
    target: '.advanced-filter-button',
    content: 'Or click here to…',
    placement: 'top',
    event: StepEvents.click,
    disableBeacon: true,
    spotlightClicks: true,
    waitForCurrentTarget: async () => {
      await new Promise((resolve) => {
        setTimeout(resolve, 100);
      });
    },
    waitForNextTarget: async () => {
      await new Promise((resolve) => {
        setTimeout(resolve, 400);
      });
    },
  },
  {
    target: '.advanced-filter-dropdown',
    content: 'Create your own custom query',
    placement: 'top',
    disableBeacon: true,
    spotlightClicks: true,
  },
  {
    target: '.favorite-filter',
    content: 'Add favorite filters to quickly reuse searches',
    placement: 'bottom',
    disableBeacon: true,
    spotlightClicks: true,
  },
  {
    target: '.button-apply',
    content: 'Apply any new filters or changes',
    placement: 'top',
    disableBeacon: true,
    spotlightClicks: true,
  },
  {
    target: '.cx-hierarchy-filter',
    content: 'Filter should be applied. Quickly view applied filters',
    placement: 'bottom',
    disableBeacon: true,
    spotlightClicks: true,
  },
  {
    target: '.filter-bar',
    content: 'Click here to close filter bar',
    event: StepEvents.click,
    placement: 'auto',
    disableBeacon: true,
    spotlightClicks: true,
    waitForCurrentTarget: async () => {
      await new Promise((resolve) => {
        setTimeout(resolve, 100);
      });
    },
  },
];
