import { FilterState, FilteringSolutionEnum, SelectedFilters } from 'types';

const getState = (state: FilterState) => state.hierarchy;
const getUI = (state: FilterState) => getState(state)?.ui;
const getData = (state: FilterState) => getState(state)?.data;
const getFilters = (state: FilterState) => getData(state)?.Filters;
const getHierarchyGuid = (state: FilterState) => getData(state)?.HierarchyGuid;
const getSelectedFilters = (state: FilterState): SelectedFilters =>
  getData(state)?.SelectedFilters;
const getIsLoading = (state: FilterState): boolean => getUI(state)?.loading;
const getError = (state: FilterState): string => getUI(state)?.error;
const geFilteringSolution = (state: FilterState) =>
  getData(state)?.FilteringSolution || FilteringSolutionEnum.Default;

export default {
  getState,
  getUI,
  getData,
  getFilters,
  getHierarchyGuid,
  getSelectedFilters,
  getIsLoading,
  getError,
  geFilteringSolution,
};
