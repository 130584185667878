import { BaseWidget } from 'models/WidgetModel';
import React, { useEffect, useState } from 'react';
import './TopFive.scss';

interface TopFiveProps extends BaseWidget {
  colors: string[];
}

const TopFive: React.FC<TopFiveProps> = ({ response, colors }) => {
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (response) {
      setData(response.data.data);
    }
  }, [response]);

  return data ? (
    <div className="ranking-widget">
      {data?.map((item: any, i: number) => (
        <div className="ranking-item" key={i}>
          <div className="ranking-number" style={{ backgroundColor: colors[i] }}>
            {i + 1}
          </div>
          <div className="categories">
            <span className="category-l2">{item.l2}</span>
            <span className="category-l1">{item.l1}</span>
          </div>
        </div>
      ))}
    </div>
  ) : null;
};

export default TopFive;
