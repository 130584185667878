import React, { useEffect, useState } from 'react';
import CardWidget from 'components/CardWidget/CardWidget';
import { library } from 'components';
import { ResponsiveProps } from 'react-grid-layout';
import { GridState } from 'models/GridModel';
import './DiyModal.scss';
import { useStore } from 'store/useGlobalStore';

export interface DiyModalProps {
  modalOpen: boolean;
  gridProps: ResponsiveProps;
  grid: GridState;
  gridContainer?: HTMLDivElement | null;
  devEnv: boolean;
}

const DiyModal: React.FC<DiyModalProps> = ({
  modalOpen,
  gridProps,
  grid,
  gridContainer,
  devEnv,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const customWidget = useStore((state) => state.customWidget);
  const [modalWidth, setModalWidth] = useState<number>(600);
  const [modalHeight, setModalHeight] = useState<number | string>('auto');
  const [loadingSize, setLoadingSize] = useState<boolean>(false);

  useEffect(() => {
    if (modalOpen) {
      setIsOpen(modalOpen);
    }
  }, [modalOpen]);

  useEffect(() => {
    setLoadingSize(true);
    setDimensions();
  }, [customWidget]);

  const scaleByEnv = () => {
    return devEnv ? 1 : 1.5;
  };

  const setDimensions = () => {
    if (customWidget.alias !== '') {
      if (gridProps?.cols && gridProps?.breakpoints) {
        const colsNumber = gridProps.cols[grid.currentBreakpoint];
        const breakpoint =
          gridContainer?.clientWidth || gridProps.breakpoints[grid.currentBreakpoint];
        // Get ratio based on client width
        const factor = customWidget.alias === library.METRIC_SUMMARY ? 0.75 : scaleByEnv();
        const ratioWidth = (breakpoint / colsNumber) * factor;
        const ratioHeight = (breakpoint / colsNumber) * factor;
        // Get ratio based on columns and rows
        const heightFix = customWidget.options.showTitle ? 50 : 25;
        if (customWidget?.config?.w) setModalWidth(customWidget.config.w * ratioWidth);
        if (
          customWidget?.config?.h &&
          gridProps?.rowHeight &&
          customWidget.alias === library.DYNAMIC_TABLE
        ) {
          setModalHeight(
            ((customWidget.config.h * ratioHeight) / gridProps.rowHeight + heightFix).toString() +
              'px'
          );
        } else {
          setModalHeight('auto');
        }
      }
      setLoadingSize(false);
    }
  };

  return (
    <>
      <div className={`pck-modal diy-modal ${isOpen ? 'open' : ''}`}>
        <div className="modal-box">
          <div
            className={
              customWidget.alias === library.METRIC_SUMMARY
                ? 'pck-modal-body-sms'
                : 'pck-modal-body'
            }
            style={{ width: `${modalWidth}px`, height: `${modalHeight}` }}
          >
            {(customWidget.alias !== library.CUSTOM_WIDGET ||
              customWidget.alias !== library.QUERY_WIDGET) &&
              !loadingSize && (
                <CardWidget
                  widget={customWidget}
                  index={Number(customWidget.config.i)}
                  previewMode={true}
                />
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DiyModal;
