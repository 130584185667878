import React, { useEffect, useState } from 'react';
import './BulletChart.scss';
import { BaseWidget } from 'models/WidgetModel';
import { BulletChartType } from 'models/ChartModel';
import SingleHorizontalBarChart from '../SingleHorizontalBarChart/SingleHorizontalBarChart';
import Scrollbars from 'react-custom-scrollbars-2';
import { getLabelWithCustomDecimalPlaces } from 'helpers/utilities';

interface BulletChartProps extends BaseWidget {
  colors: string[];
  previewMode?: boolean;
  options: any;
}

const BulletChart: React.FC<BulletChartProps> = ({ response, colors, previewMode, options }) => {
  const [data, setData] = useState<any>();
  const [color, setColor] = useState<string>('#00656E');
  useEffect(() => {
    if (Array.isArray(colors)) {
      const [color1] = colors;
      setColor(color1);
    }
  }, [colors]);

  useEffect(() => {
    if (response) {
      setData(response?.data?.data);
    }
  }, [response]);

  return (
    <>
      {data ? (
        <Scrollbars className={previewMode ? 'fix-height-scroll' : 'drawer-scroll'}>
          <div className="bullet-chart-widget" data-testid="BulletChart">
            {data?.map((item: BulletChartType, i: number) => {
              return (
                <div key={i}>
                  <div className="bullet-chart-header">
                    <span className="bullet-chart-label">{item.label}</span>
                    <span className="bullet-chart-value" style={{ color: color }}>
                      {getLabelWithCustomDecimalPlaces(item.value, true, options.decimalPlaces)}
                    </span>
                  </div>
                  <SingleHorizontalBarChart
                    value={item.value}
                    isPercentage
                    color={color}
                    showLabel={false}
                    decimalPlaces={options.decimalPlaces}
                  />
                  <div className="bullet-chart-xaxis">
                    {Array.from({ length: 11 }, (x, i) => (
                      <span className="xaxis-label" key={i}>
                        {i * 10}
                      </span>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        </Scrollbars>
      ) : null}
    </>
  );
};

export default BulletChart;
