import { FilterSlice, FilterState } from 'types';
import defaulInitialState from './initialState';
import { createActions } from './actions/createActions';

export const createSlice =
  (initialState: Partial<FilterState> = defaulInitialState) =>
  (set: any, get: any, store: any): Partial<FilterSlice> => ({
    ...(initialState || defaulInitialState),
    ...createActions({ set, get, store, initialState }),
  });
