import './LoadingSpinner.scss';

interface LoadingSpinnerProps {
  text?: string;
}

const LoadingSpinner = (props: LoadingSpinnerProps) => {
  return (
    <div className="diy-loading">
      <div className="diy-spinner"></div>
      {props.text && <div className="diy-text">{props.text}</div>}
    </div>
  );
};

export default LoadingSpinner;
