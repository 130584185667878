import ColorFamilyPicker from 'core-ui/ColorFamilyPicker/ColorFamilyPicker';
import { ColorFamilies } from 'helpers/ColorPickerList';
import { displayColorsByFamily } from 'helpers/utilities';
import React, { useEffect, useRef, useState } from 'react';

export interface ColorFamilyInputValues {
  name: string;
  theme: string[];
}

export interface ColorFamilyInputProps {
  inputTitle: string;
  defaultColorFamily: string[];
  selectColorFamily: React.Dispatch<any>;
}

const ColorFamilyInput: React.FC<ColorFamilyInputProps> = ({
  inputTitle,
  defaultColorFamily,
  selectColorFamily,
}) => {
  const [textValue, setTextValue] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [tempColorFamily, setTempColorFamily] = useState<string[]>(defaultColorFamily);
  const pickerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener('mousedown', outsideClickListener);
    return () => {
      document.removeEventListener('mousedown', outsideClickListener);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      setIsOpen(!isOpen);
    }
    selectColorFamily({ theme: tempColorFamily, textValue });
  }, [tempColorFamily, textValue]);

  const outsideClickListener = (event: any) => {
    if (
      pickerRef.current &&
      pickerRef.current !== event.target &&
      !pickerRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  const colorFamilyOnChange = (event: { target: { value: any } }) => {
    setTextValue(event.target.value);
  };

  const toggleColorFamilyPicker = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="diy-input-container">
      <div className="diy-material-colorfield">
        <input
          className="diy-input"
          type="text"
          name="theme"
          maxLength={50}
          onChange={colorFamilyOnChange}
          onClick={toggleColorFamilyPicker}
          readOnly
        />
        <div className="diy-color-input" onClick={toggleColorFamilyPicker}>
          <div className="diy-theme-container">
            {displayColorsByFamily(tempColorFamily).map((cf: string, i: number) => {
              return (
                <span className="diy-selected-theme" key={i} style={{ backgroundColor: cf }}></span>
              );
            })}
          </div>
        </div>
        <div className="diy-label">{inputTitle}</div>
      </div>
      {isOpen ? (
        <div ref={pickerRef}>
          <ColorFamilyPicker
            {...{
              ColorFamiliesList: ColorFamilies,
              selectedColorFamily: tempColorFamily,
              setSelectedColorFamily: setTempColorFamily,
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ColorFamilyInput;
