import { WidgetAction } from 'models/WidgetModel';
import SortBy from './Actions/SortBy/SortBy';
import SwitchBarLine from './Actions/SwitchBarLine/SwitchBarLine';
import SwitchChartTable from './Actions/SwitchChartTable/SwitchChartTable';
import MetricsDropdown from './Actions/MetricsDropdown/MetricsDropdown';
import SearchFilter from './Actions/SearchFilter/SearchFilter';
import ExportToFile from './Actions/ExportToFile/ExportToFile';
import ExpandDataGrid from './Actions/ExpandDataGrid/ExpandDataGrid';

const headerActionComponents: { [key: string]: React.FC<any> } = {};
const nonHeaderActionComponents: { [key: string]: React.FC<any> } = {};
headerActionComponents[WidgetAction.EXPORT_TO_CSV] = ExportToFile;
headerActionComponents[WidgetAction.SORT] = SortBy;
headerActionComponents[WidgetAction.SWITCH_BAR_LINE] = SwitchBarLine;
headerActionComponents[WidgetAction.SWITCH_CHART_TABLE] = SwitchChartTable;
headerActionComponents[WidgetAction.METRICS_DROPDOWN] = MetricsDropdown;
headerActionComponents[WidgetAction.SEARCH_FILTER] = SearchFilter;
nonHeaderActionComponents[WidgetAction.EXPANDABLE] = ExpandDataGrid;

export { headerActionComponents, nonHeaderActionComponents };
