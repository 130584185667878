import { createContext, useContext, useState } from 'react';
import { initialWidgetState } from 'store/initialWidgetState';
import PropTypes from 'prop-types';

export function createStore<Store>(initialState: Store) {
  return createContext<Store>(initialState);
}

type WidgetStore = typeof initialWidgetState & {
  setState: (newState: Partial<typeof initialWidgetState>) => void;
};
// eslint-disable-next-line prettier/prettier
export const WidgetContext = createStore({} as WidgetStore);

type WidgetProviderProps = { children: React.ReactNode };

export const WidgetProvider: React.FC<WidgetProviderProps> = ({ children }) => {
  const [config, setConfig] = useState(initialWidgetState);

  const setState = (newState: Partial<typeof initialWidgetState>) =>
    setConfig((prevState: typeof initialWidgetState) => ({ ...prevState, ...newState }));

  const value = {
    ...config,
    setState,
  };

  return <WidgetContext.Provider value={value}>{children}</WidgetContext.Provider>;
};

WidgetProvider.propTypes = {
  children: PropTypes.any,
};

export function useWidgetContext() {
  return useContext(WidgetContext);
}
